import { sendNotification } from 'actions/Notifications.js';
import { handleRequestError } from 'actions/ErrorHandling.js';
import {
	SNACKS_POST_CONTAINER_RESPONSE,
	SNACKS_POST_CONTAINER_REQUEST,
	SNACKS_POST_CONTAINER_ERROR,
	SNACKS_RESET_CONTAINER_STATE,
	SNACKS_POST_CONTAINER_CHECK,
} from '../constants/ActionTypes';

export const postContainer = (packageCodes, containerCode, force) => async (
	dispatch,
	_getState,
	api
) => {
	try {
		dispatch({
			type: SNACKS_POST_CONTAINER_REQUEST,
		});
		const response = await api.post('wms/snacks/container', {
			packageCodes,
			containerCode,
			force,
		});
		if (response.data.error) {
			dispatch(
				sendNotification({
					type: 'error',
					message: response.data.error,
				})
			);
		} else if (!response.data.repeated) {
			dispatch(
				sendNotification({
					type: 'success',
					message: 'Adicionado!',
				})
			);
			dispatch({
				type: SNACKS_POST_CONTAINER_RESPONSE,
				payload: {
					data: response.data,
				},
			});
		} else
			dispatch({
				type: SNACKS_POST_CONTAINER_CHECK,
				payload: {
					data: response.data.message,
				},
			});
	} catch (error) {
		dispatch(handleRequestError(error));
		dispatch({
			type: SNACKS_POST_CONTAINER_ERROR,
			payload: {
				error,
			},
		});
	}
};

export const resetRedux = () => async (dispatch, _getState, _api) => {
	dispatch({
		type: SNACKS_RESET_CONTAINER_STATE,
	});
};
