const env = process.env.NODE_ENV;

export const ROUTES_STATUS = {
	WAITING_SIMULATION: 'waiting_simulation',
	WAITING_ALLOCATION: 'waiting_allocation',
	ALLOCATING: 'allocating',
	IN_TRANSIT: 'in_transit',
	FINISHED: 'finished',
};

export const LOGGI_URI =
	env === 'production'
		? 'https://www.loggi.com/presto/app/acompanhamento'
		: 'https://staging.loggi.com/presto/app/acompanhamento';

export const ROUTES_TYPES = {
	LOGGI: 'loggi',
	LIVUP: 'livup',
	COURRIEROS: 'courrieros',
};

export const ROUTE_NAME_BY_TYPE = {
	loggi: 'Loggi',
	livup: 'Motoboy Próprio',
	courrieros: 'Bike',
};
