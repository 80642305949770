import {
	PRODUCTS_LOSS_FINISH_REQUEST,
	PRODUCTS_LOSS_FINISH_RESPONSE,
	PRODUCTS_LOSS_FINISH_ERROR,
	PRODUCTS_LOSS_RESET,
} from 'constants/ActionTypes';
import axios from 'axios/index';
import { INVENTORY_API_ADDRESS } from 'constants/InventoryAPI';
import { sendNotification } from 'actions/Notifications';

const getInventoryAPI = () =>
	axios.create({
		baseURL: INVENTORY_API_ADDRESS,
		responseType: 'json',
	});

export const finishProductsLossScanning = items => async (dispatch, getState) => {
	const centerId = getState().distributionCenters.selected;
	const centerKind = getState().distributionCenters.selectedKind;
	const inventoryApi = getInventoryAPI();
	const authToken = localStorage.getItem('authToken');
	const headers = {
		Authorization: authToken ? `Bearer ${authToken}` : '',
		'Content-Type': 'application/json',
	};
	try {
		dispatch({
			type: PRODUCTS_LOSS_FINISH_REQUEST,
		});
		const response = await inventoryApi.post(
			'orders/loss',
			{
				origin: centerId,
				originKind: centerKind,
				items,
			},
			{
				headers,
			}
		);
		dispatch({
			type: PRODUCTS_LOSS_FINISH_RESPONSE,
			payload: response.data,
		});
		dispatch(sendNotification({ type: 'success', message: 'Operação finalizada!' }));
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		console.log(errorMessage);
		dispatch({
			type: PRODUCTS_LOSS_FINISH_ERROR,
			payload: {
				error: errorMessage,
			},
		});
	}
};

export const resetStore = () => async dispatch => {
	dispatch({
		type: PRODUCTS_LOSS_RESET,
	});
};
