import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import FiscalPrintingContainer from './containers/FiscalPrintingContainer';

class NfeAndNfcePrinting extends React.Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={<IntlMessages id="pages.fiscalPrinting" />}
        />
        <FiscalPrintingContainer />
      </div>
    );
  }
}

export default NfeAndNfcePrinting;
