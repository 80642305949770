import {
  ORDERS_RETURN_FINISH_REQUEST,
  ORDERS_RETURN_FINISH_RESPONSE,
  ORDERS_RETURN_FINISH_ERROR,
  ORDERS_RETURN_GET_ORDER_REQUEST,
  ORDERS_RETURN_GET_ORDER_RESPONSE,
  ORDERS_RETURN_GET_ORDER_ERROR,
} from "../constants/ActionTypes";
import { handleRequestError } from "./ErrorHandling";
import { sendNotification } from "./Notifications";

export const getOrderToReturn = (packageCode, dispatchType) => async (
  dispatch,
  getState,
  api
) => {
  const distributionCenter = getState().distributionCenters.selected;
  try {
    dispatch({
      type: ORDERS_RETURN_GET_ORDER_REQUEST,
      order: {
        packageCode,
        distributionCenter,
      },
    });
    const response = await api.get("/order-return/get-order", {
      params: { packageCode, distributionCenter, dispatchType },
    });
    dispatch({
      type: ORDERS_RETURN_GET_ORDER_RESPONSE,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({
      type: ORDERS_RETURN_GET_ORDER_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(error));
  }
};

export const returnSingleOrder = (order, packing) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: ORDERS_RETURN_FINISH_REQUEST,
    order,
    packing,
  });
  try {
    const response = await api.post("/order-return/", {
      order,
      packing,
    });

    dispatch({
      type: ORDERS_RETURN_FINISH_RESPONSE,
      payload: {
        data: response.data,
      },
    });
    dispatch(
      sendNotification({
        type: "success",
        message: `Pedido ${response.data.order} retornado com sucesso`,
      })
    );
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({
      type: ORDERS_RETURN_FINISH_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(error));
  }
};
