import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import {
  closePrintEventsStream,
  deleteRecentRoutes,
  finishDispatch,
  finishOrderDispatch,
  loadOrder,
  loadRoute,
  printNFCe,
  resetFinishStatus,
} from 'actions/Dispatch.js';
import { sendNotification } from 'actions/Notifications.js';
import {
  DISPATCH_SEARCH_TYPES,
  DISPATCH_STEPS,
  DISPATCH_TYPES,
} from 'constants/Dispatch';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { shouldPrintNFe } from '../../../utils/shouldPrintNFe';
import RouteDetails, { PickingTypesPT } from '../components/RouteDetails';
import RouteForm from '../components/RouteForm';
import { styles } from '../constants/styles';

import { PickingTypesByDispatchType } from '../logic';

class DispatchContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.routeData.loading && !nextProps.routeData.success) {
      return {
        routeData: {
          success: nextProps.routeData.success,
          loading: nextProps.routeData.loading,
        },
        step: DISPATCH_STEPS.BEGINNING,
      };
    } else if (nextProps.routeData.success && prevState.routeData.loading) {
      if (!nextProps.routeData.pending) {
        return {
          routeData: {
            success: nextProps.routeData.success,
            loading: nextProps.routeData.loading,
          },
          step: DISPATCH_STEPS.DETAILS,
        };
      }
      return {
        routeData: {
          success: nextProps.routeData.success,
          loading: nextProps.routeData.loading,
        },
        errorDialog: {
          isOpen: true,
          routeData: nextProps.routeData,
        },
        step: DISPATCH_STEPS.BEGINNING,
      };
    } else if (nextProps.routeData.error && prevState.routeData.loading) {
      return {
        routeData: {
          loading: false,
        },
      };
    }
    if (
      nextProps.finishSuccess &&
      prevState.step !== DISPATCH_STEPS.BEGINNING
    ) {
      return {
        step: DISPATCH_STEPS.BEGINNING,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      routeData: {
        success: false,
        loading: false,
      },
      type: DISPATCH_SEARCH_TYPES.ROUTE,
      dispatchType: DISPATCH_TYPES.frozen,
      errorDialog: {
        isOpen: false,
        routeData: null,
      },
      date: moment().format('YYYY-MM-DD'),
      routeOrOrder: '',
      step: DISPATCH_STEPS.BEGINNING,
    };
  }

  componentWillUnmount() {
    this.props.closePrintEventsStream();
  }

  handleTypeChange = type => {
    this.setState({
      type,
    });
  };

  handleDateChange = selectedDate => {
    this.setState({
      date: selectedDate,
    });
  };

  handleDispatchTypeChange = dispatchType => {
    this.setState({ dispatchType });
  };

  handleErrorDialogClose = () => {
    this.setState({
      errorDialog: {
        isOpen: false,
      },
    });
  };

  handleCancel = () => {
    this.setState({
      step: DISPATCH_STEPS.BEGINNING,
      routeOrOrder: '',
      routeData: {
        success: false,
        loading: false,
      },
    });
  };

  handleFinishDispatch = () => {
    const { routeData } = this.props;

    const dispatchType = Object.keys(DISPATCH_TYPES).find(
      key => DISPATCH_TYPES[key] === this.state.dispatchType
    );

    if (this.state.type === DISPATCH_SEARCH_TYPES.ROUTE) {
      this.props.finishDispatch(routeData._id, dispatchType);
    } else {
      this.props.finishOrderDispatch(routeData.orders[0].order, dispatchType);
    }
  };

  handleRouteInput = route => {
    this.setState({
      routeOrOrder: route,
    });
  };

  handleOnClick = () => {
    const { routeOrOrder, date, type, dispatchType } = this.state;

    if (type === DISPATCH_SEARCH_TYPES.ROUTE) {
      this.props.loadRoute({
        number: routeOrOrder,
        dispatchType: Object.keys(DISPATCH_TYPES).find(
          key => DISPATCH_TYPES[key] === dispatchType
        ),
        date,
      });
    } else {
      this.props.loadOrder(
        routeOrOrder,
        Object.keys(DISPATCH_TYPES).find(
          key => DISPATCH_TYPES[key] === dispatchType
        )
      );
    }
  };

  handleOnDeleteLastRoutes = () => {
    this.props.deleteRecentRoutes();
  };

  handlePrintAllNFCes = () => {
    const { routeData, printNFCe } = this.props;
    const orders = [];
    const routeType = routeData.type;
    routeData.orders.forEach(order => {
      if (!shouldPrintNFe(order)) {
        orders.push(order.order);
      }
    });
    printNFCe(orders, routeType);
  };

  renderErrorDialog() {
    const { isOpen, routeData } = this.state.errorDialog;
    const { classes } = this.props;

    const pickingTypes =
      PickingTypesByDispatchType[
        Object.keys(DISPATCH_TYPES).find(
          key => DISPATCH_TYPES[key] === this.state.dispatchType
        )
      ];

    return (
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Entrega</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pedidos incompletos
          </DialogContentText>
          {routeData &&
            routeData.orders
              .filter(order =>
                Object.keys(order.pendingPickings).some(pickingType =>
                  pickingTypes.includes(pickingType)
                )
              )
              .map(order => (
                <Grid item>
                  {order.order} -{' '}
                  {Object.keys(order.pendingPickings).map(pickingType =>
                    order.pendingPickings[pickingType]
                      ? `picking ${PickingTypesPT[pickingType].toLowerCase()}, `
                      : ''
                  )}
                </Grid>
              ))}
        </DialogContent>
        <DialogActions className={classes.justifyContentCenter}>
          <Button
            onClick={() => this.handleErrorDialogClose()}
            variant="raised"
            color="primary"
            className="jr-btn text-blue-gray"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderRouteForm() {
    const { routeData, classes } = this.props;
    if (this.state.step === DISPATCH_STEPS.BEGINNING) {
      if (routeData.loading) {
        return (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            className={classes.loadingAndErrorContainerHeight}
          >
            <CircularProgress size={50} />
          </Grid>
        );
      }
      return (
        <RouteForm
          onRouteInput={this.handleRouteInput}
          onClick={this.handleOnClick}
          onTypeChange={this.handleTypeChange}
          onDispatchTypeChange={this.handleDispatchTypeChange}
          onDateChange={this.handleDateChange}
          lastRoutesNumbers={this.props.lastRoutesNumbers}
          type={this.state.type}
          dispatchType={this.state.dispatchType}
          date={this.state.date}
          deleteRecentRoutes={this.handleOnDeleteLastRoutes}
        />
      );
    }
    return null;
  }

  renderRouteDetails() {
    const {
      routeData,
      finishLoading,
      classes,
      nfcePrintingLoading,
      closePrintEventsStream,
    } = this.props;
    if (this.state.step === DISPATCH_STEPS.DETAILS) {
      if (finishLoading) {
        return (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            className={classes.loadingAndErrorContainerHeight}
          >
            <CircularProgress size={50} />
          </Grid>
        );
      }
      if (!routeData.loading && routeData.orders.length > 0) {
        const { type } = this.state;
        let title;
        if (type === DISPATCH_SEARCH_TYPES.ROUTE) {
          title =
            routeData.number &&
            `Rota ${routeData.number} - ${routeData.period.name}`;
        } else if (type === DISPATCH_SEARCH_TYPES.ORDER) {
          title = 'Retirada de Pedido';
        }

        return (
          <RouteDetails
            closePrintEventsStream={closePrintEventsStream}
            nfcePrintingLoading={nfcePrintingLoading}
            shouldPrintNFe={shouldPrintNFe}
            handlePrintAllNFCes={this.handlePrintAllNFCes}
            date={this.state.date}
            onCancel={this.handleCancel}
            onFinish={this.handleFinishDispatch}
            routeData={routeData}
            type={this.state.type}
            printNFCe={this.props.printNFCe}
            sendNotification={this.props.sendNotification}
            shouldRenderBillingInfo
            title={title}
            pickingTypes={
              PickingTypesByDispatchType[
                Object.keys(DISPATCH_TYPES).find(
                  key => DISPATCH_TYPES[key] === this.state.dispatchType
                )
              ]
            }
          />
        );
      }
      return null;
    }
    return null;
  }

  render() {
    return (
      <Grid id="dispatch-container" container justify="center">
        {this.renderRouteForm()}
        {this.renderRouteDetails()}
        {this.renderErrorDialog()}
      </Grid>
    );
  }
}

DispatchContainer.propTypes = {
  finishLoading: PropTypes.bool,
  finishSuccess: PropTypes.bool,
  finishDispatch: PropTypes.func.isRequired,
  cancelDispatch: PropTypes.func.isRequired,
  loadRoute: PropTypes.func.isRequired,
  loadOrder: PropTypes.func.isRequired,
  finishOrderDispatch: PropTypes.func.isRequired,
  getAvailablePeriods: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  lastRoutesNumbers: PropTypes.array.isRequired,
  deleteRecentRoutes: PropTypes.func.isRequired,
  distributionCenter: PropTypes.object.isRequired,
};

DispatchContainer.defaultProps = {
  loadSuccess: false,
  finishLoading: false,
  finishSuccess: false,
};

const mapStateToProps = state => ({
  finishLoading: state.dispatch.finish.loading,
  finishSuccess: state.dispatch.finish.success,
  finishError: state.dispatch.finish.error,
  routeData: state.dispatch.data,
  lastRoutesNumbers: state.dispatch.lastRoutesNumbers,
  nfcePrintingLoading: state.dispatch.nfcePrinting.loading,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    loadRoute,
    loadOrder,
    finishDispatch,
    finishOrderDispatch,
    resetFinishStatus,
    sendNotification,
    printNFCe,
    closePrintEventsStream,
  })
)(DispatchContainer);
