// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j4COtsYIjQQDoKaOyEd4 {
  width: 280px;
  margin: auto;
}

.Z9ObvzIZqXMDtOaRcDku {
  width: 100%;
}

.h6et9dMpG8KmmWI30Zwy {
  padding: 0 !important;
}

.wRVNFxRu_nV7m7eyOGjn {
  height: 350px;
}

.zxFyYoIU8whpTR_2XOJs {
  margin-top: 70px;
}

#Mcew5JO0LFdeoIudEIlw {
  width: 80%;
  margin: auto;
  margin-top: 10% !important;
}

.JYL5jjqcSySA3dUmBTQD {
  margin-left: 16px;
}

.dUnEbDrC92oGltrVyjAE {
  margin: 0px;
}

.ZmAqAbVNDOsDbDdQdXXW {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.SVu5isABkHiCnwnh8Dh1 {
  height: 64px;
  width: 64px;
  margin-right: 16px;
  margin-left: 16px;
}

.Rlxy5jOL0fyxoBSOJZqI {
  float: right;
}

.Q94puWghSZj1zv0ZQVnQ {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./routes/ProductsLoss/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".input {\n  width: 280px;\n  margin: auto;\n}\n\n.buttons {\n  width: 100%;\n}\n\n.productCard {\n  padding: 0 !important;\n}\n\n.loadingContainer {\n  height: 350px;\n}\n\n.dcSelect {\n  margin-top: 70px;\n}\n\n#card {\n  width: 80%;\n  margin: auto;\n  margin-top: 10% !important;\n}\n\n.multiplierIcon {\n  margin-left: 16px;\n}\n\n.productText {\n  margin: 0px;\n}\n\n.bulletText {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.productIcon {\n  height: 64px;\n  width: 64px;\n  margin-right: 16px;\n  margin-left: 16px;\n}\n\n.finishButton {\n  float: right;\n}\n\n.totalsCard {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `j4COtsYIjQQDoKaOyEd4`,
	"buttons": `Z9ObvzIZqXMDtOaRcDku`,
	"productCard": `h6et9dMpG8KmmWI30Zwy`,
	"loadingContainer": `wRVNFxRu_nV7m7eyOGjn`,
	"dcSelect": `zxFyYoIU8whpTR_2XOJs`,
	"card": `Mcew5JO0LFdeoIudEIlw`,
	"multiplierIcon": `JYL5jjqcSySA3dUmBTQD`,
	"productText": `dUnEbDrC92oGltrVyjAE`,
	"bulletText": `ZmAqAbVNDOsDbDdQdXXW`,
	"productIcon": `SVu5isABkHiCnwnh8Dh1`,
	"finishButton": `Rlxy5jOL0fyxoBSOJZqI`,
	"totalsCard": `Q94puWghSZj1zv0ZQVnQ`
};
export default ___CSS_LOADER_EXPORT___;
