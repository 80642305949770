import {
  SAVE_COUNTING_REQUEST,
  SAVE_COUNTING_RESPONSE,
  SAVE_COUNTING_ERROR,
  GET_COUNTING_REQUEST,
  GET_COUNTING_RESPONSE,
  GET_COUNTING_ERROR,
  FINISH_COUNTING_ERROR,
  FINISH_COUNTING_REQUEST,
  FINISH_COUNTING_RESPONSE,
  CLEAR_COUNTING_STORE,
  CALCULATE_INVENTORY_REQUEST,
  CALCULATE_INVENTORY_RESPONSE,
  CALCULATE_INVENTORY_ERROR,
  INSERT_GREENGROCER_REQUEST,
  INSERT_GREENGROCER_RESPONSE,
  INSERT_GREENGROCER_ERROR,
} from "../constants/ActionTypes";

import { handleRequestError } from "./ErrorHandling";
import { sendNotification, errorNotification } from "actions/Notifications";

export const get = (data) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: GET_COUNTING_REQUEST,
    });
    const response = await api.get("/counting/get", { params: { ...data } });
    dispatch({
      type: GET_COUNTING_RESPONSE,
      payload: {
        data: response.data,
      },
    });
    dispatch({
      type: CLEAR_COUNTING_STORE,
    });
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: GET_COUNTING_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err, false));
  }
};

export const save = (data) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: SAVE_COUNTING_REQUEST,
    });
    const response = await api.post("/counting/save", { ...data });
    dispatch({
      type: SAVE_COUNTING_RESPONSE,
      payload: {
        data: response.data,
      },
    });
    dispatch({
      type: CLEAR_COUNTING_STORE,
    });
    dispatch(
      sendNotification({
        type: "success",
        message: "Checagem salva com sucesso!",
      })
    );
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: SAVE_COUNTING_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err, false));
  }
};

const downloadCsv = (csv) => {
  const csvContent = `data:text/csv;charset=utf-8,${csv}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "contagem.csv");
  document.body.appendChild(link); // Required for FF
  link.click();
};

export const finish = (data) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: FINISH_COUNTING_REQUEST,
    });
    const response = await api.post("/counting/finish", { ...data });
    downloadCsv(response.data.csv);
    dispatch({
      type: FINISH_COUNTING_RESPONSE,
      payload: {
        data: response.data,
      },
    });
    dispatch({
      type: CLEAR_COUNTING_STORE,
    });
    dispatch(
      sendNotification({
        type: "success",
        message:
          "Checagem finalizada com sucesso! Um email foi enviado com os resultados",
      })
    );
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: FINISH_COUNTING_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err, false));
  }
};

export const calculateInventory = (data) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: CALCULATE_INVENTORY_REQUEST,
    });
    const response = await api.post("/counting/calculateInventory", {
      ...data,
    });
    dispatch({
      type: CALCULATE_INVENTORY_RESPONSE,
      payload: {
        data: response.data,
      },
    });
    dispatch({
      type: CLEAR_COUNTING_STORE,
    });
    dispatch(
      sendNotification({
        type: "success",
        message:
          "Estoque calculado com sucesso! Um email foi enviado com os resultados",
      })
    );
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: CALCULATE_INVENTORY_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err, false));
  }
};
