import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import classNames from 'classnames';

const styles = theme => ({
	boxCard: {
		width: '100%',
		padding: '8px 12px 8px 12px',
	},
	errorBorder: {
		border: '2px solid #f2b9b9',
	},
	regularBorder: {
		border: '2px solid #7fc6d2',
	},
	noBorder: {
		border: '0',
	},
	cardTitle: {
		color: '#008ea6',
		marginBottom: '6px',
		fontSize: '12px',
	},
	boxIcon: {
		opacity: 0.25,
		marginRight: '10px',
		marginLeft: '10px',
		marginTop: '8px',
		marginBottom: '8px',
	},
	itemName: {
		marginBottom: '6px',
		color: '#2a3644',
		fontSize: '14px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflowX: 'hidden',
		fontWeight: 'bold',
	},
	productsPerBox: {
		color: '#e98081',
		fontSize: '14px',
		fontWeight: 'bold',
	},
	emptyBoxItemName: {
		color: '#888888',
		fontSize: '12px',
	},
	date: {
		color: '#888888',
		fontSize: '12px',
	},
	expirationDate: {
		color: '#888888',
		fontSize: '12px',
	},
});

const formatDate = (dateString, expiration) => {
	if (expiration) {
		return moment(new Date(dateString))
			.utc()
			.add(expiration, 'day')
			.format('DD/MM/YYYY');
	}
	return moment(new Date(dateString))
		.utc()
		.format('DD/MM/YYYY');
};

const BoxInfoCard = ({ box, classes, error, border }) => (
	<Paper
		className={classNames(
			classes.boxCard,
			border ? (error ? classes.errorBorder : classes.regularBorder) : classes.noBorder
		)}
	>
		<Grid container direction="column" className={classes.container}>
			{box ? (
				<Fragment>
					<Typography className={classes.cardTitle}>Informações da caixa</Typography>
					<Grid container className={classes.item}>
						<Grid container direction="row" justify="space-between">
							<Grid item xs={10}>
								<Typography className={classes.itemName}>{box.name}</Typography>
							</Grid>
							<Typography className={classes.productsPerBox}>
								{box.productsPerBox} un
							</Typography>
						</Grid>
						<Grid container direction="row" justify="space-between">
							<Typography className={classes.date}>
								Fabricação: {formatDate(box.batch)}
							</Typography>
							<Typography className={classes.expirationDate}>
								Validade: {formatDate(box.batch, box.expiration)}
							</Typography>
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Grid container direction="row" justify="flex-start" alignItems="center">
					<img
						id="newPalletButton"
						src="../../../assets/images/leitor_caixa.svg"
						alt="Iniciar pallet novo"
						className={classes.boxIcon}
					/>
					<Typography className={classes.emptyBoxItemName}>
						As informações da caixa vão aparecer aqui.
					</Typography>
				</Grid>
			)}
		</Grid>
	</Paper>
);

BoxInfoCard.propTypes = {
	box: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoxInfoCard);
