import {
  FISCAL_PRINTING_REQUEST,
  FISCAL_PRINTING_RESPONSE,
  FISCAL_PRINTING_ERROR,
  FISCAL_PRINTING_INVOICE_REQUEST,
  FISCAL_PRINTING_INVOICE_RESPONSE,
  FISCAL_PRINTING_INVOICE_ERROR,
} from '../constants/ActionTypes';

const initialState = {
  loading: false,
};

const updateStateItemById = (state, id, update) => {
  const [sale] = state?.data?.filter(o => o?.order === id);

  if (sale) {
    const index = state.data.indexOf(sale);

    if (index !== -1) {
      const data = [...state.data];
      data[index] = {
        ...state.data[index],
        ...update,
      };

      return {
        ...state,
        data,
      };
    }
  }

  return {
    ...state,
  };
};

const fiscalPrinting = (state = initialState, action) => {
  switch (action.type) {
    case FISCAL_PRINTING_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case FISCAL_PRINTING_RESPONSE:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
      };
    case FISCAL_PRINTING_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
      };
    case FISCAL_PRINTING_INVOICE_REQUEST:
      return updateStateItemById(state, action.payload.data.order, {
        isRequestInvoice: true,
        requestInvoiceError: null,
      });
    case FISCAL_PRINTING_INVOICE_RESPONSE:
      return updateStateItemById(state, action.payload.data.order, {
        ...action.payload.data,
        isRequestInvoice: false,
        requestInvoiceError: null,
      });
    case FISCAL_PRINTING_INVOICE_ERROR:
      return updateStateItemById(state, action.payload.data.order, {
        isRequestInvoice: false,
        requestInvoiceError: action.payload.error,
      });
    default:
      return state;
  }
};

export default fiscalPrinting;
