import { handleRequestError } from 'actions/ErrorHandling';
import { INVENTORY_API_ADDRESS } from 'constants/InventoryAPI';
import moment from 'moment';
import {
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST,
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE,
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR,
} from 'constants/ActionTypes';
import axios from 'axios';
import { sendNotification } from 'actions/Notifications';

export const confirmReturnProducts = items => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST,
		});
		const centerId = getState().distributionCenters.selected;
		const centerKind = getState().distributionCenters.selectedKind;
		const authToken = localStorage.getItem('authToken');
		const response = await axios({
			method: 'POST',
			headers: {
				Authorization: authToken ? `Bearer ${authToken}` : '',
				'Content-Type': 'application/json',
			},
			data: {
				items,
				origin: centerId,
				originKind: centerKind,
			},
			url: `${INVENTORY_API_ADDRESS}/orders/confirm-return-product`,
		});
		dispatch({
			type: PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE,
			payload: response.data,
		});
		dispatch(sendNotification({ type: 'success', message: 'Operação finalizada!' }));
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};
