import React from "react";
import Button from "@material-ui/core/Button";
import FlipMove from "react-flip-move";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import BarCodeInput, { checkUsingRegex } from "components/BarCodeInput";
import AlertDialog from "components/AlertDialog";
import { PropTypes } from "prop-types";
import styles from "../styles.css";
import { REGEX_PRODUCT } from "constants/Regexes";
import ProductComponent from "../components/ProductComponent";
import { finishProductsLossScanning, resetStore } from "actions/ProductsLoss";
import { getProductsInfos, errorClear } from "actions/ProductsInfo";
import { connect } from "react-redux";
import { sendNotification } from "actions/Notifications";
import DCSelect from "containers/DCSelect";
import { PRODUCT_LOSS_REASONS, PRODUCT_LOSS_SUBREASONS } from "../../../constants/strings";
import { Paper, Typography, TextField } from "@material-ui/core";

class ProductsLossContainer extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success && prevState.step === 1) {
      return {
        ...prevState,
        productsList: {},
        busyScanner: false,
        step: 0,
        total: 0,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      productsList: {},
      busyScanner: false,
      lossReason: null,
      lossSubReason: null,
      productQuantity: 1,
      lossObservation: null,
      total: 0,
      step: 0,
    };
  }

  componentDidMount() {
    this.props.getProductsInfos();
  }

  componentDidUpdate() {
    if (this.props.success) {
      this.props.resetStore();
    }
  }

  static getDerivedStateFromProps = (props) => {
    if (props.success)
      return {
        productsList: {},
        busyScanner: false,
        lossReason: null,
        lossSubReason: null,
        lossObservation: null,
        total: 0,
        step: 0,
      };
    return null;
  };

  getSkuFromBarcode = (barcode) =>
    String.fromCharCode(96 + parseInt(barcode.substr(1, 2))).toUpperCase() +
    barcode.substr(3, 3);

  findValidSku = (value) => {
    const sku = this.getSkuFromBarcode(value);
    if (!this.props.products[sku]) {
      return Object.keys(this.props.products).find(
        (key) => this.props.products[key].barcode == Number(value)
      );
    }

    return sku
  }

  handleRegisterSubmission = (value) => {
    const sku = this.findValidSku(value)

    if (!this.state.productsList[sku]) {
      this.setState((prevState) => ({
        ...prevState,
        total: prevState.total + this.state.productQuantity,
        productsList: {
          ...prevState.productsList,
          [sku]: {
            ...this.props.products[sku],
            quantity: this.state.productQuantity,
            batches: { [value]: value },
          }, // ALTERAR: aqui vai mais um campo: loss-reason
        },
        productQuantity: 1,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        total: prevState.total + this.state.productQuantity,
        productsList: {
          ...prevState.productsList,
          [sku]: {
            ...prevState.productsList[sku],
            quantity:
              prevState.productsList[sku].quantity + this.state.productQuantity,
            batches: { ...prevState.productsList[sku].batches, [value]: value },
          },
        },
        productQuantity: 1,
      }));
    }
  };

  handleCheckValid = (value) => {
    const sku = this.findValidSku(value)
    if (!sku) {
      this.props.sendNotification({
        type: "error",
      });
      setTimeout(() => {
        this.setState((prevState) => ({
          ...prevState,
          busyScanner: false,
        }));
      }, 1000);
      return false;
    }

    if (!this.state.busyScanner && checkUsingRegex(value, REGEX_PRODUCT)) {
      this.setState((prevState) => ({
        ...prevState,
        busyScanner: true,
      }));
      this.props.sendNotification({
        type: "success",
      });
      setTimeout(() => {
        this.setState((prevState) => ({
          ...prevState,
          busyScanner: false,
        }));
      }, 1000);
      return true;
    }
    return false;
  };

  handleClear = () => {
    this.setState((prevState) => ({
      ...prevState,
      productsList: {},
      busyScanner: false,
      total: 0,
    }));
  };

  handleScanProducts = () => {
    if (!this.state.lossReason || !this.state.lossSubReason) {
      alert(
        'Os campos de motivo e detalhe do motivo precisam ser preenchidos :)'
      )
    } else if (
      this.state.lossReason === PRODUCT_LOSS_REASONS.OTHER &&
      !this.state.lossObservation
    ) {
      alert('É obrigatório preencher o campo de observações.')
    } else {
      this.setState(prevState => ({ ...prevState, step: 2 }))
    }
  };

  handleFinish = () => {
    const { productsList, lossReason, lossSubReason, lossObservation } = this.state;
    const items = Object.keys(this.state.productsList).map((key) => ({
      _id: productsList[key]._id,
      sku: key,
      name: productsList[key].name,
      quantity: -Number(productsList[key].quantity),
      batches: Object.keys(productsList[key].batches),
      observation: {
        lossReason,
        lossSubReason,
        lossObservation,
      },
    }));
    this.props.finishProductsLossScanning(items);
  };

  handleModalClose = () => {
    this.props.errorClear();
  };

  handleStart = () => {
    this.setState((prevState) => ({
      ...prevState,
      step: 1,
    }));
  };

  handleLossReasonSelect = (selectedReason) => {
    this.setState((prevState) => ({
      ...prevState,
      lossReason: selectedReason.value,
      lossSubReason: null
    }));
  };

  handleLossSubReasonSelect = (selectedSubReason) => {
    this.setState((prevState) => ({
      ...prevState,
      lossSubReason: selectedSubReason.value,
    }));
  };


  handleObservationAdded = (observationObject) => {
    const { value } = observationObject.target;
    this.setState((prevState) => ({
      ...prevState,
      lossObservation: value,
    }));
  };

  onChangeProductQuantity = (quantityObject) => {
    const { value } = quantityObject.target;
    this.setState((prevState) => ({
      ...prevState,
      productQuantity: parseInt(value),
    }));
  };

  sendErrorNotification = (value) => value;

  renderDCSelect() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={styles.dcSelect}
      >
        <Grid item xs={4}>
          <Card>
            {!this.props.products ? (
              <CircularProgress size={50} />
            ) : (
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <h2>Escolha o centro de distribuição</h2>
                  </Grid>
                  <Grid item className={styles.input}>
                    <DCSelect />
                  </Grid>
                  <Grid item justify="center">
                    <Button
                      variant="raised"
                      color="primary"
                      onClick={this.handleStart}
                      className={styles.start}
                    >
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }

  disabledFinish() {
    const { productsList } = this.state;
    return productsList && Object.values(productsList).length <= 0;
  }

  renderCard() {
    return (
      <Grid
        container
        justify="space-between"
        alignItems="flex-end"
        spacing={24}
      >
        <Grid item xs={7}>
          <Card>
            {!this.props.products ? (
              <CircularProgress size={50} />
            ) : (
              <CardContent>
                <Grid container direction="column">
                  <Grid item>Leia produtos</Grid>
                  <Grid item className={styles.input}>
                    <TextField
                      id="standard-basic"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      label="Quantidade do Produto"
                      value={this.state.productQuantity}
                      onChange={this.onChangeProductQuantity}
                    />
                    <BarCodeInput
                      onMatch={this.handleRegisterSubmission}
                      checkValid={this.handleCheckValid}
                      onError={this.sendErrorNotification}
                      autoFocus
                      debounce
                    />
                  </Grid>
                  <Grid item className={styles.buttons}>
                    <Button
                      variant="raised"
                      color="secondary"
                      onClick={this.handleClear}
                    >
                      Limpar
                    </Button>
                    <Button
                      variant="raised"
                      color="primary"
                      className={styles.finishButton}
                      disabled={this.disabledFinish()}
                      onClick={this.handleFinish}
                    >
                      Finalizar
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item xs={5} alignItems="center" className={styles.totalsCard}>
          <Card>
            <CardContent>
              <h2>Total lido: {this.state.total}</h2>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  renderJustifyLossesScreen() {
    const { productsList, lossReason, lossSubReason } = this.state;
    return (
      <Paper>
        <Typography variant="h4" paragraph style={{ margin: '15px' }}>
          Justificar Perdas
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={16}
          style={{ padding: '15px' }}
        >
          <Grid item xs={3}>
            <div>
              <Select
                options={Object.values(PRODUCT_LOSS_REASONS).map(reason => ({
                  value: reason,
                  label: reason,
                }))}
                value={
                  lossReason && {
                    label: lossReason,
                    value: lossReason,
                  }
                }
                placeholder="Selecione o motivo"
                onChange={this.handleLossReasonSelect}
                required
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Select
                options={(PRODUCT_LOSS_SUBREASONS[lossReason] || []).map(
                  subreason => ({
                    value: subreason,
                    label: subreason,
                  })
                )}
                value={
                  lossSubReason && {
                    label: lossSubReason,
                    value: lossSubReason,
                  }
                }
                placeholder="Selecione o detalhe do motivo"
                onChange={this.handleLossSubReasonSelect}
                required
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="outlined-full-width"
              label="Observações"
              style={{ marginRight: '50px' }}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={this.handleObservationAdded}
            />
          </Grid>
        </Grid>
        <Button
          variant="raised"
          color="primary"
          className={styles.finishButton}
          onClick={this.handleScanProducts}
          style={{ margin: '30px' }}
        >
          Escanear produtos
        </Button>
      </Paper>
    )
  }

  renderProductsTable() {
    const { productsList } = this.state;
    return (
      <Grid container xs={12} justify="center">
        <Grid item xs={12}>
          <Card className={styles.productCard}>
            {this.state.productsList && (
              <CardContent className={styles.productCard}>
                <Grid container xs={12} direction="column">
                  <FlipMove duration={300} easing="ease-out">
                    {Object.keys(productsList).map((sku, index) => (
                      <ProductComponent
                        index={index}
                        sku={sku}
                        name={productsList[sku].name}
                        imageUrl={productsList[sku].imageUrl}
                        quantity={productsList[sku].quantity}
                        color={productsList[sku].color}
                        key={productsList[sku]._id}
                      />
                    ))}
                  </FlipMove>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }

  renderErrorModal() {
    const { error } = this.props;
    return (
      <AlertDialog
        open={error}
        title={`Erro: ${error}`}
        children={`Clique em 'FINALIZAR' para tentar novamente`}
        onClose={this.handleModalClose}
        actions={[
          {
            onClick: this.handleModalClose,
            title: "Ok",
            type: "primary",
            autoFocus: true,
          },
        ]}
      />
    );
  }

  renderLoading() {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={styles.loadingContainer}
      >
        <CircularProgress size={50} />
      </Grid>
    );
  }

  render() {
    return (
      <Grid container direction="column">
        {this.props.loading && this.renderLoading()}
        {!this.props.loading && this.state.step === 0 && this.renderDCSelect()}
        {!this.props.loading && this.state.step === 2 && this.renderCard()}
        <br />
        <br />
        {!this.props.loading &&
          this.state.step === 2 &&
          this.renderProductsTable()}
        {!this.props.loading &&
          this.state.step === 1 &&
          this.renderJustifyLossesScreen()}
        {this.renderErrorModal()}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.productsLoss.loading,
  error: state.productsLoss.error,
  success: state.productsLoss.success,
  products: state.productsLoss.products,
});

ProductsLossContainer.propTypes = {
  getProductsInfos: PropTypes.func.isRequired,
};

ProductsLossContainer.defaultProps = {};

export default connect(mapStateToProps, {
  getProductsInfos,
  finishProductsLossScanning,
  sendNotification,
  errorClear,
  resetStore,
})(ProductsLossContainer);
