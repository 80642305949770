import {
	FROZEN_POST_CONTAINER_REQUEST,
	FROZEN_POST_CONTAINER_RESPONSE,
	FROZEN_POST_CONTAINER_ERROR,
	FROZEN_RESET_CONTAINER_STATE,
	FROZEN_POST_CONTAINER_CHECK,
} from '../constants/ActionTypes';

const initialState = {
	container: {},
	finish: false,
	loading: false,
	error: null,
	check: {
		repeated: false,
		message: '',
	},
};

const frozenWMS = (state = initialState, action) => {
	switch (action.type) {
		case FROZEN_POST_CONTAINER_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case FROZEN_POST_CONTAINER_RESPONSE:
			return {
				...state,
				container: action.payload.data.container || null,
				finish: true,
				loading: false,
			};
		case FROZEN_POST_CONTAINER_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error || 'Unknow error',
			};
		case FROZEN_POST_CONTAINER_CHECK:
			return {
				...state,
				check: {
					repeated: true,
					message: action.payload.data,
				},
			};
		case FROZEN_RESET_CONTAINER_STATE:
			return {
				container: {},
				finish: false,
				loading: false,
				error: '',
				check: {
					repeated: false,
					message: '',
				},
			};
		default:
			return state;
	}
};

export default frozenWMS;
