import {
	PRODUCTS_INFO_REQUEST,
	PRODUCTS_INFO_RESPONSE,
	PRODUCTS_INFO_ERROR,
	PRODUCTS_INFO_ERROR_CLEAR,
	GET_COUNTING_REQUEST,
	GET_COUNTING_RESPONSE,
	GET_COUNTING_ERROR,
	FINISH_COUNTING_ERROR,
	FINISH_COUNTING_REQUEST,
	FINISH_COUNTING_RESPONSE,
	SAVE_COUNTING_ERROR,
	SAVE_COUNTING_REQUEST,
	SAVE_COUNTING_RESPONSE,
	CLEAR_COUNTING_STORE,
	CALCULATE_INVENTORY_REQUEST,
	CALCULATE_INVENTORY_RESPONSE,
	CALCULATE_INVENTORY_ERROR,
	INSERT_GREENGROCER_REQUEST,
	INSERT_GREENGROCER_RESPONSE,
	INSERT_GREENGROCER_ERROR
} from 'constants/ActionTypes';

const initialState = {
	products: {},
	loading: false,
	error: null,
	inventoryCount: null,
	saving: false,
	saveSuccess: false,
	saveError: null,
	getting: false,
	getSuccess: false,
	getError: null,
	finishing: false,
	finishSuccess: false,
	finishError: null,
	calculatingInventory: false,
	calculateInventorySuccess: false,
	calculateInventoryError: null,
	greenGrocerList: {}
};

const Counting = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_INFO_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case PRODUCTS_INFO_RESPONSE:
			return {
				...state,
				products: action.payload,
				loading: false,
				error: null,
			};
		case PRODUCTS_INFO_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.data,
			};
		case INSERT_GREENGROCER_REQUEST: // optimistic render
			const { quantity, product } = action.payload;
			return {
				...state,
				loading: true,
				error: null,
				greenGrocerList: {
					...state.greenGrocerList,
					[product.sku]: {
						...product,
						quantity: 
							(state.greenGrocerList && 
							state.greenGrocerList[product.sku] && 
							state.greenGrocerList[product.sku].quantity + Number(quantity)) ||
							Number(quantity)
					}
				}
			};
		case INSERT_GREENGROCER_RESPONSE:
			return {
				...state,
				loading: false,
				error: null,
			};
		case INSERT_GREENGROCER_ERROR: // optimistic rendering handler
			const remainingQuantity = state.greenGrocerList[action.payload.product.sku].quantity - Number(action.payload.quantity)
			return {
				...state,
				loading: false,
				error: action.payload.data,
				greenGrocerList: remainingQuantity === 0 ? 
					Object.values(state.greenGrocerList).reduce((acc, curr) => {
						if (curr.sku === action.payload.product.sku) return acc;
						return {
							...acc,
							[curr.sku]: curr
						}
					}, {})
					:
					{
						...state.greenGrocerList,
						[action.payload.product.sku]: {
							...action.payload.product,
							quantity: remainingQuantity
						}
					}
			};
		case PRODUCTS_INFO_ERROR_CLEAR:
			return {
				...state,
				success: false,
				loading: false,
				error: null,
			};
		case GET_COUNTING_REQUEST:
			return {
				...state,
				getting: true,
				getSuccess: false,
				getError: null,
			};
		case GET_COUNTING_RESPONSE:
			return {
				...state,
				inventoryCount: action.payload.data,
				getting: false,
				getSuccess: true,
				getError: null,
			};
		case GET_COUNTING_ERROR:
			return {
				...state,
				getting: false,
				getSuccess: false,
				getError: action.payload.data,
			};
		case SAVE_COUNTING_REQUEST:
			return {
				...state,
				saveSuccess: false,
				saving: true,
				saveError: null,
			};
		case SAVE_COUNTING_RESPONSE:
			return {
				...state,
				saveSuccess: true,
				saving: false,
				saveError: null,
			};
		case SAVE_COUNTING_ERROR:
			return {
				...state,
				saveSuccess: false,
				saving: false,
				saveError: action.payload.data,
			};
		case FINISH_COUNTING_REQUEST:
			return {
				...state,
				finishSuccess: false,
				finishing: true,
				finishError: null,
			};
		case FINISH_COUNTING_RESPONSE:
			return {
				...state,
				finishSuccess: true,
				finishing: false,
				finishError: null,
			};
		case FINISH_COUNTING_ERROR:
			return {
				...state,
				finishSuccess: false,
				finishing: false,
				finishError: action.payload.data,
			};
		case CALCULATE_INVENTORY_REQUEST:
			return {
				...state,
				calculateInventorySuccess: false,
				calculatingInventory: true,
				calculateInventoryError: null,
			};
		case CALCULATE_INVENTORY_RESPONSE:
			return {
				...state,
				calculateInventorySuccess: true,
				calculatingInventory: false,
				calculateInventoryError: null,
			};
		case CALCULATE_INVENTORY_ERROR:
			return {
				...state,
				calculateInventorySuccess: false,
				calculatingInventory: false,
				calculateInventoryError: action.payload.data,
			};
		case CLEAR_COUNTING_STORE:
			return {
				...state,
				finishSuccess: false,
				saveSuccess: false,
				getSuccess: false,
			};
		default:
			return state;
	}
};

export default Counting;
