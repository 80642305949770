import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import { ROUTES_STATUS } from 'constants/DeliveryRoutes';

import Styles from '../styles.css';

const getBackgroundColor = route => {
	if (route.status === ROUTES_STATUS.ALLOCATING || route.status === ROUTES_STATUS.IN_TRANSIT) {
		if (route.dispatched) {
			return Styles.routeListCalledAndDispatched;
		}
		if (route.external_dispatch && route.external_dispatch.end) {
			return Styles.routeListExternalyDispatched;
		}
		return Styles.routeListCalled;
	}
	return null;
};

class RoutesSelect extends React.Component {
	handleChange = route => {
		const routeId = route._id;
		this.props.onSelect(routeId);
	};

	renderRoutesList = (routes, selectedRoute) => (
		<List>
			{routes.map(route => (
				<ListItem
					button
					disabled={selectedRoute === route._id}
					onClick={() => {
						this.handleChange(route);
					}}
					key={route._id}
					className={getBackgroundColor(route)}
				>
					<ListItemText primary={route.number} />
				</ListItem>
			))}
		</List>
	);

	render() {
		const { routes, selectedRoute } = this.props;

		return (
			<Card autoComplete="off" className={Styles.selectRouteMenu}>
				<div className="col-lg-12 col-sm-12 col-12">
					{this.renderRoutesList(routes, selectedRoute)}
				</div>
			</Card>
		);
	}
}

RoutesSelect.propTypes = {
	routes: PropTypes.array,
	onSelect: PropTypes.func.isRequired,
	selectedRoute: PropTypes.string,
};

RoutesSelect.defaultProps = {
	routes: [],
	selectedRoute: '',
};

export default RoutesSelect;
