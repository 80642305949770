import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { BaseModal } from '../BaseModal';

export const FinishModal = ({ onConclude }) => {
	const classes = useStyles();

	return (
		<BaseModal actions={[{ text: 'concluir', onClick: onConclude }]}>
			<Grid container direction="row" alignItems="center">
				<img
					id="closePalletModal"
					src="../../../assets/images/pallet.svg"
					alt="Imagem de Pallet Fechado"
				/>
				<Grid item xs>
					<Typography className={classes.text}>
						Não esqueça de strechar o pallet fechado! ;)
					</Typography>
				</Grid>
			</Grid>
		</BaseModal>
	);
};

FinishModal.propTypes = {
	onConclude: PropTypes.func.isRequired,
};
