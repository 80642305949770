import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { BaseModal } from '../BaseModal';

export const ConfirmationModal = ({ pallet, onConfirm, onCancel }) => {
	const classes = useStyles();

	return (
		<BaseModal
			actions={[
				{ text: 'cancelar', onClick: onCancel },
				{ text: 'confirmar', onClick: onConfirm },
			]}
		>
			<Grid container direction="column">
				<Typography className={classes.text}>
					Tem certeza que deseja fechar o pallet selecionado?
				</Typography>
				<Typography className={classes.palletIndex}>Pallet {pallet._id}</Typography>
				<Grid item xs>
					{pallet.items.map(item => (
						<Grid container direction="row" wrap="nowrap">
							<Grid item zeroMinWidth>
								<Typography className={classes.itemName} noWrap>
									{item.name}
								</Typography>
							</Grid>
							<Grid item>
								<Typography className={classes.boxQuantity} noWrap>
									{item.quantity} cx
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</BaseModal>
	);
};

ConfirmationModal.propTypes = {
	pallet: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};
