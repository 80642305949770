import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { BaseModal } from '../../ClosePallet/components/BaseModal';

const styles = theme => ({
	root: {
		width: '90%',
	},
	title: {
		fontFamily: 'Roboto',
		color: '#38424B',
		fontSize: '20px',
		fontWeight: 'bold',
		marginBottom: '16px',
	},
});

const ConfirmModal = ({ open, classes, onClick, onClose }) => (
	<Modal open={open} onClose={onClose}>
		<BaseModal
			buttonTextColor="#008EA6"
			spaceBetween
			fullWidth
			matchContent
			actions={[
				{ text: 'Voltar', onClick: onClose },
				{ text: 'Salvar', onClick },
			]}
		>
			<Typography className={classes.title}>Tem certeza que deseja salvar?</Typography>
		</BaseModal>
	</Modal>
);

export default withStyles(styles)(ConfirmModal);
