export const STYLE_DESKTOP = {
	Containers: {
		DefaultStyle: {
			margin: '0% 0% -5% 26%',
		},
	},
	NotificationItem: {
		DefaultStyle: {
			marginTop: '0',
			borderTop: '0',
			color: '#ffffff',
			fontSize: '1em',
			width: '720px',
			minHeight: '86px',
			fontWeight: 'normal',
			transform: 'translateX(-30%)',
			paddingLeft: '10%',
		},
		success: {
			backgroundColor: '#008EA6',
		},

		error: {
			backgroundColor: '#e94848',
		},

		warning: {
			backgroundColor: '#ffab00',
		},

		info: {
			backgroundColor: '#7d4294',
		},
	},
	MessageWrapper: {
		DefaultStyle: {
			marginTop: '6px',
			marginLeft: '10.5%',
			marginRight: '15.8%',
			display: 'flex',
			height: '80%',
			alignItems: 'center',
			fontFamily: 'Roboto',
			fontSize: '16px',
			borderLeft: '1px solid',
			paddingLeft: '3.1%',
		},
	},
	Dismiss: {
		DefaultStyle: {
			marginTop: '4%',
			marginRight: '4%',
			backgroundColor: '#008EA6',
			fontSize: '30px',
			color: '#ffffff',
			fontWeight: '100',
			fontFamily: 'Open Sans',
		},
		success: {
			backgroundColor: '#008EA6',
		},

		error: {
			backgroundColor: '#e94848',
		},

		warning: {
			backgroundColor: '#ffab00',
		},

		info: {
			backgroundColor: '#7d4294',
		},
	},
};

export const STYLE_MOBILE = {
	Containers: {
		DefaultStyle: {
			marginBottom: '10%',
			width: '100%',
			padding: 0,
			left: 0,
			top: '45px',
			zIndex: 1000,
			height: '79px',
		},
	},
	NotificationItem: {
		DefaultStyle: {
			display: 'flex',
			alignItems: 'center',
			marginTop: '0',
			borderTop: '0',
			color: '#ffffff',
			width: '100%',
			height: '100%',
			padding: 0,
		},
		success: {
			backgroundColor: '#008EA6',
		},

		error: {
			backgroundColor: '#e94848',
		},

		warning: {
			backgroundColor: '#ffab00',
		},

		info: {
			backgroundColor: '#7d4294',
		},
	},
	MessageWrapper: {
		DefaultStyle: {
			marginLeft: '19.5%',
			marginRight: '15.8%',
			display: 'flex',
			height: '60%',
			alignItems: 'center',
			fontFamily: 'Roboto',
			fontSize: '12px',
			borderLeft: '1px solid',
			paddingLeft: '3.1%',
		},
	},
	Dismiss: {
		DefaultStyle: {
			marginTop: '3%',
			marginRight: '3%',
			backgroundColor: '#008EA6',
			fontSize: '30px',
			color: '#ffffff',
			fontWeight: '100',
			fontFamily: 'Open Sans',
		},
		success: {
			backgroundColor: '#008EA6',
		},

		error: {
			backgroundColor: '#e94848',
		},

		warning: {
			backgroundColor: '#ffab00',
		},

		info: {
			backgroundColor: '#7d4294',
		},
	},
	Action: {
		DefaultStyle: {
			display: 'none',
		},
	},
};
