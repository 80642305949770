import axios from 'axios';
import { INVENTORY_API_ADDRESS } from 'constants/InventoryAPI';
import {
	GET_PALLETS_REQUEST,
	GET_PALLETS_RESPONSE,
	GET_PALLETS_ERROR,
	SET_PALLET,
	CLOSE_PALLET_REQUEST,
	CLOSE_PALLET_RESPONSE,
	CLOSE_PALLET_ERROR,
	RESET_CLOSE_PALLETS_STORE,
} from '../constants/ActionTypes';
import { handleRequestError } from './ErrorHandling';

export const getPallets = () => async dispatch => {
	try {
		dispatch({
			type: GET_PALLETS_REQUEST,
		});
		const authToken = localStorage.getItem('authToken');
		const response = await axios.get(`${INVENTORY_API_ADDRESS}/pallets/get-all`, {
			headers: {
				Authorization: authToken ? `Bearer ${authToken}` : '',
				'Content-Type': 'application/json',
			},
			params: {
				open: true,
			},
		});
		dispatch({
			type: GET_PALLETS_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: GET_PALLETS_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const setPallet = pallet => dispatch => {
	dispatch({
		type: SET_PALLET,
		payload: {
			pallet,
		},
	});
};

export const closePallet = (pallet, boxCode) => async dispatch => {
	try {
		dispatch({
			type: CLOSE_PALLET_REQUEST,
		});
		const authToken = localStorage.getItem('authToken');
		const response = await axios.put(
			`${INVENTORY_API_ADDRESS}/pallets/close-pallet/${pallet._id}`,
			{ boxCode },
			{
				headers: {
					Authorization: authToken ? `Bearer ${authToken}` : '',
					'Content-Type': 'application/json',
				},
			}
		);
		dispatch({
			type: CLOSE_PALLET_RESPONSE,
			payload: {
				palletClosed: response.status === 200,
			},
		});
	} catch (err) {
		if (err.response.status === 304) {
			dispatch({
				type: CLOSE_PALLET_RESPONSE,
				payload: {
					alreadyClosed: true,
				},
			});
			return;
		}
		const error = err.response ? err.response.data : err;
		dispatch({
			type: CLOSE_PALLET_ERROR,
			payload: {
				error,
			},
		});
	}
};

export const resetStore = () => dispatch => {
	dispatch({
		type: RESET_CLOSE_PALLETS_STORE,
	});
};
