import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'utils/IntlMessages';

const Footer = () => (
	<footer className="app-footer">
		<div className="d-flex flex-row justify-content-between">
			<div>
				<span> Liv Up &copy; 2018</span>
			</div>
		</div>
	</footer>
);

export default Footer;
