import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { resetNotification } from 'actions/Notifications';
import { BREAKPOINT_MOBILE } from 'constants/Layout';
import { STYLE_DESKTOP, STYLE_MOBILE } from './notificationStyle';

import SUCCESS_SOUND from '../../../public/sounds/beep.mp3';
import ERROR_SOUND from '../../../public/sounds/error.mp3';
import INFO_SOUND from '../../../public/sounds/beep2.mp3';

const DEFAULT_AUTO_DISMISS = 3;

const playSound = level => {
	if (level === 'success') {
		new Audio(SUCCESS_SOUND).play();
	} else if (level === 'error') {
		new Audio(ERROR_SOUND).play();
	} else if (level === 'info') {
		new Audio(INFO_SOUND).play();
	}
};

const getIcon = level => {
	const mobile = window.innerWidth < 600;
	switch (level) {
		case 'info':
			return (
				<img
					id="newPalletButton"
					src="../../assets/images/error-icon.svg"
					alt="Iniciar pallet novo"
					style={{
						position: 'absolute',
						left: mobile ? 0 : 20,
						marginLeft: '16px',
						...(!mobile && { top: 20 }),
					}}
				/>
			);
		case 'success':
			return (
				<img
					id="newPalletButton"
					src="../../assets/images/check_line.svg"
					alt="Iniciar pallet novo"
					style={{
						position: 'absolute',
						left: mobile ? 0 : 20,
						marginLeft: '16px',
						...(!mobile && { top: 20 }),
					}}
				/>
			);
		case 'warning':
			return (
				<img
					id="newPalletButton"
					src="../../assets/images/danger.svg"
					alt="Iniciar pallet novo"
					style={{
						width: '43px',
						height: '43px',
						position: 'absolute',
						left: mobile ? 0 : 20,
						marginLeft: '16px',
						...(!mobile && { top: 20 }),
					}}
				/>
			);
		case 'error':
			return (
				<img
					id="newPalletButton"
					src="../../assets/images/error-icon.svg"
					alt="Iniciar pallet novo"
					style={{
						position: 'absolute',
						left: mobile ? 0 : 20,
						marginLeft: '16px',
						...(!mobile && { top: 20 }),
					}}
				/>
			);
		default:
			return null;
	}
};

class NotificationCenter extends React.Component {
	constructor(props) {
		super(props);
		this.notificationSystem = null;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.type && nextProps.type !== '') {
			this.addNotification(
				nextProps.type,
				nextProps.message,
				nextProps.autoDismiss,
				nextProps.soundOff
			);
			this.props.resetNotification();
		}
	}

	addNotification(level, message, autoDismiss, soundOff) {
		if (!soundOff) {
			playSound(level);
		}
		if (message && message !== '') {
			this.notificationSystem.addNotification({
				autoDismiss: autoDismiss || autoDismiss === 0 ? autoDismiss : DEFAULT_AUTO_DISMISS,
				message,
				level,
				position: 'tc',
				children: getIcon(level),
			});
		}
	}

	render() {
		let style = STYLE_DESKTOP;
		if (window.innerWidth <= BREAKPOINT_MOBILE) {
			style = STYLE_MOBILE;
		}
		return (
			<NotificationSystem
				ref={ref => {
					this.notificationSystem = ref;
				}}
				style={style}
			/>
		);
	}
}

NotificationCenter.propTypes = {
	// States:
	message: PropTypes.string,
	type: PropTypes.string,
	autoDismiss: PropTypes.number,
	soundOff: PropTypes.bool,
	// Actions
	resetNotification: PropTypes.func.isRequired,
};

NotificationCenter.defaultProps = {
	// States:
	message: null,
	type: null,
	autoDismiss: null,
	soundOff: null,
};

function mapStateToProps(state) {
	return {
		type: state.notificationCenter.type,
		message: state.notificationCenter.message,
		autoDismiss: state.notificationCenter.autoDismiss,
		soundOff: state.notificationCenter.soundOff,
	};
}

export default connect(
	mapStateToProps,
	{ resetNotification }
)(NotificationCenter);
