import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';
import SidenavContent from './SidenavContent';

class SideNav extends React.PureComponent {
	componentDidMount() {
		window.addEventListener('resize', () => {
			this.props.updateWindowWidth($(window).width());
		});
	}

	onToggleCollapsedNav = e => {
		const val = !this.props.navCollapsed;
		this.props.toggleCollapsedNav(val);
	};

	render() {
		const { navCollapsed, navDisabled, drawerType, width } = this.props;
		const drawerStyle = drawerType.includes(FIXED_DRAWER)
			? 'd-xl-flex'
			: drawerType.includes(COLLAPSED_DRAWER)
				? ''
				: 'd-flex';
		let type = 'permanent';
		if (
			drawerType.includes(COLLAPSED_DRAWER) ||
			(drawerType.includes(FIXED_DRAWER) && width < 1200)
		) {
			type = 'temporary';
		}

		if (navDisabled) return null;
		
		return (
			<div className={`app-sidebar d-none ${drawerStyle}`}>
				<Drawer
					className="app-sidebar-content"
					variant={type}
					open={type.includes('temporary') ? navCollapsed : true}
					onClose={this.onToggleCollapsedNav}
					classes={{
						paper: 'side-nav',
					}}
				>
					<UserInfo />
					<SidenavContent routes={this.props.routes} />
				</Drawer>
			</div>
		);
	}
}

const mapStateToProps = ({ settings }) => {
	const { navCollapsed, navDisabled, drawerType, width } = settings;
	return { navCollapsed, navDisabled, drawerType, width };
};

SideNav.propTypes = {
	// States
	navCollapsed: PropTypes.bool,
	navDisabled: PropTypes.bool,
	drawerType: PropTypes.string,
	width: PropTypes.number,
	// Actions
	updateWindowWidth: PropTypes.func.isRequired,
	toggleCollapsedNav: PropTypes.func.isRequired,
};

SideNav.defaultProps = {
	// States
	navCollapsed: false,
	navDisabled: false,
	drawerType: '',
	width: 0,
};

export default withRouter(
	connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
);
