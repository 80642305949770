import { sendNotification } from "actions/Notifications.js";
import {
  DISPATCH_FINISH_REQUEST,
  DISPATCH_FINISH_RESPONSE,
  DISPATCH_FINISH_ERROR,
  DISPATCH_FINISH_RESET_STATUS,
  DISPATCH_LOAD_ROUTE_REQUEST,
  DISPATCH_LOAD_ROUTE_ERROR,
  DISPATCH_LOAD_ROUTE_RESPONSE,
  DISPATCH_CLEAR_LAST_ROUTES,
  DISPATCH_PRINT_NFCE_REQUEST,
  DISPATCH_PRINT_NFCE_RESPONSE,
  DISPATCH_PRINT_NFCE_ERROR,
} from "../constants/ActionTypes";
import { handleRequestError } from "./ErrorHandling";
import axios from "axios";

let eventSource;

export const loadRoute = ({ number, date, dispatchType }) => async (
  dispatch,
  getState,
  api
) => {
  const distributionCenter = getState().distributionCenters.selected;
  const period = getState().periods.selected;
  try {
    dispatch({
      type: DISPATCH_LOAD_ROUTE_REQUEST,
      route: {
        number,
        date,
        period,
        distributionCenter,
      },
    });
    const response = await api.get("/dispatch/get-route", {
      params: { number, date, period, distributionCenter, dispatchType },
    });
    dispatch({
      type: DISPATCH_LOAD_ROUTE_RESPONSE,
      payload: {
        data: response.data,
      },
    });
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: DISPATCH_LOAD_ROUTE_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err));
  }
};
export const closePrintEventsStream = () => async () => {
  eventSource?.close();
};

export const openPrintEventsStream = (orders, routeType) => async (
  dispatch
) => {
  const authToken = localStorage.getItem("authToken");
  eventSource = new EventSource(
    `${process.env.API_ADDRESS}/dispatch/print-events-stream?jwt=${authToken}&orders=${orders}&routeType=${routeType}`,
    { withCredentials: true }
  );

  eventSource.addEventListener("error", (event) => {
    const { data, lastEventId } = event;
    if (Number(lastEventId) === 1)
      return dispatch(
        sendNotification({ type: "info", message: data, autoDismiss: 0 })
      );
    dispatch(
      sendNotification({ type: "error", message: data, autoDismiss: 0 })
    );
  });
  eventSource.addEventListener("success", (event) => {
    const { data } = event;
    if (data !== "") {
      dispatch(sendNotification({ type: "success", message: data }));
    }
    dispatch(closePrintEventsStream());
    dispatch({ type: DISPATCH_PRINT_NFCE_RESPONSE });
  });
};

export const printNFCe = (orders, routeType = null) => async (
  dispatch,
  getState,
  api
) => {
  try {
    const distributionCenterId = getState().distributionCenters.selected;
    dispatch(sendNotification({ type: "info", message: "Gerando nota(s)" }));
    if (distributionCenterId === "5da637a5b15d9955a5015b64") {
      const res = await api.get("/cfe", {
        params: { orders },
      });
      res.data.cfeObjects.forEach(async (cfeObject) => {
        const satResponse = await axios.post(
          "http://localhost:55555/fiscal/sat",
          cfeObject.cfe
        );
        if (satResponse && satResponse.data) {
          const {
            arquivo_cfe_base64,
            mensagem_sefaz,
            status,
            chave_cfe,
            xml_tmp_base64,
            arquivo_cfe_pdf_base64,
          } = satResponse.data;
          await api.post("/cfe/save", {
            order: cfeObject.order,
            satResponse: {
              arquivo_cfe_base64,
              mensagem_sefaz,
              status,
              chave_cfe,
              arquivo_cfe_pdf_base64,
            },
          });
        }
      });
      res.data.danfes.forEach(async (danfe) => {
        const file = new Blob(
          [Uint8Array.from(atob(danfe), (c) => c.charCodeAt(0))],
          {
            type: "application/pdf",
          }
        );
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    } else {
      dispatch({ type: DISPATCH_PRINT_NFCE_REQUEST });
      dispatch(openPrintEventsStream(orders, routeType));
    }
    // dispatch({ type: DISPATCH_PRINT_NFCE_REQUEST });
    // dispatch(openPrintEventsStream(orders, routeType));
  } catch (err) {
    dispatch(closePrintEventsStream());
    dispatch({ type: DISPATCH_PRINT_NFCE_ERROR });
  }
};

export const deleteRecentRoutes = () => async (dispatch, getState, api) => {
  dispatch({
    type: DISPATCH_CLEAR_LAST_ROUTES,
  });
};

export const finishOrderDispatch = (order, dispatchType) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: DISPATCH_FINISH_REQUEST,
  });

  try {
    const response = await api.post("/dispatch/finish-order", {
      order,
      dispatchType,
    });

    dispatch({
      type: DISPATCH_FINISH_RESPONSE,
      payload: {
        data: response.data,
      },
    });
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: DISPATCH_FINISH_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err));
  }
};

export const loadOrder = (order, dispatchType) => async (
  dispatch,
  getState,
  api
) => {
  const distributionCenter = getState().distributionCenters.selected;
  const period = getState().periods.selected;
  try {
    dispatch({
      type: DISPATCH_LOAD_ROUTE_REQUEST,
      route: {
        order,
        dispatchType,
        distributionCenter,
      },
    });
    const response = await api.get("/dispatch/get-order", {
      params: { order, distributionCenter, dispatchType },
    });
    dispatch({
      type: DISPATCH_LOAD_ROUTE_RESPONSE,
      payload: {
        data: response.data,
      },
    });
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: DISPATCH_LOAD_ROUTE_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err));
  }
};

export const finishDispatch = (routeId, dispatchType) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: DISPATCH_FINISH_REQUEST,
    routeId,
  });

  try {
    const response = await api.post("/dispatch/finish", {
      routeId,
      dispatchType,
    });

    dispatch({
      type: DISPATCH_FINISH_RESPONSE,
      payload: {
        data: response.data,
      },
    });
    dispatch(
      sendNotification({
        type: "success",
        message: `Rota ${response.data.updatedRoute.number} conferida com sucesso`,
      })
    );
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: DISPATCH_FINISH_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err));
  }
};

export const resetFinishStatus = () => async (dispatch, getState, api) => {
  dispatch({
    type: DISPATCH_FINISH_RESET_STATUS,
  });
};
