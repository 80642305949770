const userIsPartner = sale => sale.logistic_kind === 'partner';

const userIsPJ = user => user.cpf.length > 14;

const saleAlreadyHasNFe = sale =>
	sale.nfe &&
	sale.nfe.type !== 'nfce' &&
	(sale.nfe.status === 'autorizado' || sale.nfe.status === 'processando_autorizacao');

const saleAlreadyHasNFCe = sale =>
	sale.nfe && sale.nfe.type === 'nfce' && sale.nfe.status === 'autorizado';

const distributionCenterHasNFCe = distributionCenter => distributionCenter.has_nfce;

export const shouldPrintNFe = sale =>
	!saleAlreadyHasNFCe(sale) &&
	(userIsPartner(sale) ||
		userIsPJ(sale.user) ||
		saleAlreadyHasNFe(sale) ||
		!distributionCenterHasNFCe(sale.distribution_center || sale.distributionCenter));
