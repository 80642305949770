import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';

const OrderInputCard = ({
	classes,
	handleOrderInput,
	handleOrderChange,
	order,
	orderInputError,
}) => {
	const size = window.innerWidth;
	return (
		<Grid container justify="center">
			<Grid item xs={size > 800 ? 4 : 12}>
				<Paper elevation="1" className={classes.orderInputPaper}>
					<Typography variant="h6" className={classes.title}>
						Insira o número do transbordo
					</Typography>
					<form
						onSubmit={() => {
							handleOrderInput(order);
						}}
					>
						<FormControl className="mb-3" fullWidth>
							<InputLabel htmlFor="order-id-input">Número da ordem</InputLabel>
							<Input id="order-id-input" onInput={handleOrderChange} value={order} />
							{orderInputError && (
								<FormHelperText className={classes.orderInputError}>
									Número da ordem deve conter apenas números
								</FormHelperText>
							)}
							<Button
								variant="raised"
								color="primary"
								type="submit"
								className={classNames('jr-btn', 'text-blue-gray', classes.button)}
							>
								Iniciar Conferência
							</Button>
						</FormControl>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
};

OrderInputCard.propTypes = {
	classes: PropTypes.object.isRequired,
	handleOrderInput: PropTypes.func.isRequired,
	handleOrderChange: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderInputError: PropTypes.bool.isRequired,
};

export default withStyles(styles)(OrderInputCard);
