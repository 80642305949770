import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { fetchAvailableRouteTypes, selectRouteType } from '../../actions/RouteTypes';

class RouteTypeSelect extends React.Component {
	componentDidMount() {
		if (this.props.availableRouteTypes.length === 0) {
			this.props.fetchAvailableRouteTypes();
		}
	}

	handleChange = e => {
		this.props.selectRouteType(e.target.value);
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	render() {
		const { availableRouteTypes, selectedRouteType } = this.props;

		return (
			<form className="row" autoComplete="off">
				<div className="col-lg-12 col-sm-12 col-12">
					<FormControl className="w-100 mb-2" fullWidth>
						<InputLabel htmlFor="route-select">Selecionar Tipo da Rota</InputLabel>
						<Select
							value={selectedRouteType || ''}
							onChange={this.handleChange}
							input={<Input id="route-types-select" />}
						>
							{availableRouteTypes.map(route => (
								<MenuItem value={route.id} key={route.id}>
									{route.pt}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</form>
		);
	}
}

function mapStateToProps(state) {
	return {
		availableRouteTypes: state.routeTypes.available,
		selectedRouteType: state.routeTypes.selected,
		loading: state.routeTypes.loading,
		error: state.routeTypes.error,
	};
}

RouteTypeSelect.propTypes = {
	availableRouteTypes: PropTypes.array,
	selectedRouteType: PropTypes.string,
	onChange: PropTypes.func,
	fetchAvailableRouteTypes: PropTypes.func.isRequired,
	selectRouteType: PropTypes.func.isRequired,
};

RouteTypeSelect.defaultProps = {
	availableRouteTypes: [],
	selectedRouteType: '',
	onChange: null,
};

export default connect(mapStateToProps, { fetchAvailableRouteTypes, selectRouteType })(
	RouteTypeSelect
);
