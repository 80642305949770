import { makeStyles } from '@material-ui/styles';

const styles = () => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '44%',
	},
	savingContainer: {
		paddingLeft: 16,
		paddingRight: 16,
		position: 'fixed',
		left: 0,
		bottom: '58px',
	},
	saving: {
		color: '#455a64',
		marginTop: '16px',
		marginBottom: '4px',
		fontSize: '12px',
	},
	savingProgress: {
		flexGrow: 1,
	},
});

export const useStyles = makeStyles(styles);
