import {
	GET_CLOSED_PALLETS_REQUEST,
	GET_CLOSED_PALLETS_ERROR,
	GET_CLOSED_PALLETS_RESPONSE,
	DELETE_PALLET_REQUEST,
	DELETE_PALLET_RESPONSE,
	DELETE_PALLET_ERROR,
} from 'constants/ActionTypes';

const initialState = {
	pallet: null,
	pallets: [],
	products: {},
	loadingPallets: false,
	loadingProducts: false,
	error: null,
	deletePalletLoading: false,
	palletClosed: false,
	alreadyClosed: false,
};

const ManagePallets = (state = initialState, action) => {
	switch (action.type) {
		case GET_CLOSED_PALLETS_REQUEST:
			return {
				...state,
				pallets: [],
				loadingPallets: true,
				error: null,
			};
		case GET_CLOSED_PALLETS_RESPONSE:
			return {
				...state,
				pallets: action.payload.data,
				loadingPallets: false,
				error: null,
			};
		case GET_CLOSED_PALLETS_ERROR:
			return {
				...state,
				loadingPallets: false,
				error: action.payload.error,
			};
		case DELETE_PALLET_REQUEST:
			return {
				...state,
				deletePalletLoading: true,
				error: null,
			};
		case DELETE_PALLET_RESPONSE:
			return {
				...state,
				deletePalletLoading: false,
			};
		case DELETE_PALLET_ERROR:
			return {
				...state,
				error: action.payload.error,
				deletePalletLoading: false,
			};
		default:
			return state;
	}
};

export default ManagePallets;
