import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import DCSelect from "containers/DCSelect";
import { Card, CardBody } from "reactstrap";
import Button from "@material-ui/core/Button";
import PeriodSelect from "containers/PeriodSelect";
import { withStyles } from "@material-ui/core/styles";
import { DISPATCH_SEARCH_TYPES, DISPATCH_TYPES } from "constants/Dispatch";
import { styles } from "../constants/styles";
import RouteInput from "../components/RouteInput";
import OrderRouteSelector from "./OrderRouteSelector.js";
import LastRoutesCard from "./LastRoutesCard.js";
import DateSelect from "../../../components/DateSelect";

class RouteForm extends React.Component {
  handleRouteInput = (route) => {
    this.props.onRouteInput(route);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onClick();
  };
  getPlaceholder = () => {
    switch (this.props.type) {
      case DISPATCH_SEARCH_TYPES.ROUTE:
        return "Buscar Rota";
      case DISPATCH_SEARCH_TYPES.ORDER:
        return "Buscar Pedido";
      default:
        return "Buscar";
    }
  };

  handleDateChange = (e) => {
    if (this.props.onDateChange && e) {
      this.props.onDateChange(e);
    }
  }

  render() {
    const { classes } = this.props;
    const today = moment(this.props.date).format("DD/MM/YYYY");
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={6}>
              <Card className="shadow border-0">
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <DateSelect selectedDate={this.props.date} onSelect={this.handleDateChange} />
                      </Grid>
                      <Grid item xs={12}>
                        <DCSelect />
                      </Grid>
                      {this.props.type === DISPATCH_SEARCH_TYPES.ROUTE && (
                        <Grid item xs={12}>
                          <PeriodSelect />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <OrderRouteSelector
                          name="Tipo de entrega:"
                          onChange={this.props.onDispatchTypeChange}
                          selected={this.props.dispatchType}
                          options={Object.values(DISPATCH_TYPES)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <OrderRouteSelector
                          name="Rota ou pedido?"
                          onChange={this.props.onTypeChange}
                          selected={this.props.type}
                          options={Object.values(DISPATCH_SEARCH_TYPES)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RouteInput
                          selected={this.props.type}
                          onRouteInput={this.handleRouteInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="raised"
                          color="primary"
                          type="submit"
                          className="jr-btn text-blue-gray"
                        >
                          {this.getPlaceholder()}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <LastRoutesCard
                          lastRoutesNumbers={this.props.lastRoutesNumbers}
                          deleteRecentRoutes={this.props.deleteRecentRoutes}
                          searchType={this.props.type}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RouteForm);
