import { formatDateAsString } from 'utils/dateUtils';

import {
	UPLOAD_ROUTES_REQUEST,
	UPLOAD_ROUTES_RESPONSE,
	UPLOAD_ROUTES_ERROR,
	DELIVERY_ROUTES_PERIODS_REQUEST,
	DELIVERY_ROUTES_PERIODS_RESPONSE,
	DELIVERY_ROUTES_PERIODS_ERROR,
	GET_ROUTES_REQUEST,
	GET_ROUTES_RESPONSE,
	GET_ROUTES_ERROR,
	UPDATE_ROUTE,
	DELETE_ROUTE_REQUEST,
	DELETE_ROUTE_RESPONSE,
	DELETE_ROUTE_ERROR,
	CALL_LOGGI_REQUEST,
	CALL_LOGGI_RESPONSE,
	CALL_LOGGI_ERROR,
	SIMULATE_ROUTE_REQUEST,
	SIMULATE_ROUTE_RESPONSE,
	SIMULATE_ROUTE_ERROR,
	CHECK_UPDATE_ROUTE,
	RESET_REPEATED_ROUTES,
	CHANGE_ROUTE_TYPE_REQUEST,
	CHANGE_ROUTE_TYPE_RESPONSE,
	CHANGE_ROUTE_TYPE_ERROR,
	TOGGLE_EXTRA_RETURN_REQUEST,
	TOGGLE_EXTRA_RETURN_RESPONSE,
	TOGGLE_EXTRA_RETURN_ERROR,
} from '../constants/ActionTypes';

import { handleRequestError } from './ErrorHandling';

export const getAvailablePeriods = () => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: DELIVERY_ROUTES_PERIODS_REQUEST,
		});
		const response = await api.get('/delivery/periods');

		dispatch({
			type: DELIVERY_ROUTES_PERIODS_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const error = err.response ? err.response.data.message : err.message;
		dispatch({
			type: DELIVERY_ROUTES_PERIODS_ERROR,
			payload: {
				error,
			},
		});
		dispatch(handleRequestError(error));
	}
};

export const getRoutes = (date = formatDateAsString(new Date())) => async (
	dispatch,
	getState,
	api
) => {
	try {
		dispatch({
			type: GET_ROUTES_REQUEST,
		});
		const centerId = getState().distributionCenters.selected;
		const period = getState().periods.selected;
		const routeType = getState().routeTypes.selected;
		const response = await api.get('/delivery/routes', {
			params: { date, period, centerId, routeType },
		});
		dispatch({
			type: GET_ROUTES_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: GET_ROUTES_ERROR,
			payload: {
				error: errorMessage,
			},
		});
	}
};

export const changePackagesOrder = ({ deliveryRouteId, packagesOrder }) => async (
	dispatch,
	getState,
	api
) => {
	try {
		const response = await api.post('/delivery/change-packages-order', {
			deliveryRouteId,
			packagesOrder,
		});
		if (response.data && response.data.success) {
			dispatch({
				type: UPDATE_ROUTE,
				payload: {
					updatedRoute: response.data.updatedRoute,
				},
			});
		}
	} catch (error) {
		dispatch(handleRequestError(error));
	}
};

export const deletePackage = ({ deliveryRouteId, order }) => async (dispatch, getState, api) => {
	try {
		const response = await api.post('/delivery/delete-package', {
			deliveryRouteId,
			order,
		});
		if (response.data && response.data.success) {
			dispatch({
				type: UPDATE_ROUTE,
				payload: {
					updatedRoute: response.data.updatedRoute,
				},
			});
		}
	} catch (error) {
		dispatch(handleRequestError(error));
	}
};

export const addPackage = ({ deliveryRouteId, order, callback, force }) => async (
	dispatch,
	getState,
	api
) => {
	try {
		const response = await api.post('/delivery/add-package', {
			deliveryRouteId,
			order,
			force,
		});
		if (response.data) {
			if (response.data.success) {
				dispatch({
					type: UPDATE_ROUTE,
					payload: {
						updatedRoute: response.data.route,
					},
				});
			} else {
				dispatch({
					type: CHECK_UPDATE_ROUTE,
					payload: {
						order: response.data.order,
						repeatedRoutes: response.data.route,
					},
				});
			}
		}
		if (callback) {
			callback();
		}
	} catch (error) {
		dispatch(handleRequestError(error));
	}
};

export const resetRepeatedRoutes = () => async (dispatch, getState, api) => {
	dispatch({ type: RESET_REPEATED_ROUTES });
};

export const toggleReturnPoint = deliveryRouteId => async (dispatch, getState, api) => {
	dispatch({
		type: TOGGLE_EXTRA_RETURN_REQUEST,
		deliveryRouteId,
	});
	try {
		const response = await api.post('/delivery/toggle-return-point', {
			deliveryRouteId,
		});
		if (response.data && response.data.success) {
			dispatch({
				type: TOGGLE_EXTRA_RETURN_RESPONSE,
			});
			dispatch({
				type: UPDATE_ROUTE,
				payload: {
					updatedRoute: response.data.updatedRoute,
				},
			});
		}
	} catch (error) {
		dispatch({
			type: TOGGLE_EXTRA_RETURN_ERROR,
		});
		dispatch(handleRequestError(error));
	}
};

export const uploadRoutes = (csvData, selectedDate) => async (dispatch, getState, api) => {
	const centerId = getState().distributionCenters.selected;
	const periodId = getState().periods.selected;
	try {
		dispatch({
			type: UPLOAD_ROUTES_REQUEST,
			csvData,
			date: selectedDate,
			centerId,
			periodId,
		});
		const response = await api.post('/delivery/upload-routes', {
			csvData,
			date: selectedDate,
			centerId,
			periodId,
		});
		if (response.data && response.data.success) {
			dispatch({
				type: UPLOAD_ROUTES_RESPONSE,
				payload: {
					data: response.data,
				},
			});
		}
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: UPLOAD_ROUTES_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const callLoggi = route => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: CALL_LOGGI_REQUEST,
			route,
		});
		const response = await api.post('/delivery/call-loggi', { route });
		dispatch({
			type: CALL_LOGGI_RESPONSE,
		});
		dispatch({
			type: UPDATE_ROUTE,
			payload: {
				updatedRoute: response.data.updatedRoute,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: CALL_LOGGI_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const deleteRoute = route => async (dispatch, getState, api) => {
	dispatch({
		type: DELETE_ROUTE_REQUEST,
		route,
	});
	try {
		const response = await api.post('/delivery/delete-route', {
			route,
		});
		if (response.data && response.data.success) {
			dispatch({
				type: DELETE_ROUTE_RESPONSE,
				payload: {
					data: response.data,
				},
			});
		}
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: DELETE_ROUTE_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const simulateRoute = (route, optimize) => async (dispatch, getState, api) => {
	dispatch({
		type: SIMULATE_ROUTE_REQUEST,
		route,
	});
	try {
		const response = await api.post('/delivery/simulate-route', {
			route,
			optimize,
		});
		if (response.data && response.data.success) {
			dispatch({
				type: SIMULATE_ROUTE_RESPONSE,
			});
			dispatch({
				type: UPDATE_ROUTE,
				payload: {
					updatedRoute: response.data.updatedRoute,
				},
			});
		} else {
			throw response.data.jsonError;
		}
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: SIMULATE_ROUTE_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const changeRouteType = (routeId, newRouteType) => async (dispatch, getState, api) => {
	dispatch({
		type: CHANGE_ROUTE_TYPE_REQUEST,
		routeId,
		newRouteType,
	});
	try {
		const response = await api.post('/delivery/change-route-type', {
			routeId,
			type: newRouteType,
		});
		dispatch({
			type: CHANGE_ROUTE_TYPE_RESPONSE,
		});
		dispatch(getRoutes());
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: CHANGE_ROUTE_TYPE_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};
