import {
	GET_PALLETS_REQUEST,
	GET_PALLETS_RESPONSE,
	GET_PALLETS_ERROR,
	PRODUCTS_INFO_REQUEST,
	PRODUCTS_INFO_RESPONSE,
	PRODUCTS_INFO_ERROR,
	ADD_BOX_TO_PALLET_REQUEST,
	ADD_BOX_TO_PALLET_RESPONSE,
	ADD_BOX_TO_PALLET_ERROR,
	RESET_INVENTORY_INPUT_STORE,
} from 'constants/ActionTypes';

const initialState = {
	pallets: [],
	loadingPallets: false,
	loadingProducts: false,
	addingBoxToPallet: false,
	boxAdded: false,
	error: null,
	products: {},
};

const InventoryInput = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_INFO_REQUEST:
			return {
				...state,
				loadingProducts: true,
				error: null,
			};
		case PRODUCTS_INFO_RESPONSE:
			return {
				...state,
				loadingProducts: false,
				products: action.payload,
				error: null,
			};
		case PRODUCTS_INFO_ERROR:
			return {
				...state,
				loadingProducts: false,
				error: action.payload.error,
			};
		case GET_PALLETS_REQUEST:
			return {
				...state,
				pallets: [],
				loadingPallets: true,
				error: null,
			};
		case GET_PALLETS_RESPONSE:
			return {
				...state,
				pallets: action.payload.data,
				loadingPallets: false,
				error: null,
			};
		case GET_PALLETS_ERROR:
			return {
				...state,
				loadingPallets: false,
				error: action.payload.error,
			};
		case ADD_BOX_TO_PALLET_REQUEST:
			return {
				...state,
				addingBoxToPallet: true,
				boxAdded: false,
				error: null,
			};
		case ADD_BOX_TO_PALLET_RESPONSE:
			return {
				...state,
				pallets: action.payload.data,
				boxAdded: true,
				addingBoxToPallet: false,
				error: null,
			};
		case ADD_BOX_TO_PALLET_ERROR:
			return {
				...state,
				boxAdded: false,
				addingBoxToPallet: false,
				error: action.payload.error,
			};
		case RESET_INVENTORY_INPUT_STORE:
			return {
				...state,
				boxAdded: false,
				addingBoxToPallet: false,
				error: null,
			};
		default:
			return state;
	}
};

export default InventoryInput;
