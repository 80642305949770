import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid } from '@material-ui/core';
import { useStyles } from './styles';

export const FooterButton = ({ text, onClick }) => {
	const classes = useStyles();
	return (
		<Grid container className={classes.buttonContainer} justify="center" alignItems="center">
			<Button className={classes.button} onClick={onClick}>
				<Typography className={classes.buttonText}>{text}</Typography>
			</Button>
		</Grid>
	);
};

FooterButton.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};
