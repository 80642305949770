import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import ProductsLossContainer from './containers/ProductsLossContainer';

class ProductsLoss extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.productsLoss" />}
				/>
				<ProductsLossContainer />
			</div>
		);
	}
}

export default ProductsLoss;
