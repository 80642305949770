import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'utils/IntlMessages';
import ContainerHeader from '../../components/ContainerHeader/index';
import RoutingAPIFiorinoFormContainer from './containers/RoutingAPIFiorinoFormContainer';

class RoutingFiorino extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.routing.fiorino" />}
				/>
				<Grid container justify="center">
					<Grid item xs={6}>
						<RoutingAPIFiorinoFormContainer />
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default RoutingFiorino;
