export const openFileInNewTab = (content, fileType) => {
	const blob = new Blob([content], { type: fileType });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.target = '_blank';
	a.click();
};

export const downloadFile = (content, fileType, filename) => {
	let fileContent = [content];
	if (fileType.includes('csv')) {
		fileContent = ['\ufeff', content];
	}
	const blob = new Blob(fileContent, { type: fileType });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.type = fileType;
	a.download = filename;
	a.click();
};
