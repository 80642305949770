import {
	DISPATCH_AVAILABLE_PERIODS_REQUEST,
	DISPATCH_AVAILABLE_PERIODS_RESPONSE,
	DISPATCH_AVAILABLE_PERIODS_ERROR,
	DISPATCH_FINISH_REQUEST,
	DISPATCH_FINISH_RESPONSE,
	DISPATCH_FINISH_ERROR,
	DISPATCH_FINISH_RESET_STATUS,
	DISPATCH_LOAD_ROUTE_REQUEST,
	DISPATCH_LOAD_ROUTE_ERROR,
	DISPATCH_LOAD_ROUTE_RESPONSE,
	DISPATCH_CLEAR_LAST_ROUTES,
	DISPATCH_PRINT_NFCE_REQUEST,
	DISPATCH_PRINT_NFCE_RESPONSE,
	DISPATCH_PRINT_NFCE_ERROR,
} from '../constants/ActionTypes';

const initialState = {
	finish: {
		loading: false,
		success: false,
		error: null,
	},
	periods: {
		data: [],
		loading: false,
		error: null,
	},
	data: {
		routeId: null,
		number: '',
		date: '',
		period: {
			name: '',
			start_hour: '',
		},
		orders: [],
		done: false,
		loading: false,
		success: false,
		error: false,
	},
	nfcePrinting: {
		loading: false,
	},
	lastRoutesNumbers: [],
};

const dispatch = (state = initialState, action) => {
	switch (action.type) {
		case DISPATCH_FINISH_REQUEST:
			return {
				...state,
				finish: {
					loading: true,
					success: false,
				},
			};
		case DISPATCH_FINISH_RESPONSE:
			return {
				...state,
				lastRoutesNumbers: [
					action.payload.data.updatedRoute.number,
					...state.lastRoutesNumbers,
				],
				finish: {
					loading: false,
					success: true,
				},
			};
		case DISPATCH_FINISH_ERROR:
			return {
				...state,
				finish: {
					loading: false,
					success: false,
					error: action.payload.error,
				},
			};
		case DISPATCH_FINISH_RESET_STATUS:
			return {
				...state,
				finish: {
					loading: false,
					success: false,
					error: null,
				},
				data: {
					routeId: null,
				},
			};
		case DISPATCH_LOAD_ROUTE_REQUEST:
			return {
				...state,
				data: {
					loading: true,
					success: false,
				},
			};
		case DISPATCH_LOAD_ROUTE_RESPONSE:
			return {
				...state,
				finish: {
					loading: false,
					success: false,
					error: null,
				},
				data: {
					...action.payload.data,
					loading: false,
					success: true,
				},
			};
		case DISPATCH_LOAD_ROUTE_ERROR:
			return {
				...state,
				data: {
					error: action.payload.error,
				},
			};
		case DISPATCH_AVAILABLE_PERIODS_REQUEST:
			return {
				...state,
				periods: {
					...state.periods,
					loading: true,
					error: null,
				},
			};
		case DISPATCH_AVAILABLE_PERIODS_RESPONSE:
			return {
				...state,
				periods: {
					data: action.payload.data,
					loading: false,
				},
			};
		case DISPATCH_AVAILABLE_PERIODS_ERROR:
			return {
				...state,
				periods: {
					loading: false,
					error: action.payload.error,
				},
			};
		case DISPATCH_CLEAR_LAST_ROUTES:
			return {
				...state,
				lastRoutesNumbers: [],
			};
		case DISPATCH_PRINT_NFCE_REQUEST:
			return {
				...state,
				nfcePrinting: {
					loading: true,
				},
			};
		case DISPATCH_PRINT_NFCE_RESPONSE:
			return {
				...state,
				nfcePrinting: {
					loading: false,
				},
			};
		case DISPATCH_PRINT_NFCE_ERROR:
			return {
				...state,
				nfcePrinting: {
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default dispatch;
