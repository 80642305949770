import { makeStyles } from '@material-ui/styles';

const styles = theme => ({
	button: {
		borderRadius: 0,
		boxShadow:
			'0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
		width: `100%`,
		height: `100%`,
		backgroundColor: '#455a64',
		textAlign: 'center',
		'&:hover': {
			backgroundColor: '#455a64',
		},
	},
	buttonText: {
		color: theme.palette.common.white,
		textTransform: 'none',
		fontSize: '16px',
	},
	buttonContainer: {
		width: '100%',
		height: `45px`,
		zIndex: 1000,
		position: 'fixed',
		bottom: 0,
		left: 0,
		backgroundColor: theme.palette.common.white,
	},
});

export const useStyles = makeStyles(styles);
