import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		color: '#455A64',
		fontSize: '18px',
		fontFamily: 'Roboto',
		fontWeight: 'bold',
	},
});

const SubTitle = ({ children, classes, style }) => (
	<Typography className={classes.root} style={style}>
		{children}
	</Typography>
);

export default withStyles(styles)(SubTitle);
