import {
	ROUTE_TYPES_FETCH_REQUEST,
	ROUTE_TYPES_FETCH_RESPONSE,
	ROUTE_TYPES_FETCH_ERROR,
	ROUTE_TYPE_SELECT,
} from '../constants/ActionTypes';

import { handleRequestError } from './ErrorHandling';

export const fetchAvailableRouteTypes = () => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: ROUTE_TYPES_FETCH_REQUEST,
		});
		const response = await api.get('/delivery/route-types');
		dispatch({
			type: ROUTE_TYPES_FETCH_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: ROUTE_TYPES_FETCH_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err, false));
	}
};

export const selectRouteType = typeId => async dispatch => {
	dispatch({
		type: ROUTE_TYPE_SELECT,
		payload: {
			data: typeId,
		},
	});
	localStorage.setItem('period', typeId);
};
