import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';

const ProductComponent = ({
	index,
	divider,
	classes,
	name,
	imageUrl,
	quantity,
	color,
	key,
	confirmed,
}) => {
	const CellText = ({ children }) => <h1 className={classes.productText}>{children}</h1>;

	CellText.propTypes = {
		children: PropTypes.any.isRequired,
	};
	const mobile = window.innerWidth < 800;

	return (
		<ListItem
			className={`${
				!confirmed
					? classes.pending
					: index % 2 === 0
						? classes.primaryColor
						: classes.secondaryColor
			} ${classes.productItemContainer}`}
			disableGutters
			divider={divider}
			key={key}
		>
			<Grid
				container
				direction="row"
				alignItems="center"
				key={quantity}
				spacing={8}
				wrap="nowrap"
				className={classes.productGridContainer}
			>
				{!mobile && (
					<Grid item className={classes.productIconContainer}>
						<img src={imageUrl || ''} alt="" className={classes.productIcon} />
					</Grid>
				)}
				<Grid item>
					<Chip label={<CellText>{quantity}</CellText>} tabindex="0" />
				</Grid>
				<Grid item>
					<CellText>x</CellText>
				</Grid>
				<Grid item xs className={classes.productNameContainer}>
					<Grid container alignItems="center" className={classes.productNameContainer}>
						<Grid item>
							<CellText>
								<span className={classes[color]}>{name}</span>
							</CellText>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ListItem>
	);
};

ProductComponent.propTypes = {
	name: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired,
	quantity: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	key: PropTypes.any.isRequired,
	index: PropTypes.number,
	divider: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	confirmed: PropTypes.bool.isRequired,
};

ProductComponent.defaultProps = {
	index: 0,
	divider: false,
};

export default withStyles(styles)(ProductComponent);
