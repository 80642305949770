import { PACKAGE_BARCODE_REGEX } from 'constants/Regexes';
import { checkUsingRegex } from '../../../components/BarCodeInput';

export const checkPackageBarcode = str => checkUsingRegex(str, PACKAGE_BARCODE_REGEX);

function getSkuFromPackageBarcode(barcode) {
	return barcode.slice(2, 6).toUpperCase();
}

function getProductBatchFromBoxCode(boxCode) {
	return `${boxCode.slice(9, 13)}-${boxCode.slice(13, 15)}-${boxCode.slice(15, 17)}`;
}

function getQuantityFromBarcode(boxCode) {
	return boxCode.slice(6, 9);
}

export function isBoxInPallet(pallet, packageBarcode) {
	const sku = getSkuFromPackageBarcode(packageBarcode);
	const batch = getProductBatchFromBoxCode(packageBarcode);
	return pallet.items.some(item => item.sku === sku && item.batch === batch);
}

export function getBoxInfoFromBarcode(barcode, products) {
	const sku = getSkuFromPackageBarcode(barcode);
	const quantity = getQuantityFromBarcode(barcode);
	const batch = getProductBatchFromBoxCode(barcode);
	const { name, productsPerBox, expiration } = products[sku];
	return {
		sku,
		name,
		quantity,
		productsPerBox,
		batch,
		expiration,
		code: barcode,
	};
}
