export const STRING_SCAN_POSITION = "Escaneie a Posição";
export const STRING_SCAN_ORDER_OR_POSITION =
  "Escaneie um pedido para adicioná-lo ou uma posição para confirmar";
export const STRING_SCAN_BOX =
  "Por Favor, faça a leitura da caixa com o pedido.";
export const PERIOD_NAMES = Object.freeze({
  MORNING: "manhã",
  AFTERNOON: "tarde",
  NIGHT: "noite",
});

export const PRODUCT_LOSS_REASONS = {
  QUALITY: 'Qualidade',
  PACKAGE: 'Embalagem',
  DEVOLUTION: 'Retorno de Pedidos',
  CD_OPERATIONS: 'Operação CD',
  EXPIRATION_DATE: 'Validade',
  PORTFOLIO: 'Portfólio',
  OTHER: 'Outros',
}

export const PRODUCT_LOSS_SUBREASONS = {
  [PRODUCT_LOSS_REASONS.QUALITY]: [
    'Contaminação física',
    'Contaminação cruzada',
    'Desvio sensorial',
    'Desvio microbiológico',
    'Desvio de peso',
  ],
  [PRODUCT_LOSS_REASONS.PACKAGE]: [
    'Embalagem rasgada',
    'Falta de vácuo',
    'Embalagem aberta',
    'Cinta rasgada',
    'Item amassado',
    'Etiqueta errada',
    'Embalagem com migração'
  ],
  [PRODUCT_LOSS_REASONS.DEVOLUTION]: ['Fora de temperatura', 'Embalagem danificada'],
  [PRODUCT_LOSS_REASONS.CD_OPERATIONS]: ['Fora de temperatura'],
  [PRODUCT_LOSS_REASONS.EXPIRATION_DATE]: ['Próximo à validade'],
  [PRODUCT_LOSS_REASONS.PORTFOLIO]: ['Retirada de portfólio'],
  [PRODUCT_LOSS_REASONS.OTHER]: ['Outros'],
}
