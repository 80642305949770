import qs from 'querystring';
import {
  FISCAL_PRINTING_REQUEST,
  FISCAL_PRINTING_RESPONSE,
  FISCAL_PRINTING_ERROR,
  FISCAL_PRINTING_INVOICE_REQUEST,
  FISCAL_PRINTING_INVOICE_RESPONSE,
  FISCAL_PRINTING_INVOICE_ERROR,
} from '../constants/ActionTypes';
import { handleRequestError } from './ErrorHandling';
import { sendNotification } from './Notifications';

export const getSaleInvoice = order => async (dispatch, _getState, api) => {
  try {
    dispatch({
      type: FISCAL_PRINTING_REQUEST,
    });

    const authToken = localStorage.getItem('authToken');

    const response = await api.get(
      `/fiscal/get-sale-invoice?${qs.stringify({ order })}`,
      {
        headers: {
          Authorization: authToken ? `Bearer ${authToken}` : '',
          'content-type': 'application/json',
        },
      }
    );

    dispatch({
      type: FISCAL_PRINTING_RESPONSE,
      payload: {
        data: [response.data.saleInvoice],
      },
    });
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: FISCAL_PRINTING_ERROR,
      payload: {
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err));
  }
};

export const requestSaleInvoice = order => async (dispatch, _getState, api) => {
  try {
    dispatch({
      type: FISCAL_PRINTING_INVOICE_REQUEST,
      payload: {
        data: {
          order,
        },
      },
    });

    dispatch(
      sendNotification({
        type: 'info',
        message:
          'A emissão da nota está em andamento! Caso a nota ainda não esteja disponível busque novamente em alguns minutos',
        autoDismiss: true,
        soundOff: true,
      })
    );

    const authToken = localStorage.getItem('authToken');

    const response = await api.post(
      '/fiscal/request-sale-invoice',
      { order },
      {
        headers: {
          Authorization: authToken ? `Bearer ${authToken}` : '',
          'Content-Type': 'application/json',
        },
      }
    );

    dispatch({
      type: FISCAL_PRINTING_INVOICE_RESPONSE,
      payload: {
        data: {
          ...response.data.saleInvoice,
        },
      },
    });
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message;
    dispatch({
      type: FISCAL_PRINTING_INVOICE_ERROR,
      payload: {
        data: {
          order,
        },
        error: errorMessage,
      },
    });
    dispatch(handleRequestError(err));
  }
};
