import {
	SWITCH_LANGUAGE,
	TOGGLE_COLLAPSED_NAV,
	TOGGLE_DISABLED_NAV,
	TOGGLE_FULL_SCREEN,
	WINDOW_WIDTH,
	TOGGLE_DRAWER_TYPE,
	COLLAPSED_DRAWER,
	FIXED_DRAWER,
	SHOW_FOOTER,
} from '../constants/ActionTypes';

export function toggleCollapsedNav(isNavCollapsed) {
	return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function toggleDisabledNav(isNavDisabled) {
	return { type: TOGGLE_DISABLED_NAV, isNavDisabled };
}

export function toggleFullScreen(isFullScreen) {
	return { type: TOGGLE_FULL_SCREEN, isFullScreen };
}

export function toggleDrawerType(drawerType) {
	return { type: TOGGLE_DRAWER_TYPE, drawerType };
}

export function hideDrawer() {
	return { type: TOGGLE_DRAWER_TYPE, drawerType: COLLAPSED_DRAWER };
}

export function showDrawer() {
	return { type: TOGGLE_DRAWER_TYPE, drawerType: FIXED_DRAWER };
}

export function updateWindowWidth(width) {
	return { type: WINDOW_WIDTH, width };
}

export function switchLanguage(locale) {
	return {
		type: SWITCH_LANGUAGE,
		payload: locale,
	};
}

export function showFooter(footerVisibility) {
	return {
		type: SHOW_FOOTER,
		payload: footerVisibility,
	};
}
