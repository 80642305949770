import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import BoxInfoCard from './BoxInfoCard';

const styles = theme => ({
	instruction: {
		marginTop: '16px',
		marginBottom: '4px',
		fontSize: '12px',
	},
	errorInstructionColor: {
		color: '#e22f2f',
	},
	regularInstructionColor: {
		color: '#455a64',
	},
	savingContainer: {
		paddingLeft: 16,
		paddingRight: 16,
		position: 'fixed',
		left: 0,
		bottom: '58px',
	},
	saving: {
		color: '#455a64',
		marginTop: '16px',
		marginBottom: '4px',
		fontSize: '12px',
	},
	savingProgress: {
		flexGrow: 1,
	},
});

const STEP_ONE_INSTRUCTION = 'Passe o leitor na etiqueta da caixa.';

const STEP_TWO_INSTRUCTION = 'Informações da caixa adicionada.';

const STEP_TRHEE_INSTRUCTION = 'Passe o leitor novamente na etiqueta para confirmar.';

const ERROR = 'Erro ao escanear a caixa';

const BoxInfoCards = ({ box, classes, addingBoxToPallet, error }) => (
	<Fragment>
		<Typography
			className={classNames(
				classes.instruction,
				error ? classes.errorInstructionColor : classes.regularInstructionColor
			)}
		>
			{!error ? (!box ? STEP_ONE_INSTRUCTION : STEP_TWO_INSTRUCTION) : ERROR}
		</Typography>
		<BoxInfoCard box={!error && box} error={error} border={!box && 1} />
		{box &&
			!error && (
				<Fragment>
					<Typography
						className={classNames(classes.instruction, classes.regularInstructionColor)}
					>
						{STEP_TRHEE_INSTRUCTION}
					</Typography>
					{!addingBoxToPallet ? (
						<BoxInfoCard border={1} />
					) : (
						<Grid container justify="center" direction="column">
							<BoxInfoCard box={box} border={1} />
							<Grid
								container
								className={classes.savingContainer}
								justify="center"
								direction="column"
							>
								<Typography className={classes.saving}>
									Salvando operação...
								</Typography>
								<LinearProgress className={classes.savingProgress} />
							</Grid>
						</Grid>
					)}
				</Fragment>
			)}
	</Fragment>
);

BoxInfoCards.propTypes = {
	box: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
	addingBoxToPallet: PropTypes.bool.isRequired,
};

export default withStyles(styles)(BoxInfoCards);
