import {
	PRODUCTS_INFO_REQUEST,
	PRODUCTS_INFO_RESPONSE,
	PRODUCTS_INFO_ERROR,
	PRODUCTS_LOSS_FINISH_REQUEST,
	PRODUCTS_LOSS_FINISH_RESPONSE,
	PRODUCTS_LOSS_FINISH_ERROR,
	PRODUCTS_INFO_ERROR_CLEAR,
	PRODUCTS_LOSS_RESET,
} from 'constants/ActionTypes';

const initialState = {
	products: {},
	loading: false,
	error: null,
	success: false,
};

const productsLoss = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_LOSS_RESET:
			return {
				...state,
				loading: false,
				error: null,
				success: false,
			};
		case PRODUCTS_INFO_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case PRODUCTS_INFO_RESPONSE: {
			const { rejectedBatches, ...products } = action.payload;
			return {
				...state,
				products,
				rejectedBatches,
				loading: false,
				error: null,
			};
		}
		case PRODUCTS_INFO_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.data,
			};
		case PRODUCTS_LOSS_FINISH_REQUEST:
			return {
				...state,
				loading: true,
				success: false,
				error: null,
			};
		case PRODUCTS_LOSS_FINISH_RESPONSE:
			return {
				...state,
				loading: false,
				success: true,
				error: null,
			};
		case PRODUCTS_LOSS_FINISH_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				error: action.payload.error,
			};
		case PRODUCTS_INFO_ERROR_CLEAR:
			return {
				...state,
				success: false,
				loading: false,
				error: null,
			};
		default:
			return state;
	}
};

export default productsLoss;
