import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexDirection: "row",
  },
});
const SinglePackageAs20Radio = ({ children, classes, onTypeChange, type }) => {
  const handleTypeChange = (event) => {
    onTypeChange(event.target.value === "true");
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup
        className={classes.root}
        aria-label="type"
        name="codeType"
        value={type}
        onChange={handleTypeChange}
      >
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="Caixa/Sacos"
        />
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="Quantidade por saco"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles(styles)(SinglePackageAs20Radio);
