import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { PropTypes } from 'prop-types';
import { fetchDistributionCenters, selectDistributionCenter } from 'actions/DistributionCenters';
import { connect } from 'react-redux';
import { sendNotification, errorNotification } from 'actions/Notifications';
import { fetchDcOrders, confirmDcOrderArrival } from 'actions/TransferCheck';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import DcBox from '../components/DcBox';
import LoadingDcs from '../components/LoadingDcs';
import LoadingOrders from '../components/LoadingOrders';
import FooterBackButton from '../components/FooterBackButton';
import OrderCard from '../components/OrderCard';
import { Typography } from '@material-ui/core';

const OrderArrivalContainer = props => {
	const [step, setStep] = useState(0);
	const [dc, setDc] = useState();
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState({});

	useEffect(() => {
		props.fetchDistributionCenters();
		selectDistributionCenter && props.fetchDcOrders();
	}, []);

	useEffect(() => {
		if (
			props.distributionCenters &&
			props.distributionCenters.data &&
			props.distributionCenters.data.length > 0 &&
			localStorage.getItem('distributionCenter')
		) {
			const distributionCenter = props.distributionCenters.data.find(
				d => d._id === localStorage.getItem('distributionCenter')
			);

			setStep(1);
			setDc(distributionCenter);
		}
	}, [props.distributionCenters, props.orders]);

	const handleDcClick = name => {
		const distributionCenter = props.distributionCenters.data.find(d => d.name === name);
		setStep(1);
		props.selectDistributionCenter(
			distributionCenter._id,
			distributionCenter.address,
			distributionCenter.kind
		);
		setDc(distributionCenter);
		props.fetchDcOrders();
	};

	const renderFooterButton = () => <FooterBackButton onBack={handleBack} />;

	const handleBack = () => {
		setStep(0);
		setDc();
	};

	const handleOrderClick = (number, arrival) => {
		setConfirmModalOpen(true);
		setSelectedOrder({ number, arrival });
	};

	const handleConfirmModalClose = () => {
		setConfirmModalOpen(false);
		setSelectedOrder({});
	}

	const handleConfirmOrder = () => {
		props.confirmDcOrderArrival(selectedOrder.number);
		setConfirmModalOpen(false);
	}

	const renderConfirmModal = () => (
		<Dialog open={confirmModalOpen} onClose={handleConfirmModalClose}>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Confirma a chegada da Ordem <b>{selectedOrder.number}</b>,
					<br />
					prevista para <b>{selectedOrder.arrival}</b>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleConfirmOrder} color="primary">
					Sim
				</Button>
				<Button onClick={handleConfirmModalClose} color="primary" autoFocus>
					Não
				</Button>
			</DialogActions>
		</Dialog>
	);

	const renderDcs = () => (
		<Grid>
			{props.distributionCenters.loading ? (
				<LoadingDcs />
			) : (
					props.distributionCenters.data
						.filter(dc => dc.kind !== 'Warehouse')
						.map(dc => <DcBox name={dc.name} onClick={handleDcClick} />)
				)}
		</Grid>
	);

	const renderOrders = () => {
		if (props.ordersLoading) {
			return (<LoadingOrders />);
		}
		return mapOrders(props.orders);
	}

	const mapOrders = orders => (
		<React.Fragment>
			{orders.length ? (
				orders.map(order => (
					<OrderCard number={order.number} arrival={order.arrival} onClick={handleOrderClick} />
				))
			) : (
					<Typography>
						{`Não existem ordens agendadas para hoje neste CD (${dc.name})`}
					</Typography>
				)}
		</ React.Fragment>
	);

	const subTitleMessage = `${dc && dc.name}`;
	return (
		<Grid container direction="column">
			<Title />
			<SubTitle>{(dc && subTitleMessage) || 'Escolha o local onde deseja começar'}</SubTitle>
			{renderConfirmModal()}
			{step === 0 && renderDcs()}
			{step === 1 && renderOrders()}
			{step === 1 && renderFooterButton()}
		</Grid>
	);
};

const mapStateToProps = state => ({
	distributionCenters: state.distributionCenters,
	orders: state.transferCheck.orders,
	ordersLoading: state.transferCheck.ordersLoading,
});

OrderArrivalContainer.propTypes = {
	sendNotification: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	fetchDcOrders,
	confirmDcOrderArrival,
	selectDistributionCenter,
	fetchDistributionCenters,
	sendNotification,
	errorNotification,
})(OrderArrivalContainer);
