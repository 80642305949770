import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import OrdersListContainer from './containers/OrdersListContainer';

class OrdersList extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.ordersList" />}
				/>
				<OrdersListContainer />
			</div>
		);
	}
}

export default OrdersList;
