// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NLNIJxDlAtd7FsTH4EQw {
  padding-top: 16px;
}

.JOAmwp3y6_SxK7hunCkG {
  margin-left: 10px
}

.KMHlB5CxDZsfLbXDowud:hover {
  cursor: pointer;
}

.zTB0PYu_UWnnmDYT5V_J {
  max-height: 400px;
  overflow-y: auto;
  font-size: 0.8em !important;
}

.PIl6R019jQMbrnsAgOTQ {
  margin-right: 40px
}

.UHZHu3_c_F1kDelLvzqF {
  width: 100%;
}

.WQ1xyfaV2GFMKG_ubawq {
  height: 400px;
}

.zt0tz3M_ZwON1fKnToDQ {
  margin-bottom: 0;
}

.jTPJJ612RCI8_d0S1l7I {
  margin: 0px;
  overflow: auto;
  max-height: 630px;
}

.v6B6H_Z8vWV3oQ8uOVGA {
  font-size: 0.9em;
}

.Zi2KJmVqWmj4RPrCWz2E {
  background-color: #98f898;
}

.oH7g04maCH6jUOApobl5 {
  background-color: #98f898;
}

.v7PivVKVUGFJt8wR8UNO {
  background-color: darkviolet;
}

.DrD2t2P9AXe0RBQdfQh7 {
  background-color: #fff59d;
}

.T4Jqe6I9PJ8j_uMZyhwS {
  font-size: 18px;
  margin-right: 20px;
  vertical-align: text-bottom;
}

.bXgPY9UTAIzvmsQGBSz8 {
  background-color: #CFD8DC;
}

.qrQQEuJnHva3VTeLKVtb {
  margin-top: 15px;
}

.plDJETrhBpqxHsHj6NWA {
  margin-left: 2%;
  margin-right: 2%;
}
`, "",{"version":3,"sources":["webpack://./routes/DeliveryRoutes/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".buttonPadding {\n  padding-top: 16px;\n}\n\n.loggiLinkButton {\n  margin-left: 10px\n}\n\n.extraReturnCheckLabel:hover {\n  cursor: pointer;\n}\n\n.packagesList {\n  max-height: 400px;\n  overflow-y: auto;\n  font-size: 0.8em !important;\n}\n\n.addPackage {\n  margin-right: 40px\n}\n\n.routesForm {\n  width: 100%;\n}\n\n.routeMap {\n  height: 400px;\n}\n\n.packageDetails {\n  margin-bottom: 0;\n}\n\n.selectRouteMenu {\n  margin: 0px;\n  overflow: auto;\n  max-height: 630px;\n}\n\n.selectRouteMenuItem {\n  font-size: 0.9em;\n}\n\n.routeListCellActive {\n  background-color: #98f898;\n}\n\n.routeListCalledAndDispatched {\n  background-color: #98f898;\n}\n\n.routeListExternalyDispatched {\n  background-color: darkviolet;\n}\n\n.routeListCalled {\n  background-color: #fff59d;\n}\n\n.icon {\n  font-size: 18px;\n  margin-right: 20px;\n  vertical-align: text-bottom;\n}\n\n.highlightPackageInfo {\n  background-color: #CFD8DC;\n}\n\n.divider {\n  margin-top: 15px;\n}\n\n.checkDialogText {\n  margin-left: 2%;\n  margin-right: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonPadding": `NLNIJxDlAtd7FsTH4EQw`,
	"loggiLinkButton": `JOAmwp3y6_SxK7hunCkG`,
	"extraReturnCheckLabel": `KMHlB5CxDZsfLbXDowud`,
	"packagesList": `zTB0PYu_UWnnmDYT5V_J`,
	"addPackage": `PIl6R019jQMbrnsAgOTQ`,
	"routesForm": `UHZHu3_c_F1kDelLvzqF`,
	"routeMap": `WQ1xyfaV2GFMKG_ubawq`,
	"packageDetails": `zt0tz3M_ZwON1fKnToDQ`,
	"selectRouteMenu": `jTPJJ612RCI8_d0S1l7I`,
	"selectRouteMenuItem": `v6B6H_Z8vWV3oQ8uOVGA`,
	"routeListCellActive": `Zi2KJmVqWmj4RPrCWz2E`,
	"routeListCalledAndDispatched": `oH7g04maCH6jUOApobl5`,
	"routeListExternalyDispatched": `v7PivVKVUGFJt8wR8UNO`,
	"routeListCalled": `DrD2t2P9AXe0RBQdfQh7`,
	"icon": `T4Jqe6I9PJ8j_uMZyhwS`,
	"highlightPackageInfo": `bXgPY9UTAIzvmsQGBSz8`,
	"divider": `qrQQEuJnHva3VTeLKVtb`,
	"checkDialogText": `plDJETrhBpqxHsHj6NWA`
};
export default ___CSS_LOADER_EXPORT___;
