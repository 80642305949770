import React, { Component } from 'react';

const forceRefresh = () => {
	window.location.reload(true);
};

const ONE_HOUR = 60 * 60 * 1000;
const FIFTY_EIGHT_MINUTES = 3480000;

const checkAndReload = justCreated => {
	let currentTime = new Date();
	currentTime = currentTime.getTime();
	const midNight = new Date();
	midNight.setHours(0, 0, 0, 0);
	if (currentTime - midNight <= ONE_HOUR && !justCreated) forceRefresh();
	return setTimeout(() => {
		checkAndReload(false);
	}, FIFTY_EIGHT_MINUTES);
};

class RegularUpdateCenter extends Component {
	constructor(props) {
		super(props);

		if (!this.timeOutReference) this.timeOutReference = checkAndReload(true);
	}

	componentWillUnmount() {
		if (this.timeOutReference) clearTimeout(this.timeOutReference);
	}

	render() {
		return null;
	}
}

export default RegularUpdateCenter;
