import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	root: {
		height: `50px`,
		zIndex: 1000,
		position: 'fixed',
		bottom: 0,
		right: 0,
		backgroundColor: theme.palette.common.white,
	},
	buttonText: {
		color: theme.palette.common.white,
		textTransform: 'none',
		fontSize: '16px',
	},
	button: {
		'&:hover': {
			backgroundColor: '#455a64',
		},
		borderRadius: 0,
		boxShadow:
			'0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
		width: `49.5%`,
		height: `100%`,
		backgroundColor: '#455a64',
		textAlign: 'center',
	},
	disabled: {
		opacity: '0.4',
	},
	mobile: {
		width: '100%',
	},
	desktop: {
		width: '80%',
	},
});

const ButtonsFooter = ({ onBack, onFinish, classes, disabled, finishing }) => (
	<Grid
		className={classNames(
			classes.root,
			(window.innerWidth > 500 && classes.desktop) || classes.mobile
		)}
		container
		direction="row"
		justify="space-between"
	>
		{finishing ? (
			<Grid container justify="center">
				<CircularProgress />
			</Grid>
		) : (
			<React.Fragment>
				<Button onClick={onBack} className={classes.button}>
					<Typography className={classes.buttonText}>Voltar</Typography>
				</Button>
				<Button
					onClick={onFinish}
					className={classNames(classes.button, disabled && classes.disabled)}
					disabled={disabled}
				>
					<Typography className={classes.buttonText}>Finalizar</Typography>
				</Button>
			</React.Fragment>
		)}
	</Grid>
);

export default withStyles(styles)(ButtonsFooter);
