import { makeStyles } from '@material-ui/styles';

const styles = () => ({
	typography: {
		fontSize: '14px',
		color: props => (props.type === 'error' ? '#E02020' : '#455a64'),
		fontWeight: props => (props.type === 'secondary' ? null : 'bold'),
		marginTop: props => (props.type === 'primary' ? null : '16px'),
	},
});

export const useStyles = makeStyles(styles);
