export const PickingTypes = {
  frozen: "frozen",
  snacks: "snacks",
  basket: "basket",
  greenGrocer: "greenGrocer",
};
export const hasType = (productType) => (sale) =>
  sale.cart.product_types.includes(productType);

export const hasFrozen = hasType(PickingTypes.frozen);
export const hasSnacks = hasType(PickingTypes.snacks);
export const hasBasket = hasType(PickingTypes.basket);
export const hasGreenGrocer = hasType(PickingTypes.greenGrocer);

export const finishedPicking = (pickingType) => (sale) =>
  sale.logistics.picking[pickingType].end;

export const finishedFrozenPicking = finishedPicking(PickingTypes.frozen);
export const finishedSnacksPicking = finishedPicking(PickingTypes.snacks);
export const finishedBasketPicking = finishedPicking(PickingTypes.basket);
export const finishedGreenGrocerPicking = finishedPicking(
  PickingTypes.greenGrocer
);

export const PickingTypesByDispatchType = {
  frozen: [PickingTypes.frozen, PickingTypes.snacks],
  greenGrocer: [PickingTypes.greenGrocer],
};

export const PackageCodesByDispatchType = {
  frozen: ["F", "S"],
  greenGrocer: ["G"],
};

export const hasDispatch = (dispatchType) => (sale) =>
  PickingTypesByDispatchType[dispatchType].some((pickingType) =>
    hasType(pickingType)(sale)
  );

export const finishedDispatch = (dispatchType) => (sale) =>
  sale.logistics[dispatchType] && sale.logistics[dispatchType].end;
