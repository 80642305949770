import { handleRequestError } from 'actions/ErrorHandling';
import {
	PRODUCTS_INFO_REQUEST,
	PRODUCTS_INFO_RESPONSE,
	PRODUCTS_INFO_ERROR,
	PRODUCTS_INFO_ERROR_CLEAR,
} from 'constants/ActionTypes';

export const getProductsInfos = ({ rejectedBatches } = {}) => async (dispatch, _getState, api) => {
	try {
		dispatch({
			type: PRODUCTS_INFO_REQUEST,
		});
		const response = await api.get('/products-info/get-products', {
			params: {
				rejectedBatches,
			},
		});
		dispatch({
			type: PRODUCTS_INFO_RESPONSE,
			payload: response.data,
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: PRODUCTS_INFO_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const errorClear = () => async dispatch => {
	dispatch({
		type: PRODUCTS_INFO_ERROR_CLEAR,
	});
};
