import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import App from './containers/App';
import configureStore, { history } from './store';

moment.locale('pt-BR');

export const store = configureStore();

const MainApp = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Route path="/" component={App} />
		</ConnectedRouter>
	</Provider>
);

export default MainApp;
