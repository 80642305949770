import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { showFooter } from 'actions/Setting';
import PalletSelectionContainer from './containers/PalletSelectionContainer';
import BoxReadContainer from './containers/BoxReadContainer';
import { PageTitle } from './components/PageTitle';

const ClosePallet = props => {
	useEffect(() => {
		props.showFooter(false);
		return () => props.showFooter(true);
	});

	const handleSelectPallet = useCallback(
		pallet => props.history.push(`/app/close-pallet/${pallet._id}`),
		[props.history]
	);
	const handlePalletListNav = useCallback(() => props.history.push('/app/close-pallet'), [
		props.history,
	]);

	return (
		<div className="app-wrapper">
			<PageTitle title="Fechar Pallets" />
			<Route
				exact
				path="/app/close-pallet"
				render={() => <PalletSelectionContainer onSelectPallet={handleSelectPallet} />}
			/>
			<Route
				path="/app/close-pallet/:id"
				render={() => (
					<BoxReadContainer
						onNullPallet={handlePalletListNav}
						onFinish={handlePalletListNav}
					/>
				)}
			/>
		</div>
	);
};

ClosePallet.propTypes = {
	history: PropTypes.object.isRequired,
	showFooter: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	showFooter,
};

export default connect(
	_state => {},
	mapActionsToProps
)(ClosePallet);
