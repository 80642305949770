import axios from 'axios';
import { INVENTORY_API_ADDRESS } from 'constants/InventoryAPI';
import {
	GET_CLOSED_PALLETS_REQUEST,
	GET_CLOSED_PALLETS_RESPONSE,
	GET_CLOSED_PALLETS_ERROR,
	DELETE_PALLET_REQUEST,
	DELETE_PALLET_RESPONSE,
	DELETE_PALLET_ERROR,
} from '../constants/ActionTypes';
import { handleRequestError } from './ErrorHandling';

export const getClosedPallets = () => async dispatch => {
	try {
		dispatch({
			type: GET_CLOSED_PALLETS_REQUEST,
		});
		const authToken = localStorage.getItem('authToken');
		const response = await axios.get(`${INVENTORY_API_ADDRESS}/pallets/closed-pallets`, {
			headers: {
				Authorization: authToken ? `Bearer ${authToken}` : '',
				'Content-Type': 'application/json',
			},
		});
		dispatch({
			type: GET_CLOSED_PALLETS_RESPONSE,
			payload: {
				data: response.data.pallets,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: GET_CLOSED_PALLETS_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const deletePallet = pallet => async dispatch => {
	try {
		dispatch({
			type: DELETE_PALLET_REQUEST,
		});
		const authToken = localStorage.getItem('authToken');
		const response = await axios.delete(
			`${INVENTORY_API_ADDRESS}/pallets/delete-pallet?palletId=${pallet._id}`,
			{
				headers: {
					Authorization: authToken ? `Bearer ${authToken}` : '',
					'Content-Type': 'application/json',
				},
			}
		);
		dispatch({
			type: DELETE_PALLET_RESPONSE,
			payload: {
				palletClosed: response.status === 200,
			},
		});
		dispatch(getClosedPallets()); // reload the pallets
	} catch (err) {
		const error = err.response ? err.response.data : err;
		dispatch({
			type: DELETE_PALLET_ERROR,
			payload: {
				error,
			},
		});
	}
};
