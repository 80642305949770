import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import DCSelect from 'containers/DCSelect';
import PeriodSelect from 'containers/PeriodSelect';
import { styles } from '../constants/styles';

class RouteForm extends React.Component {
	handlePeriodInput = route => {
		this.props.onRouteInput();
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.onClick();
	};

	render() {
		const { classes } = this.props;
		const today = moment(this.props.date).format('DD/MM/YYYY');
		return (
			<Grid container>
				<Grid item xs={12}>
					<Grid container justify="center">
						<Grid item xs={6}>
							<Card className="shadow border-0">
								<CardBody>
									<form onSubmit={this.handleSubmit}>
										<Grid container direction="column">
											<Grid item xs={12}>
												<h1>Data: {today}</h1>
											</Grid>
											<Grid item xs={12}>
												<DCSelect />
											</Grid>
											<Grid item xs={12}>
												<PeriodSelect />
											</Grid>
											<Grid item xs={12}>
												<Button
													variant="raised"
													color="primary"
													type="submit"
													className="jr-btn text-blue-gray"
													onClick={() => this.handlePeriodInput()}
												>
													Buscar Rotas
												</Button>
											</Grid>
										</Grid>
									</form>
								</CardBody>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(RouteForm);
