import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { fromEvent } from 'rxjs';
import { scan, map, debounceTime } from 'rxjs/operators';
import { PropTypes } from 'prop-types';
import { getProductsInfos, errorClear } from 'actions/ProductsInfo';
import { fetchDistributionCenters, selectDistributionCenter } from 'actions/DistributionCenters';
import { connect } from 'react-redux';
import { sendNotification, errorNotification } from 'actions/Notifications';
import { save } from 'actions/Counting';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import DcBox from '../components/DcBox';
import ProductBox from '../components/ProductBox';
import LoadingDcs from '../components/LoadingDcs';
import Totals from '../components/Totals';
import ManualButton from '../components/ManualButton';
import ButtonsFooter from '../components/ButtonsFooter';
import ConfirmModal from '../components/ConfirmModal';
import SinglePackageAs20Radio from '../components/SinglePackageAs20Radio';
const BUSY = 200;

const triggerBusy = setBusy => {
	setBusy(true);
	setTimeout(() => {
		setBusy(false);
	}, BUSY);
};

const inventoryTypes = {
	chamber: 'Câmara fria | Freezers de estoque',
	picking: 'Freezers de Picking',
	snacks: 'Snacks',
};
const formatBatch = batch => `20${batch.slice(-2)}${batch.slice(2, -2)}${batch.slice(0, 2)}`;

const getInfoFromScannedCode = (barcode, singlePackageCountingAsBag) => {
	if (barcode.length === 22) {
		return {
			sku: barcode.slice(2, 6),
			batch: barcode.slice(9, 17),
			quantity: Number(barcode.slice(6, 9)),
		};
	}
	return {
		sku:
			String.fromCharCode(96 + parseInt(barcode.substr(1, 2))).toUpperCase() +
			barcode.substr(3, 3),
		batch: formatBatch(barcode.slice(0, -1).slice(-6)),
		quantity: singlePackageCountingAsBag ? 20 : 1,
	};
};

const CountingContainer = props => {
	const [step, setStep] = useState(0);
	const [dc, setDc] = useState();
	const [items, setItems] = useState({});
	const [busy, setBusy] = useState(false);
	const [total, setTotal] = useState(0);
	const [lastProduct, setLastProduct] = useState();
	const [inventoryType, setInventoryType] = useState();
	const [confirmModal, setConfirmModal] = useState(false);
	const [manual, setManual] = useState(false);
	const [barcodes, setBarcodes] = useState({});
	const [singlePackageCountingAsBag, setSinglePackageCountingAsBag] = useState(false);

	const resetState = () => {
		setStep(0);
		setItems({});
	};
	const handleTagScan = scannedCode => {
		triggerBusy(setBusy);
		let { sku, quantity, batch } = getInfoFromScannedCode(
			scannedCode,
			singlePackageCountingAsBag
		);
		if (!props.products[sku]) {
			const product = Object.keys(props.products).find(
				p => String(props.products[p].barcode) === scannedCode
			);
			if (
				!product ||
				(inventoryType === Object.keys(inventoryTypes)[0] &&
					!manual &&
					!singlePackageCountingAsBag)
			) {
				return props.sendNotification({
					type: 'error',
					message: 'Produto não encontrado',
				});
			}

			if (singlePackageCountingAsBag && !product.productsPerBag) {
				return props.sendNotification({
					type: 'error',
					message: `Esse produto não possui o campo "Número de produtos por saco" cadastrado.`,
				});
			}
			quantity = singlePackageCountingAsBag ? product.productsPerBag : 1;

			sku = product;
			batch = '';
			setLastProduct(props.products[sku].name);
			setTotal(total + quantity);
			setBarcodes({
				...(barcodes || {}),
				...(scannedCode.length === 22 && { [scannedCode]: scannedCode }),
			});
			setItems({
				...items,
				[sku]: {
					sku,
					name: props.products[sku].name,
					units:
						((items && items[sku] && items[sku].units) || 0) +
						(((quantity === 1 || singlePackageCountingAsBag) && quantity) || 0),
					batches: {
						...((items && items[sku] && items[sku].batches) || {}),
						...(batch && batch !== '' && { [batch]: batch }),
					},
				},
			});
			return;
		}
		if (
			(inventoryType === Object.keys(inventoryTypes)[1] || manual) &&
			scannedCode.length > 13
		) {
			return props.sendNotification({
				type: 'error',
				message: 'Esse não é um código de saquinho',
			});
		}
		if (
			(!inventoryType || inventoryType === Object.keys(inventoryTypes)[0]) &&
			!manual &&
			!singlePackageCountingAsBag &&
			scannedCode.length < 20
		) {
			return props.sendNotification({
				type: 'error',
				message: 'Esse não é um código de caixa/saco',
			});
		}
		if (scannedCode.length === 22 && barcodes && barcodes[scannedCode]) {
			return props.sendNotification({
				type: 'error',
				message: 'Esse saco/caixa já foi lido uma vez',
			});
		}
		if (isNaN(quantity)) {
			return props.sendNotification({
				type: 'error',
				message: 'Erro na leitura, leia novamente por favor',
			});
		}

		if (singlePackageCountingAsBag && !props.products[sku].productsPerBag) {
			return props.sendNotification({
				type: 'error',
				message: `Esse produto não possui o campo "Número de produtos por saco" cadastrado.`,
			});
		}
		quantity = singlePackageCountingAsBag ? props.products[sku].productsPerBag : quantity;
		setLastProduct(props.products[sku].name);
		setTotal(total + quantity);
		setBarcodes({
			...(barcodes || {}),
			...(scannedCode.length === 22 && { [scannedCode]: scannedCode }),
		});
		setItems({
			...items,
			[sku]: {
				sku,
				name: props.products[sku].name,
				units:
					((items && items[sku] && items[sku].units) || 0) +
					(((quantity === 1 || singlePackageCountingAsBag) && quantity) || 0),
				batches: {
					...((items && items[sku] && items[sku].batches) || {}),
					[batch]: batch,
				},
			},
		});
	};

	useEffect(() => {
		props.fetchDistributionCenters();
		props.getProductsInfos();
		return () => {};
	}, []);

	useEffect(() => {
		if (props.saveSuccess) {
			setTotal(0);
			setItems({});
			setLastProduct();
			setBarcodes({});
			setManual(false);
			setSinglePackageCountingAsBag(false);
			if (dc.kind !== 'Warehouse') {
				setInventoryType();
				setStep(step - 1);
			}
		}
		return () => {};
	}, [props.saveSuccess]);

	const handlePaste = ({ clipboardData }) => {
		const barcode = clipboardData.getData('Text');
		handleTagScan(barcode);
	};

	useEffect(() => {
		if (step === 2) {
			if (!busy) {
				window.addEventListener('paste', handlePaste);
				const obs = fromEvent(window, 'keypress')
					.pipe(
						map(event => {
							const { keyCode } = event;
							if (keyCode === 13) {
								return '';
							}
							return String.fromCharCode(keyCode);
						}),
						scan((acc, cur) => `${acc}${cur}`, ''),
						debounceTime(100)
					)
					.subscribe(handleTagScan);
				return () => {
					window.removeEventListener('paste', handlePaste);
					obs.unsubscribe();
				};
			}
			return () => {};
		}
		return () => {};
	});

	useEffect(() => {
		if (
			props.distributionCenters &&
			props.distributionCenters.data &&
			props.distributionCenters.data.length > 0 &&
			localStorage.getItem('distributionCenter')
		) {
			const distributionCenter = props.distributionCenters.data.find(
				d => d._id === localStorage.getItem('distributionCenter')
			);
			if (distributionCenter.kind === 'Warehouse') {
				setStep(2);
			} else {
				setStep(1);
			}
			setDc(distributionCenter);
		}
		return () => {};
	}, [props.distributionCenters]);

	const handleClick = name => {
		const distributionCenter = props.distributionCenters.data.find(d => d.name === name);
		if (distributionCenter.kind === 'Warehouse') {
			setStep(2);
		} else {
			setStep(1);
		}
		props.selectDistributionCenter(
			distributionCenter._id,
			distributionCenter.address,
			distributionCenter.kind
		);
		setDc(distributionCenter);
	};

	const handleSelectType = type => {
		setInventoryType(Object.keys(inventoryTypes).find(key => inventoryTypes[key] === type));
		setStep(2);
	};

	const handleBack = () => {
		if (dc.kind === 'Warehouse') {
			setStep(step - 2);
		} else {
			setStep(step - 1);
		}
		if (step - 1 === 1) {
			setInventoryType();
		}
		if (step - 1 === 0) {
			setDc();
		}
		setSinglePackageCountingAsBag(false);
		setManual(false);
		setTotal(0);
		setItems({});
		setLastProduct();
		setBarcodes({});
	};
	const handleConfirmSave = () => {
		setConfirmModal(false);
		props.save({
			fromCenter: dc._id,
			fromKind: dc.kind,
			inventoryType,
			barcodes,
			items,
			total,
		});
	};

	const handleFinishClick = () => {
		setConfirmModal(true);
	};

	const handleClose = () => {
		setConfirmModal(false);
	};

	const handleManualClick = () => {
		if (!manual) {
			setSinglePackageCountingAsBag(false);
		}
		setManual(!manual);
	};

	const renderDcs = () => (
		<Grid>
			{props.distributionCenters.loading ? (
				<LoadingDcs />
			) : (
				props.distributionCenters.data.map(dc => (
					<DcBox name={dc.name} onClick={handleClick} />
				))
			)}
		</Grid>
	);

	const renderInventoryType = () => (
		<Grid>
			{Object.values(inventoryTypes).map(type => (
				<DcBox name={type} onClick={handleSelectType} />
			))}
		</Grid>
	);
	const renderCounting = () => (
		<Grid>
			{step === 2 && !manual && (
				<SinglePackageAs20Radio
					onTypeChange={setSinglePackageCountingAsBag}
					type={singlePackageCountingAsBag}
				/>
			)}
			<ProductBox product={lastProduct} />
			<Totals total={total} />
			{(!inventoryType || inventoryType === Object.keys(inventoryTypes)[0]) && (
				<ManualButton onClick={handleManualClick} manual={manual} />
			)}
		</Grid>
	);

	const renderButtonsFooter = () => (
		<ButtonsFooter
			finishing={props.saving}
			onBack={handleBack}
			onFinish={handleFinishClick}
			disabled={!(step === 2 && total > 0)}
		/>
	);

	const renderConfirmModal = () => (
		<ConfirmModal open={confirmModal} onClick={handleConfirmSave} onClose={handleClose} />
	);

	const subTitleMessage = `${dc && dc.name} ${(inventoryType &&
		`| ${inventoryTypes[inventoryType]}`) ||
		''}`;
	return (
		<Grid container direction="column">
			<Title />
			<SubTitle>{(dc && subTitleMessage) || 'Escolha o local onde deseja começar'}</SubTitle>
			{step === 0 && renderDcs()}
			{step === 1 && renderInventoryType()}
			{step === 2 && renderCounting()}
			{step !== 0 && renderButtonsFooter()}
			{step === 2 && renderConfirmModal()}
		</Grid>
	);
};

const mapStateToProps = state => ({
	distributionCenters: state.distributionCenters,
	saving: state.counting.saving,
	saveError: state.counting.saveError,
	saveSuccess: state.counting.saveSuccess,
	products: state.productsLoss.products,
});

CountingContainer.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.bool,
	products: PropTypes.array,
	sendNotification: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
	errorClear: PropTypes.func.isRequired,
	getProductsInfos: PropTypes.func.isRequired,
};

CountingContainer.defaultProps = {
	loading: false,
	error: false,
	products: [],
};

export default connect(mapStateToProps, {
	selectDistributionCenter,
	save,
	fetchDistributionCenters,
	getProductsInfos,
	sendNotification,
	errorNotification,
	errorClear,
})(CountingContainer);
