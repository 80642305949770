import React from 'react';
import PropTypes from 'prop-types';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';

class SamplePage extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.home" />}
				/>
				<div className="d-flex justify-content-center">
					<h1>
						<IntlMessages id="pages.home.description" />
					</h1>
				</div>
				<div className="d-flex justify-content-center">
					<h2>
						<IntlMessages id="pages.home.instructions" />
					</h2>
				</div>
			</div>
		);
	}
}

export default SamplePage;
