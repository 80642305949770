import { makeStyles } from '@material-ui/styles';

const styles = () => ({
	text: {
		color: '#4A4A4A',
		fontSize: '14px',
		marginLeft: '16px',
	},
});

export const useStyles = makeStyles(styles);
