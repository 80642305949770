import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Pallet from './Pallet';

const styles = theme => ({
	root: {
		marginBottom: '24.5%',
	},
});

const Pallets = ({ pallets, classes, handleClick, editable, handleDelete }) => {
	const deletePallet = i => () => handleDelete(pallets[i]);
	return (
		<Grid container direction="column" className={classes.root}>
			{pallets.map((pallet, index) => (
				<Pallet
					pallet={pallet}
					index={index}
					handleClick={handleClick}
					key={pallet._id}
					editable={editable}
					handleDelete={deletePallet}
				/>
			))}
		</Grid>
	);
};

Pallets.propTypes = {
	pallets: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	handleClick: PropTypes.func.isRequired,
	editable: PropTypes.bool,
	handleDelete: PropTypes.func,
};

Pallets.defaultProps = {
	editable: false,
	handleDelete: () => {},
};

export default withStyles(styles)(Pallets);
