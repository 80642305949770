export default [
  {
    path: "",
    component: "./Home",
    restricted: true,
    permission: "erp/*",
  },
  {
    path: "dispatch",
    component: "./Dispatch",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "delivery-routes",
    component: "./DeliveryRoutes",
    permission: "erp/logistics/*",
    restricted: true,
  },
  {
    path: "wms",
    component: "./RegisterPack",
    restricted: true,
    permission: "erp/logistics/*",
    children: [
      {
        path: "frozen",
        component: "./RegisterPack/containers/RegisterFrozenPack",
        restricted: true,
        permission: "erp/logistics/*",
      },
      {
        path: "snacks",
        component: "./RegisterPack/containers/RegisterSnackPack",
        restricted: true,
        permission: "erp/logistics/*",
      },
      {
        path: "green-grocer",
        component: "./RegisterPack/containers/RegisterGreenGrocerPack",
        restricted: true,
        permission: "erp/logistics/*",
      },
    ],
  },
  {
    path: "nut-picking",
    component: "./NutPicking",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "orders-list",
    component: "./OrdersList",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "routing/loggi",
    component: "./RoutingAPI/loggi",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "routing/fiorino",
    component: "./RoutingAPI/fiorino",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "products-loss",
    component: "./ProductsLoss",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "orders-return",
    component: "./OrdersReturn",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "counting/start",
    component: "./Counting",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "counting/finish",
    component: "./CountingFinish",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "transfer-check/order-arrival",
    component: "./OrderArrival",
    permission: "erp/logistics/*",
  },
  {
    path: "transfer-check",
    component: "./TransferCheck",
    permission: "erp/logistics/*",
  },
  {
    path: "nota-fiscal",
    component: "./FiscalPrinting",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "products-return",
    component: "./ProductsReturn",
    restricted: true,
    permission: "erp/logistics/*",
  },
  {
    path: "inventory-input",
    component: "./InventoryInput",
    restricted: true,
    permission: "erp/inventory/*",
  },
  {
    path: "close-pallet",
    component: "./ClosePallet",
    greedy: true,
    permission: "erp/inventory/*",
  },
  {
    path: "manage-pallet",
    component: "./ManagePallets",
    greedy: true,
    permission: "erp/inventory/*",
  },
];
