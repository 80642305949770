import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

function getModalStyle() {
	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
	};
}

const styles = theme => ({
	paper: {
		position: 'absolute',
		width: '60%',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
});

class ManualCheckModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
		};
	}

	renderRadioButtons() {
		const result = [];
		// iterate on the properties of the Object
		for (const possibleError in this.props.options) {
			// Check if the property is from the object class
			if (this.props.options.hasOwnProperty(possibleError)) {
				result.push(
					<Grid item>
						<Radio
							value={this.props.options[possibleError]}
							onChange={e => {
								this.setState({
									value: e.target.value,
								});
							}}
							checked={this.state.value === this.props.options[possibleError]}
						/>
						<span>{this.props.options[possibleError]}</span>
					</Grid>
				);
			}
		}
		if (result.length > 0) return result;
		return null;
	}

	render() {
		const { classes } = this.props;
		return (
			<Modal open={this.props.open}>
				<div style={getModalStyle()} className={classes.paper}>
					<Grid container justify="space-between" direction="column" alignItems="center">
						<Grid item>
							<h1>{this.props.header}</h1>
						</Grid>
						<Grid item>
							<h2>{this.props.subHeader}</h2>
						</Grid>
						<Grid container direction="row" justify="space-around">
							<Grid item>
								<h2>{this.props.optionsHeader}</h2>
							</Grid>
							<Grid item alignItems="flex-start" direction="column" xs={12}>
								{this.renderRadioButtons()}
							</Grid>
						</Grid>
						<Grid container direction="row" justify="space-around">
							<Grid item xs={4}>
								<Button variant="raised" onClick={this.props.onRequestClose}>
									Cancelar
								</Button>
							</Grid>
							<Grid item xs={4}>
								<Button
									variant="raised"
									onClick={() => {
										if (this.state.value) {
											this.props.onRequestSend(this.state.value);
										}
									}}
								>
									Escolher
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Modal>
		);
	}
}

ManualCheckModal.propTypes = {
	onRequestSend: PropTypes.func.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

ManualCheckModal.defaultProps = {
	productName: '',
};

export default withStyles(styles)(ManualCheckModal);
