import { NOTIFICATION_SEND, NOTIFICATION_RESET } from '../constants/ActionTypes';
import { errorTranslator } from '../utils/errorTranslator';

export const errorNotification = (err, route) => async dispatch => {
	const message = errorTranslator(err, route);
	dispatch({
		type: NOTIFICATION_SEND,
		payload: {
			type: 'error',
			message,
			autoDismiss: 0,
		},
	});
};

export const sendNotification = ({ type, message, autoDismiss, soundOff }) => async dispatch => {
	dispatch({
		type: NOTIFICATION_SEND,
		payload: {
			type,
			message,
			autoDismiss,
			soundOff,
		},
	});
};

export const resetNotification = () => async dispatch => {
	dispatch({
		type: NOTIFICATION_RESET,
	});
};
