import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import DispatchContainer from './containers/DispatchContainer';

class Dispatch extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.dispatch" />}
				/>
				<DispatchContainer />
			</div>
		);
	}
}

export default Dispatch;
