import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		marginBottom: '12px',
	},
	total: {
		color: '#008EA6',
		fontSize: '22px',
		fontFamily: 'Roboto',
		fontWeight: 'bold',
	},
	totalValue: {
		color: '#4D4D4D',
		fontSize: '24px',
		fontFamily: 'Roboto',
		fontWeight: 'bold',
	},
});

const Totals = ({ total, classes }) => (
	<Grid container justify="space-between" className={classes.root}>
		<Typography className={classes.total}>Total</Typography>
		<Typography className={classes.totalValue}>{total} unidades</Typography>
	</Grid>
);

export default withStyles(styles)(Totals);
