import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		color: '#008EA6',
		fontSize: '22px',
		fontFamily: 'Roboto',
		fontWeight: 'bold',
		marginBottom: '12px',
	},
});

const Title = ({ classes }) => (
	<Typography className={classes.root}>Checagem de estoque</Typography>
);

export default withStyles(styles)(Title);
