import React from 'react';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'utils/IntlMessages';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ProductTypeCards from '../components/ProductTypeCards';

class ProductTypeSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productType: '',
    };
  }

  handleFrozenCardClick = () => {
    const { location, history } = this.props;
    this.setState({ productType: 'frozen' });
    history.push(`${location.pathname}/frozen`);
  };

  handleSnacksCardClick = () => {
    const { location, history } = this.props;
    this.setState({ productType: 'snacks' });
    history.push(`${location.pathname}/snacks`);
  };

  handleGreenGrocerCardClick = () => {
    const { location, history } = this.props;
    this.setState({ productType: 'greenGrocer' });
    history.push(`${location.pathname}/green-grocer`);
  };

  render() {
    if (this.state.productType) {
      return null;
    }

    return (
      <React.Fragment>
        <ContainerHeader
          location={this.props.location}
          title={<IntlMessages id="pages.wms" />}
          showBreadcrumb
          backButton
        />
        <ProductTypeCards
          onFrozenCardClick={this.handleFrozenCardClick}
          onSnacksCardClick={this.handleSnacksCardClick}
          onGreenGrocerCardClick={this.handleGreenGrocerCardClick}
        />
      </React.Fragment>
    );
  }
}

ProductTypeSelector.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ProductTypeSelector);
