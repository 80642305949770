import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import OptionCard from './OptionCard';

const styles = theme => ({
	root: {},
	palletIcon: {
		width: '50%',
		opacity: 0.6,
	},
	newPalletIcon: {
		width: '60%',
		opacity: 0.6,
	},
	optionCardContainer: {
		height: '240px',
		marginBottom: 4 * theme.spacing.unit,
	},
});

class Options extends Component {
	render() {
		const { classes, handleClickNewPallet, handleClickChoosePallet } = this.props;
		const mobile = window.innerWidth < 800;
		return (
			<Grid container direction="column" className={classes.root} alignItems="center">
				<Grid item xs={10} className={classes.optionCardContainer}>
					<OptionCard name="Iniciar pallet novo" handleClick={handleClickNewPallet}>
						<img
							id="newPalletButton"
							src="../../../assets/images/new-pallet.svg"
							alt="Iniciar pallet novo"
							className={classes.newPalletIcon}
						/>
					</OptionCard>
				</Grid>
				<Grid item xs={10} className={classes.optionCardContainer}>
					<OptionCard
						name="Escolher um pallet em aberto"
						handleClick={handleClickChoosePallet}
					>
						<img
							id="choosePalletButton"
							src="../../../assets/images/choose-pallet.svg"
							alt="Escolher um pallet em aberto"
							className={classes.palletIcon}
						/>
					</OptionCard>
				</Grid>
			</Grid>
		);
	}
}
Options.propTypes = {
	classes: PropTypes.object.isRequired,
	handleClickNewPallet: PropTypes.func.isRequired,
	handleClickChoosePallet: PropTypes.func.isRequired,
};

export default withStyles(styles)(Options);
