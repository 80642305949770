export const styles = theme => ({
  routeOrderContainer: {
    position: 'relative',
  },
  singleCell: {
    borderRight: `1px solid ${theme.palette.primary.light}`,
    padding: '4px 24px 4px 24px',
    textAlign: 'center',
    width: '50%',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  orderCell: {
    height: '100%',
    width: '100%',
    fontSize: '1.4rem',
    fontWeight: 'bolder',
    backgroundColor: 'white',
    border: `${theme.palette.primary.light} 1px solid`,
  },
  fullHeight: {
    height: '100%',
  },
  checkedLine: {
    textDecoration: 'line-through',
  },
  checkedOpacity: {
    opacity: '.4',
  },
  gridCell: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  borderSeparator: {
    marginBottom: '20px',
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.primary.light}`,
  },
  textAlign: {
    textAlign: 'center',
  },
  onHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  routeDetailsHeader: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    padding: '10px 0 10px 0',
  },
  columnSubHeaders: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1rem',
    width: `50%`,
    padding: '10px 0 10px 0',
  },
  headersPadding: {
    padding: '10px 0 10px 0',
  },
  routeNumber: {
    fontSize: '1.4rem',
    padding: '10px 24px 10px 24px',
    width: '100%',
    marginBottom: '20px',
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  routeForm: {
    height: '300px',
    width: '40%',
  },
  fullWidth: {
    width: '100%',
  },
  subHeadersHeight: {
    height: '80px',
  },
  getRouteButton: {
    width: '100%',
    textAlign: 'center',
  },
  fallBackMessageMargin: {
    margin: '0 10px 0 10px',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  loadingAndErrorContainerHeight: {
    height: '400px',
  },
  packageNumberConfirmationButton: {
    width: '100%',
    height: '100%',
    textTransform: 'none !important',
  },
  routePeriod: {
    textTransform: 'capitalize',
  },
  orderStatus: {
    padding: '20px',
    textAlign: 'center',
  },
  orderStatusText: {
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: '600',
    fontSize: '18px',
  },
  alert: {
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: '600',
    fontSize: '19px',
    color: '#b70d0b',
  },
  barCodeInput: {
    marginTop: '10px',
  },
  lastRoutesCard: {
    width: '30px',
    backgroundColor: theme.palette.primary.light,
    height: 'auto',
    textAlign: 'center',
    fontWeight: theme.typography.button.fontWeight,
  },
  lastRoutesContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  clearLastRoutes: {},
  orderLocal: {
    textAlign: 'center',
    borderWidth: '1px',
    borderStyle: 'groove',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orderWithoutPackageCodes: {
    alignSelf: 'stretch',
  },
  circularProgress: {
    marginLeft: '25%',
  },
  printAllNFCesButtonCircularProgress: {
    marginLeft: '-30%',
  },
  printNFCeButtonContainer: {
    position: 'absolute',
    right: 0,
    height: '100%',
    width: '8.3%',
  },
  printNFCeButton: {
    height: '100%',
    width: '100%',
  },
  dispatchFinishButtonContainer: {
    height: '100%',
    marginRight: '45%',
  },
  dispatchFinishButton: {
    height: '54px',
    width: '112px',
  },
  printAllNFCesButtonContainer: {},
  printAllNFCesButton: {
    height: '100%',
    width: '100%',
  },
});
