import React from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { styles } from '../constants/styles';
import RouteOrder from './RouteOrder';

const RoutePosition = ({
  local,
  orders,
  onClick,
  classes,
  onOpenConfirmationDialog,
  pickingTypes,
}) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid container alignItems="center" justify="center" direction="row">
        <Grid item xs={4} className={classes.orderCell}>
          <Grid
            container
            className={`${classes.fullHeight} ${classes.textAlign} ${
                orders.every(o => o.done) ? classes.checkedLine : ''
              }`}
            alignItems="center"
            justify="center"
          >
            {local}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {orders.map(order => (
            <RouteOrder
              key={order.order}
              pickingTypes={pickingTypes}
              order={order}
              onClick={onClick}
              onOpenConfirmationDialog={onOpenConfirmationDialog}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles)(RoutePosition);

RoutePosition.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  local: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
  onOpenConfirmationDialog: PropTypes.func.isRequired,
  pickingTypes: PropTypes.array.isRequired,
};

RoutePosition.defaultProps = {
  onClick: () => {},
};
