import {
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST,
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE,
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR,
	PRODUCTS_INFO_REQUEST,
	PRODUCTS_INFO_RESPONSE,
	PRODUCTS_INFO_ERROR,
} from 'constants/ActionTypes';

const initialState = {
	products: {},
	loading: false,
	error: null,
	success: false,
};

const ProductsReturn = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				success: false,
			};
		case PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE:
			return {
				...state,
				loading: false,
				error: null,
				success: true,
			};
		case PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR:
			return {
				...state,
				loading: false,
				error: null,
				success: false,
			};
		case PRODUCTS_INFO_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				success: false,
			};
		case PRODUCTS_INFO_RESPONSE:
			return {
				...state,
				loading: false,
				products: action.payload,
				error: null,
				success: false,
			};
		case PRODUCTS_INFO_ERROR:
			return {
				...state,
				loading: false,
				error: null,
				success: false,
			};
		default:
			return state;
	}
};

export default ProductsReturn;
