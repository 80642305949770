import {
	ORDERS_RETURN_FINISH_REQUEST,
	ORDERS_RETURN_FINISH_RESPONSE,
	ORDERS_RETURN_FINISH_ERROR,
	ORDERS_RETURN_GET_ORDER_REQUEST,
	ORDERS_RETURN_GET_ORDER_RESPONSE,
	ORDERS_RETURN_GET_ORDER_ERROR,
} from '../constants/ActionTypes';

const initialState = {
	data: {
		loading: false,
		success: false,
	},
	finish: {
		loading: false,
		success: false,
		error: null,
	},
};

const ordersReturn = (state = initialState, action) => {
	switch (action.type) {
		case ORDERS_RETURN_FINISH_REQUEST:
			return {
				...state,
				finish: {
					loading: true,
					success: false,
				},
			};
		case ORDERS_RETURN_FINISH_RESPONSE:
			return {
				...state,
				finish: {
					loading: false,
					success: true,
				},
			};
		case ORDERS_RETURN_FINISH_ERROR:
			return {
				...state,
				finish: {
					loading: false,
					success: false,
					error: action.payload.error,
				},
			};
		case ORDERS_RETURN_GET_ORDER_REQUEST:
			return {
				...state,
				data: {
					loading: true,
					success: false,
				},
			};
		case ORDERS_RETURN_GET_ORDER_RESPONSE:
			return {
				...state,
				data: {
					...action.payload.data,
					loading: false,
					success: true,
				},
			};
		case ORDERS_RETURN_GET_ORDER_ERROR:
			return {
				...state,
				data: {
					error: action.payload.error,
				},
			};
		default:
			return state;
	}
};

export default ordersReturn;
