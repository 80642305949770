import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';

import Settings from './Settings';
import Auth from './Auth';
import snacksWMS from './SnacksWMS';
import frozenWMS from './FrozenWMS';
import greenGrocerWMS from './GreenGrocerWMS';
import deliveryRoutes from './DeliveryRoutes';
import notificationCenter from './NotificationCenter';
import distributionCenters from './DistributionCenters';
import dispatch from './Dispatch';
import productType from './ProductType';
import periods from './Periods';
import ordersList from './OrdersList';
import routeTypes from './RouteTypes';
import routingAPI from './RoutingAPI';
import productsLoss from './ProductsLoss';
import ordersReturn from './OrdersReturn';
import counting from './Counting';
import transferCheck from './TransferCheck';
import fiscalPrinting from './FiscalPrinting';
import productsReturn from './ProductsReturn';
import inventoryInput from './InventoryInput';
import closePallets from './ClosePallets';
import managePallets from './ManagePallets';
import history from '../store/history';

const reducers = combineReducers({
  frozenWMS,
  snacksWMS,
  greenGrocerWMS,
  routing: routerReducer,
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  deliveryRoutes,
  notificationCenter,
  distributionCenters,
  dispatch,
  periods,
  productType,
  ordersList,
  routeTypes,
  routingAPI,
  productsLoss,
  ordersReturn,
  counting,
  transferCheck,
  fiscalPrinting,
  productsReturn,
  inventoryInput,
  closePallets,
  managePallets,
});

export default reducers;
