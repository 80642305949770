import {
	DISTRIBUTION_CENTERS_FETCH_REQUEST,
	DISTRIBUTION_CENTERS_FETCH_RESPONSE,
	DISTRIBUTION_CENTERS_FETCH_ERROR,
	DISTRIBUTION_CENTERS_SELECT,
} from 'constants/ActionTypes';

import { parseIfPossible } from 'utils/parsers';

const initialState = {
	data: [],
	loading: false,
	error: null,
	selected: localStorage.getItem('distributionCenter') || '',
	selectedAddress: parseIfPossible(localStorage.getItem('distributionCenterLocation')),
	selectedKind: localStorage.getItem('distributionCenterKind') || '',
};

const distributionCenters = (state = initialState, action) => {
	switch (action.type) {
		case DISTRIBUTION_CENTERS_FETCH_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case DISTRIBUTION_CENTERS_FETCH_RESPONSE:
			return {
				...state,
				loading: false,
				data: action.payload.data,
			};
		case DISTRIBUTION_CENTERS_FETCH_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};
		case DISTRIBUTION_CENTERS_SELECT:
			return {
				...state,
				selected: action.payload.data.id,
				selectedAddress: action.payload.data.address,
				selectedKind: action.payload.data.kind,
			};
		default:
			return state;
	}
};

export default distributionCenters;
