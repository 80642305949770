import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { PropTypes } from 'prop-types';
import styles from '../styles.css';

const ProductTypeCards = ({
  onFrozenCardClick,
  onSnacksCardClick,
  onGreenGrocerCardClick,
}) => {
  function renderFrozenCard() {
    return (
      <Card className={styles.productTypeCard}>
        <ButtonBase
          onClick={onFrozenCardClick}
          className={styles.buttonBaseStyles}
        >
          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <Typography variant="title">WMS Congelados</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subheading">
                Registrar posição de itens congelados
              </Typography>
            </Grid>
            <Grid item className={styles.iconStyles}>
              <img
                src="../../../assets/images/frozen-icon.svg"
                alt="Congelados"
                className={styles.iconSize}
              />
            </Grid>
          </Grid>
        </ButtonBase>
      </Card>
    );
  }

  function renderSnacksCard() {
    return (
      <Card className={styles.productTypeCard}>
        <ButtonBase
          onClick={onSnacksCardClick}
          className={styles.buttonBaseStyles}
        >
          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <Typography variant="title">WMS Snacks</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subheading">
                Registrar posição de snacks
              </Typography>
            </Grid>
            <Grid item className={styles.iconStyles}>
              <img
                src="../../../assets/images/snacks-icon.svg"
                alt=""
                className={styles.iconSize}
              />
            </Grid>
          </Grid>
        </ButtonBase>
      </Card>
    );
  }

  function renderGreenGrocerCard() {
    return (
      <Card className={styles.productTypeCard}>
        <ButtonBase
          onClick={onGreenGrocerCardClick}
          className={styles.buttonBaseStyles}
        >
          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <Typography variant="title">WMS Quitanda</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subheading">
                Registrar posição de quitanda
              </Typography>
            </Grid>
            <Grid item className={styles.iconStyles}>
              <img
                src="../../../assets/images/green-grocer-icon.svg"
                alt=""
                className={styles.iconSize}
              />
            </Grid>
          </Grid>
        </ButtonBase>
      </Card>
    );
  }

  return (
    <Grid container spacing={40}>
      <Grid item xs={12} md={6}>
        {renderFrozenCard()}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderSnacksCard()}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderGreenGrocerCard()}
      </Grid>
    </Grid>
  );
};

ProductTypeCards.propTypes = {
  onFrozenCardClick: PropTypes.func,
  onSnacksCardClick: PropTypes.func,
  onGreenGrocerCardClick: PropTypes.func,
};

ProductTypeCards.defaultProps = {
  onFrozenCardClick: () => {},
  onSnacksCardClick: () => {},
  onGreenGrocerCardClick: () => {},
};

export default ProductTypeCards;
