import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { yellow, green, grey, blue } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import { parseOrdersListToCSV } from "utils/parsers";
import PeriodSelect from "containers/PeriodSelect";
import DCSelect from "containers/DCSelect";
import { getOrdersList } from "actions/OrdersList.js";
import { hideDrawer, showDrawer } from "actions/Setting";
import OrdersListDateSelect from "../components/OrdersListDateSelect.js";
import styles from "../styles.css";

const getUniqueOptions = (ordersData, column) =>
  [...new Set(ordersData.map((order) => order[column]))].reduce(
    (acc, cur, i) => {
      acc[`${i}`] = cur;
      return acc;
    },
    {}
  );

const enumFormatter = (cell, row, enumObject) => enumObject[cell] || cell;

// Tooltips were temporarily disabled because they were affecting badly the performance
const clientFormatter = (cell, row) => (
  // <Tooltip title={`Tel: ${row.clientPhone}`} placement="top">
  <div>{cell}</div>
  // </Tooltip>
);

const localFormatter = (cell, row) =>
  row.justPayment ? "Apenas cobrança" : cell;

// const statusFormater = (type, cell, row, enumObject) => {
// 	const { startDate, endDate, user, boxId } = row[`${type}Status`];
// 	const startDateString = startDate && `Começo: ${new Date(startDate).toLocaleString('pt-BR')}`;
// 	const endDateString = endDate && `Término: ${new Date(endDate).toLocaleString('pt-BR')}`;
// 	const userString = user && `Autor: ${user}`;
// 	const boxIdString = boxId && `Id da caixa: ${boxId}`;
// 	const tooltipTitle = [startDateString, endDateString, userString, boxIdString]
// 		.filter(value => value)
// 		.join('\n');
// 	return (
// 		<Tooltip title={tooltipTitle} placement="top" classes={{ popper: styles.tooltip }}>
// 			<div>{enumFormatter(cell, row, enumObject)}</div>
// 		</Tooltip>
// 	);
// };

// const frozenStatusFormatter = (cell, row, enumObject) =>
// 	statusFormater('frozen', cell, row, enumObject);
// const snacksStatusFormatter = (cell, row, enumObject) =>
// 	statusFormater('snacks', cell, row, enumObject);

const themeStyles = (theme) => ({
  rowYellow: {
    backgroundColor: `${yellow[200]}`,
  },
  rowGreen: {
    backgroundColor: `${green[200]}`,
  },
  rowGrey: {
    backgroundColor: `${grey[400]}`,
  },
});

const selectRowProp = {
  mode: "checkbox",
  bgColor: blue[200],
  clickToSelect: true,
};

class OrdersListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment().format("YYYY-MM-DD"),
      isCooldownActive: false,
    };

    this.cooldownTimer = null;
  }

  componentWillMount() {
    this.props.hideDrawer();
  }

  componentDidMount() {
    this.getOrdersList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.period !== prevProps.period ||
      this.props.distributionCenter !== prevProps.distributionCenter ||
      this.state.date !== prevState.date
    ) {
      this.getOrdersList();
    }
  }

  componentWillUnmount() {
    this.props.showDrawer();
    if (this.cooldownTimer !== null) {
      clearTimeout(this.cooldownTimer);
    }
  }

  makeColumns() {
    const deliveryKindOptions = getUniqueOptions(
      this.props.ordersData,
      "deliveryKind"
    );
    const paymentStatusOptions = getUniqueOptions(
      this.props.ordersData,
      "paymentStatusText"
    );
    const frozenStatusOptions = getUniqueOptions(
      this.props.ordersData,
      "frozenStatusText"
    );
    const snacksStatusOptions = getUniqueOptions(
      this.props.ordersData,
      "snacksStatusText"
    );
    const greenGrocerStatusOptions = getUniqueOptions(
      this.props.ordersData,
      "greenGrocerStatusText"
    );
    const basketStatusOptions = getUniqueOptions(
      this.props.ordersData,
      "basketStatusText"
    );
    return [
      {
        text: "",
        className: styles.cell,
        columnClassName: `${styles.cell} ${this.props.classes.rowGrey}`,
        width: "50px",
        dataSort: true,
        dataFormat: (cell, row, formatExtraData, rowIndex) => rowIndex + 1,
      },
      {
        dataField: "routeNumber",
        text: "Rota",
        dataAlign: "center",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        rowSpan: true,
        width: "75px",
      },
      {
        dataField: "routeTypeText",
        text: "Tipo",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        width: "100px",
      },
      {
        dataField: "order",
        text: "Pedido",
        isKey: true,
        className: styles.cell,
        columnClassName: styles.cell,
        width: "85px",
        dataSort: true,
      },
      {
        dataField: "frozenLocation",
        text: "Cong. local",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        dataFormat: localFormatter,
        width: "80px",
      },
      {
        dataField: "snacksLocation",
        text: "Snacks local",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        dataFormat: localFormatter,
        width: "80px",
      },
      {
        dataField: "deliveryKind",
        text: "Tipo",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        filter: {
          type: "SelectFilter",
          options: deliveryKindOptions,
        },
        filterFormatted: true,
        dataFormat: enumFormatter,
        formatExtraData: deliveryKindOptions,
      },
      {
        dataField: "clientName",
        text: "Cliente",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "150px",
        dataFormat: clientFormatter,
      },
      {
        dataField: "clientPhone",
        text: "Telefone",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "150px",
      },
      {
        dataField: "observations",
        text: "Observações",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        width: "150px",
      },
      {
        dataField: "paymentStatusText",
        text: "Status do pagamento",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "150px",
        dataSort: true,
        filter: {
          type: "SelectFilter",
          options: paymentStatusOptions,
        },
        filterFormatted: true,
        dataFormat: enumFormatter,
        formatExtraData: paymentStatusOptions,
      },
      {
        dataField: "productTypes",
        text: "Tipos de produtos",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
        width: "100px",
      },
      {
        dataField: "routeStatusText",
        text: "Status da rota",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "150px",
      },
      {
        dataField: "frozenStatusText",
        text: "Congelados status",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "160px",
        dataSort: true,
        filter: {
          type: "SelectFilter",
          options: frozenStatusOptions,
        },
        filterFormatted: true,
        dataFormat: enumFormatter,
        formatExtraData: frozenStatusOptions,
      },

      {
        dataField: "amountOfFrozenPackages",
        text: "Congelados Pacotes",
        className: styles.cell,
        columnClassName: styles.cell,
        dataSort: true,
      },
      {
        dataField: "snacksStatusText",
        text: "Snacks Status",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "160px",
        dataSort: true,
        filter: {
          type: "SelectFilter",
          options: snacksStatusOptions,
        },
        filterFormatted: true,
        dataFormat: enumFormatter,
        formatExtraData: snacksStatusOptions,
      },
      {
        dataField: "amountOfSnacksPackages",
        text: "Snacks Pacotes",
        className: styles.cell,
        columnClassName: styles.cell,
      },
      {
        dataField: "greenGrocerStatusText",
        text: "Quitanda Status",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "160px",
        dataSort: true,
        filter: {
          type: "SelectFilter",
          options: greenGrocerStatusOptions,
        },
        filterFormatted: true,
        dataFormat: enumFormatter,
        formatExtraData: greenGrocerStatusOptions,
      },
      {
        dataField: "amountOfGreenGrocerPackages",
        text: "Quitanda Pacotes",
        className: styles.cell,
        columnClassName: styles.cell,
      },
      {
        dataField: "basketStatusText",
        text: "Cestas Status",
        className: styles.cell,
        columnClassName: styles.cell,
        width: "160px",
        dataSort: true,
        filter: {
          type: "SelectFilter",
          options: basketStatusOptions,
        },
        filterFormatted: true,
        dataFormat: enumFormatter,
        formatExtraData: basketStatusOptions,
      },
      {
        dataField: "amountOfBasketPackages",
        text: "Cestas Pacotes",
        className: styles.cell,
        columnClassName: styles.cell,
      },
    ];
  }

  getOrdersList = () => {
    this.props.getOrdersList(this.state.date);
    this.setState({ isCooldownActive: true });

    if (this.cooldownTimer !== null) {
      clearTimeout(this.cooldownTimer);
    }

    this.cooldownTimer = setTimeout(() => {
      this.setState({ isCooldownActive: false });
    }, 30000);
  };

  // eslint-disable-next-line
  makeFooter() {
    return [
      [
        {
          label: "Total",
          columnIndex: 1,
        },
        {
          label: "Total value",
          columnIndex: 2,
          align: "right",
          formatter: (tableData) => <strong>{tableData.length}</strong>,
        },
      ],
    ];
  }

  rowClassNameFormat = row => {
    if (!['paid', 'on_delivery'].includes(row.paymentStatus)) {
      return this.props.classes.rowGrey;
    }
    if (
      (row.routeStatus === "allocating" && row.routeDispatched) ||
      (row.routeType !== "loggi" && row.routeDispatched) ||
      (!row.routeType && row.saleDispatched) ||
      (row.deliveryStatus && row.deliveryStatus === "in_transit")
    ) {
      return this.props.classes.rowGreen;
    }
    if (row.routeStatus === "allocating") {
      return this.props.classes.rowYellow;
    }

    return "";
  };

  handleDateSubmit = (date) => {
    this.setState({
      date,
    });
  };

  handleExportToCsv = () => {
    parseOrdersListToCSV(
      this.props.ordersData,
      `sales_${this.state.date}_${this.props.period}.csv`
    );
  };

  handleRefreshOrdersList = () => {
    this.getOrdersList();
  };

  renderHeader() {
    const { date } = this.state;
    return (
      <Grid container direction="row" spacing={16}>
        <Grid item sm={4}>
          <DCSelect />
        </Grid>
        <Grid item sm={4}>
          <PeriodSelect />
        </Grid>
        <Grid item sm={4}>
          <OrdersListDateSelect
            selectedDate={date}
            onSelect={this.handleDateSubmit}
          />
        </Grid>
      </Grid>
    );
  }

  renderTable() {
    const { ordersData, loading } = this.props;
    const { ordersPerRoute } = this.state;

    if (loading) {
      return (
        <Grid container spacing={16}>
          <Grid item sm={12}>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    const columns = this.makeColumns();
    return (
      <Grid container spacing={16}>
        <Grid item sm={12}>
          <Button
            variant="raised"
            component="span"
            color="primary"
            className="jr-btn text-blue-gray"
            onClick={this.handleExportToCsv}
          >
            <i className="zmdi zmdi-download" />
            &nbsp;&nbsp;Exportar
          </Button>
          <Button
            variant="raised"
            component="span"
            color="primary"
            className="jr-btn text-blue-gray"
            onClick={this.handleRefreshOrdersList}
            disabled={this.state.isCooldownActive}
          >
            <i className="zmdi zmdi-refresh" />
            &nbsp;&nbsp;Atualizar lista
          </Button>
          <BootstrapTable
            data={ordersData}
            search
            footerData={this.makeFooter()}
            footer
            version="4"
            trClassName={this.rowClassNameFormat}
            selectRow={selectRowProp}
          >
            {columns.map((column) => (
              <TableHeaderColumn {...column} keyBoardNav>
                {column.text}
              </TableHeaderColumn>
            ))}
          </BootstrapTable>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          {this.renderHeader()}
          {this.renderTable()}
        </Grid>
      </Grid>
    );
  }
}

OrdersListContainer.propTypes = {
  getOrdersList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.string,
  ordersData: PropTypes.array.isRequired,
  period: PropTypes.string,
  distributionCenter: PropTypes.string,
  hideDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.func.isRequired,
};

OrdersListContainer.defaultProps = {
  loading: false,
  success: false,
  error: null,
  period: null,
  distributionCenter: null,
};

const mapStateToProps = (state) => ({
  loading: state.ordersList.loading,
  success: state.ordersList.success,
  error: state.ordersList.error,
  ordersData: state.ordersList.data,
  period: state.periods.selected,
  distributionCenter: state.distributionCenters.selected,
});

export default compose(
  withStyles(themeStyles),
  connect(
    mapStateToProps,
    {
      getOrdersList,
      hideDrawer,
      showDrawer,
    }
  )
)(OrdersListContainer);
