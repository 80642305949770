import {
	UPLOAD_ROUTES_REQUEST,
	UPLOAD_ROUTES_RESPONSE,
	UPLOAD_ROUTES_ERROR,
	DELIVERY_ROUTES_PERIODS_REQUEST,
	DELIVERY_ROUTES_PERIODS_RESPONSE,
	DELIVERY_ROUTES_PERIODS_ERROR,
	GET_ROUTES_REQUEST,
	GET_ROUTES_RESPONSE,
	GET_ROUTES_ERROR,
	UPDATE_ROUTE,
	CHECK_UPDATE_ROUTE,
	DELETE_ROUTE_REQUEST,
	DELETE_ROUTE_RESPONSE,
	DELETE_ROUTE_ERROR,
	CALL_LOGGI_REQUEST,
	CALL_LOGGI_RESPONSE,
	CALL_LOGGI_ERROR,
	SIMULATE_ROUTE_REQUEST,
	SIMULATE_ROUTE_RESPONSE,
	SIMULATE_ROUTE_ERROR,
	RESET_REPEATED_ROUTES,
	CHANGE_ROUTE_TYPE_REQUEST,
	CHANGE_ROUTE_TYPE_RESPONSE,
	CHANGE_ROUTE_TYPE_ERROR,
	TOGGLE_EXTRA_RETURN_REQUEST,
	TOGGLE_EXTRA_RETURN_RESPONSE,
	TOGGLE_EXTRA_RETURN_ERROR,
} from '../constants/ActionTypes';

const initialState = {
	routesContainer: {
		data: [],
		loading: false,
		error: null,
		order: null,
	},
	repeatedRoutesContainer: {
		data: [],
		loading: false,
		error: null,
	},
	periods: {
		data: [],
		loading: false,
		error: null,
	},
	upload: {
		loading: false,
		error: null,
		success: false,
	},
	delete: {
		loading: false,
	},
	loggi: {
		loading: false,
	},
	simulate: {
		loading: false,
	},
	changeRouteType: {
		loading: false,
	},
	extraReturn: {
		loading: false,
	},
};

const deliveryRoutes = (state = initialState, action) => {
	switch (action.type) {
		case UPLOAD_ROUTES_REQUEST:
			return {
				...state,
				upload: {
					loading: true,
					error: null,
					success: false,
				},
			};
		case UPLOAD_ROUTES_RESPONSE:
			return {
				...state,
				upload: {
					loading: false,
					error: null,
					success: true,
				},
				routesContainer: {
					data: [...action.payload.data.routes].sort(
						(curr, next) => curr.number - next.number
					),
				},
			};
		case UPLOAD_ROUTES_ERROR:
			return {
				...state,
				upload: {
					loading: false,
					error: action.payload.error,
					success: false,
				},
			};
		case DELIVERY_ROUTES_PERIODS_REQUEST:
			return {
				...state,
				periods: {
					...state.periods,
					loading: true,
					error: null,
				},
			};
		case DELIVERY_ROUTES_PERIODS_RESPONSE:
			return {
				...state,
				periods: {
					data: action.payload.data,
					loading: false,
				},
			};
		case DELIVERY_ROUTES_PERIODS_ERROR:
			return {
				...state,
				periods: {
					loading: false,
					error: action.payload.error,
				},
			};
		case GET_ROUTES_REQUEST:
			return {
				...state,
				routesContainer: {
					data: [],
					loading: true,
					error: null,
				},
			};
		case GET_ROUTES_RESPONSE:
			return {
				...state,
				routesContainer: {
					data: action.payload.data.routes,
					loading: false,
					error: null,
				},
			};
		case GET_ROUTES_ERROR:
			return {
				...state,
				routesContainer: {
					data: [],
					loading: false,
					error: action.payload.error,
				},
			};
		case UPDATE_ROUTE:
			return {
				...state,
				routesContainer: {
					data: state.routesContainer.data.map(route => {
						if (route._id === action.payload.updatedRoute._id)
							return action.payload.updatedRoute;
						return route;
					}),
				},
			};
		case CHECK_UPDATE_ROUTE:
			return {
				...state,
				repeatedRoutesContainer: {
					data: action.payload.repeatedRoutes,
					order: action.payload.order,
				},
			};
		case DELETE_ROUTE_REQUEST:
			return {
				...state,
				delete: {
					loading: true,
				},
			};
		case DELETE_ROUTE_RESPONSE:
			return {
				...state,
				routesContainer: {
					data: state.routesContainer.data.filter(
						route => route._id !== action.payload.data.deleted._id
					),
				},
				delete: {
					loading: false,
				},
			};
		case DELETE_ROUTE_ERROR:
			return {
				...state,
				delete: {
					loading: false,
				},
			};
		case CALL_LOGGI_REQUEST:
			return {
				...state,
				loggi: {
					loading: true,
				},
			};
		case CALL_LOGGI_RESPONSE:
			return {
				...state,
				loggi: {
					loading: false,
				},
			};
		case CALL_LOGGI_ERROR:
			return {
				...state,
				loggi: {
					loading: false,
				},
			};
		case SIMULATE_ROUTE_REQUEST:
			return {
				...state,
				simulate: {
					loading: true,
				},
			};
		case SIMULATE_ROUTE_RESPONSE:
			return {
				...state,
				simulate: {
					loading: false,
				},
			};
		case SIMULATE_ROUTE_ERROR:
			return {
				...state,
				simulate: {
					loading: false,
					error: action.payload.error,
				},
			};
		case RESET_REPEATED_ROUTES:
			return {
				...state,
				repeatedRoutesContainer: initialState.repeatedRoutesContainer,
			};
		case CHANGE_ROUTE_TYPE_REQUEST:
			return {
				...state,
				changeRouteType: {
					loading: true,
				},
			};
		case CHANGE_ROUTE_TYPE_RESPONSE:
			return {
				...state,
				changeRouteType: {
					loading: false,
				},
			};
		case CHANGE_ROUTE_TYPE_ERROR:
			return {
				...state,
				changeRouteType: {
					loading: false,
				},
			};
		case TOGGLE_EXTRA_RETURN_REQUEST:
			return {
				...state,
				extraReturn: {
					loading: true,
				},
			};
		case TOGGLE_EXTRA_RETURN_RESPONSE:
			return {
				...state,
				extraReturn: {
					loading: false,
				},
			};
		case TOGGLE_EXTRA_RETURN_ERROR:
			return {
				...state,
				extraReturn: {
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default deliveryRoutes;
