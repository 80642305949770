import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardSubtitle } from "reactstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import {
  ROUTES_STATUS,
  LOGGI_URI,
  ROUTES_TYPES,
  ROUTE_NAME_BY_TYPE,
} from "constants/DeliveryRoutes";

import styles from "../styles.css";

const routesPdfStyles = StyleSheet.create({
  title: {
    margin: 10,
    padding: 10,
    paddingBottom: 0,
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    fontSize: "12",
    color: "#353535",
  },
  row: {
    margin: 10,
    padding: 10,
    flexGrow: 0.1,
  },
  values: {
    color: "#000000",
  },
});

class RouteStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optimize: false,
      confirmationModalOpen: false,
      typeModalOpen: false,
      newRouteType: props.route.type,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.route.has_return_point) {
      return {
        optimize: false,
      };
    }
    return null;
  }

  handleOpenModal = () => {
    this.setState({
      confirmationModalOpen: true,
    });
  };

  handlePrintRoute = async () => {
    const RouteOrdersList = (
      <Document>
        <Page size="A4" style={routesPdfStyles.page}>
          <View style={routesPdfStyles.title}>
            <Text>
              Rota:{" "}
              <Text style={routesPdfStyles.values}>
                {this.props.route.number}
              </Text>
            </Text>
          </View>

          {this.props.route.packages.map((pkg, index) => (
            <View style={routesPdfStyles.row}>
              <Text>
                Pedido: <Text style={routesPdfStyles.values}>{pkg.order}</Text>
              </Text>
              <Text>
                Endereço:{" "}
                <Text style={routesPdfStyles.values}>{`${
                  pkg.destination.street
                }, ${pkg.destination.number}${(pkg.destination.complement &&
                  `, ${pkg.destination.complement}`) ||
                  ""} - ${pkg.destination.neighborhood}`}</Text>
              </Text>
              <Text>
                Sacolas:{" "}
                <Text style={routesPdfStyles.values}>
                  {Object.keys(pkg.packing)
                    .map(
                      (packingType) =>
                        `${pkg.packing[packingType].numberOfPackages ||
                          (pkg.packing[packingType].package_codes &&
                            pkg.packing[packingType].package_codes.length) ||
                          (pkg.packing[packingType].wms_packages &&
                            pkg.packing[packingType].wms_packages
                              .length)} ${packingType}`
                    )
                    .join(", ")}
                </Text>
              </Text>
              <Text>
                Pode deixar na portaria?:{" "}
                <Text style={routesPdfStyles.values}>
                  {pkg.authorize ? "Sim" : "NÃO PODE"}
                </Text>
              </Text>
              <Text>
                Pagamento:{" "}
                <Text style={routesPdfStyles.values}>
                  {pkg.paymentStatus.pt}
                </Text>
              </Text>
              <Text>
                Valor a cobrar:{" "}
                <Text style={routesPdfStyles.values}>
                  {(pkg.paymentStatus.id === "on_delivery" &&
                    `R$ ${(Number(pkg.paymentTotal) / 100).toFixed(2)}`) ||
                    ""}
                </Text>
              </Text>
            </View>
          ))}
        </Page>
      </Document>
    );

    const blob = await pdf(RouteOrdersList).toBlob();
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  };

  handleCloseModal = () => {
    this.setState({
      confirmationModalOpen: false,
    });
  };

  handleOpenTypeModal = () => {
    this.setState({
      typeModalOpen: true,
    });
  };

  handleCloseTypeModal = () => {
    this.setState({
      typeModalOpen: false,
    });
  };

  handleExtraReturnCheck = () => {
    const { route } = this.props;
    this.props.onCheckReturnPoint(route._id);
  };

  handleCallLoggi = () => {
    const { route } = this.props;
    this.props.onCallLoggi(route);
  };

  handleDeleteRoute = () => {
    this.setState({
      confirmationModalOpen: false,
    });
    const { route, onDelete } = this.props;
    onDelete(route);
  };

  handleSimulateRoute = () => {
    const { route } = this.props;
    const { optimize } = this.state;
    this.props.onSimulate(route, optimize);
  };

  handleCheckOptimization = (e) => {
    const { checked } = e.target;
    this.setState({ optimize: checked });
  };

  handleChangeRouteType = () => {
    const { newRouteType } = this.state;
    const { _id: routeId, type: currentType } = this.props.route;
    if (newRouteType !== currentType) {
      this.props.onChangeRouteType(routeId, newRouteType);
    }
    this.handleCloseTypeModal();
  };

  isRouteSimulated = () =>
    this.props.route.status === ROUTES_STATUS.SIMULATED ||
    this.props.route.status === ROUTES_STATUS.WAITING_ALLOCATION ||
    this.props.route.status === ROUTES_STATUS.ALLOCATING;

  renderExtraReturnCheckbox = () => {
    if (this.props.extraReturnLoading) {
      return <CircularProgress />;
    }
    if (this.props.route.type === ROUTES_TYPES.LOGGI) {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.route.has_return_point}
                onChange={this.handleExtraReturnCheck}
              />
            }
            label="Adicionar retorno extra"
          />
        </FormGroup>
      );
    }
    return null;
  };

  renderOptimizationCheckbox = () => (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.optimize}
            disabled={this.props.route.has_return_point}
            onChange={this.handleCheckOptimization}
          />
        }
        label="Permitir otimização"
      />
    </FormGroup>
  );

  renderCallLoggiButton = () => {
    if (this.props.loggiLoading) {
      return <CircularProgress />;
    }

    if (this.props.simulateLoading || this.props.extraReturnLoading) {
      return null;
    }

    if (this.props.route.status === ROUTES_STATUS.ALLOCATING) {
      return (
        <React.Fragment>
          Rota chamada acompanhe na
          <Button
            variant="outlined"
            component="a"
            color="primary"
            className={`jr-btn text-blue-gray ${styles.loggiLinkButton}`}
            href={LOGGI_URI}
            target="_blank"
            rel="noreferrer noopener"
          >
            Loggi
          </Button>
          <Button
            className="btn btn-sm"
            color="primary"
            variant="raised"
            onClick={this.handleCallLoggi}
          >
            Chamar Loggi Novamente
          </Button>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Button
          className="btn btn-sm"
          color="primary"
          variant="raised"
          disabled={!this.isRouteSimulated()}
          onClick={this.handleCallLoggi}
        >
          Chamar Loggi
        </Button>
      </React.Fragment>
    );
  };

  renderDeleteButton = () => {
    if (
      this.props.route.status === ROUTES_STATUS.ALLOCATING ||
      this.props.loggiLoading ||
      this.props.simulateLoading ||
      this.props.extraReturnLoading
    ) {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          variant="raised"
          color="secondary"
          className="btn btn-sm"
          onClick={this.handleOpenModal}
        >
          Deletar Rota
        </Button>
        <Dialog open={this.state.confirmationModalOpen}>
          <DialogTitle id="alert-dialog-title">
            {"Tem certeza que deseja deletar a rota?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseModal}>Cancelar</Button>
            <Button onClick={this.handleDeleteRoute}>Deletar</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  renderPrintRoute = () => {
    return (
      <Button
        variant="raised"
        color="primary"
        className="btn btn-sm"
        onClick={this.handlePrintRoute}
      >
        Imprimir Rota
      </Button>
    );
  };

  renderChangeTypeButton = () => {
    if (
      this.props.route.status === ROUTES_STATUS.ALLOCATING ||
      this.props.loggiLoading ||
      this.props.simulateLoading ||
      this.props.extraReturnLoading
    ) {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          onClick={this.handleOpenTypeModal}
          className={`jr-btn text-blue-gray ${styles.loggiLinkButton}`}
        >
          Alterar Tipo da Rota
        </Button>
        <Dialog open={this.state.typeModalOpen}>
          <DialogTitle id="alert-type-dialog-title">
            {"Escolher novo tipo da rota:"}
          </DialogTitle>
          <DialogActions>
            <Select
              value={this.state.newRouteType}
              onChange={(event) =>
                this.setState({ newRouteType: event.target.value })
              }
              inputProps={{
                name: "Tipo",
                id: "type-simple",
              }}
            >
              {Object.keys(ROUTES_TYPES).map((routeTypeName) => (
                <MenuItem value={ROUTES_TYPES[routeTypeName]}>
                  {routeTypeName.toLowerCase()}
                </MenuItem>
              ))}
            </Select>
            <Button onClick={this.handleCloseTypeModal}>Cancelar</Button>
            <Button onClick={this.handleChangeRouteType}>Alterar</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  renderSimulateButton = () => {
    if (
      this.props.route.status === ROUTES_STATUS.ALLOCATING ||
      this.props.loggiLoading ||
      this.props.extraReturnLoading
    ) {
      return null;
    }
    if (this.props.simulateLoading) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="btn btn-sm"
        onClick={this.handleSimulateRoute}
      >
        Simular Rota
      </Button>
    );
  };

  renderLoggiEstimates = () => (
    <React.Fragment>
      {this.props.route.delivery_eta && this.isRouteSimulated() ? (
        <span>
          <strong>ETA calculado:</strong>
          {` ${Math.ceil(this.props.route.delivery_eta / 60)} min`}
        </span>
      ) : (
        `ETA ainda não calculado.`
      )}
      <br />
      {this.props.route.estimated_price && this.isRouteSimulated() ? (
        <span>
          <strong>custo estimado: </strong>
          {this.props.route.estimated_price.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ) : (
        `Custo ainda não calculado.`
      )}
      <br />
      {this.props.route.route_optimized && this.isRouteSimulated() ? (
        <span>
          <strong>ordem da rota otimizada pela loggi</strong>
        </span>
      ) : (
        `rota mais barata não encontrada pela loggi`
      )}
    </React.Fragment>
  );

  render() {
    const { route } = this.props;
    return (
      <Card className="shadow border-0">
        <CardBody>
          <h3 className="card-title">
            {`Rota ${route.number} - ${route.period.name} - ${
              ROUTE_NAME_BY_TYPE[route.type]
            }`}
            {this.renderChangeTypeButton()}
          </h3>
          <CardSubtitle>
            {moment(route.date).format("dddd [,] ll")}
          </CardSubtitle>
          {route.type === ROUTES_TYPES.LOGGI && (
            <Grid container direction="row" spacing={24}>
              <Grid item>{this.renderLoggiEstimates()}</Grid>
              <Grid item>{this.renderExtraReturnCheckbox()}</Grid>
              <Grid item>{this.renderOptimizationCheckbox()}</Grid>
              <br />
              <Grid item>
                <span>
                  <strong>Retorno de maquininha: </strong>
                  {route.has_cc_machine_returning ? "Sim" : "Não"}
                </span>
                <br />
                <span>
                  <strong>Último ponto fora da zona: </strong>
                  {route.last_point_out_of_coverage_area ? "Sim" : "Não"}
                </span>
              </Grid>
            </Grid>
          )}
          <br />

          <Grid item>
            <Grid container justify="space-between">
              <Grid item>
                {route.type === ROUTES_TYPES.LOGGI &&
                  this.renderCallLoggiButton()}
                {route.type === ROUTES_TYPES.LOGGI &&
                  this.renderSimulateButton()}
                {this.renderDeleteButton()}
              </Grid>
              <Grid item>{this.renderPrintRoute()}</Grid>
            </Grid>
          </Grid>
        </CardBody>
        <div className="card-mt-footer" />
      </Card>
    );
  }
}

RouteStats.propTypes = {
  route: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.number,
    delivery_eta: PropTypes.number,
    estimated_price: PropTypes.number,
    status: PropTypes.string,
    has_return_point: PropTypes.bool,
    type: PropTypes.string,
    route_optimized: PropTypes.bool,
  }),
  loggiLoading: PropTypes.bool,
  simulateLoading: PropTypes.bool,
  onCallLoggi: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSimulate: PropTypes.func.isRequired,
  onCheckReturnPoint: PropTypes.func.isRequired,
  onChangeRouteType: PropTypes.func.isRequired,
};

RouteStats.defaultProps = {
  route: {},
  loggiLoading: false,
  simulateLoading: false,
};

export default RouteStats;
