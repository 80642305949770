import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DCSelect from 'containers/DCSelect';
import CardBox from 'components/CardBox';
import PeriodSelect from 'containers/PeriodSelect';
import DateSelect from 'components/DateSelect';
import { ROUTING_MODALS } from 'constants/RoutingAPI';
import { runRouter, fetchRouterStatus } from '../../../actions/RoutingAPI';
import { parseCSV } from '../../../utils/parsers';

class RoutingAPILoggiFormContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedDate: moment().format('YYYY-MM-DD'),
			filterOrders: false,
			csvFilename: '',
			csvData: null,
		};
	}

	componentDidMount() {
		this.timerID = setInterval(() => this.pollRouterStatus(), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	pollRouterStatus = () => {
		const { taskId, running } = this.props;
		if (running && taskId) {
			this.props.fetchRouterStatus(taskId);
		}
	};

	isSubmitDisabled() {
		const { selectedCenter, selectedPeriod, loading, running } = this.props;
		const { selectedDate } = this.state;

		if (loading || running || !selectedCenter || !selectedPeriod || !selectedDate) {
			return true;
		}
		return false;
	}

	handleSelectDate = date => {
		this.setState({ selectedDate: date });
	};

	handleFilterOrdersCheck = event => {
		this.setState({
			filterOrders: event.target.checked,
		});
	};

	handleUpload = event => {
		const file = event.target.files[0];
		this.setState({ csvFilename: file.name });

		parseCSV(file, { header: true }, csvData => this.setState({ csvData }));

		// clear input value so the same file can be reuploaded
		this.fileInput.value = '';
	};

	handleSubmit = () => {
		const { selectedCenter, selectedPeriod } = this.props;
		const { selectedDate, csvData, filterOrders } = this.state;

		let ordersFilter;
		if (filterOrders && csvData && csvData.data) {
			ordersFilter = csvData.data.map(row => Number(row.pedido));
		}
		this.props.runRouter({
			distributionCenterId: selectedCenter,
			date: selectedDate,
			period: selectedPeriod,
			ordersFilter,
			modal: ROUTING_MODALS.LOGGI,
		});
	};

	renderCSVStats() {
		const { filterOrders, csvFilename, csvData } = this.state;
		if (!filterOrders || !csvFilename || !csvData) {
			return null;
		}
		return (
			<span style={{ marginLeft: '50px' }}>{`${csvFilename} com ${
				csvData.data.length
			} pedidos`}</span>
		);
	}

	renderPickCSV() {
		const { filterOrders } = this.state;
		if (filterOrders) {
			return (
				<React.Fragment>
					<input
						accept=".csv"
						id="raised-button-file"
						multiple
						type="file"
						style={{ display: 'none' }}
						ref={input => {
							this.fileInput = input;
						}}
						onChange={this.handleUpload}
					/>
					<label htmlFor="raised-button-file">
						<Button
							disabled={this.isSubmitDisabled()}
							variant="raised"
							component="span"
							color="primary"
							className="jr-btn text-blue-gray"
						>
							Escolher .CSV
						</Button>
					</label>
					{this.renderCSVStats()}
				</React.Fragment>
			);
		}
		return null;
	}

	renderSubmit() {
		if (this.props.loading) {
			return (
				<Grid item xs={12}>
					<CircularProgress size={50} />
				</Grid>
			);
		}
		return (
			<Grid container direction="row" spacing={16} alignItems="baseline">
				<Grid item>
					<Button
						variant="raised"
						component="span"
						color="secondary"
						className="jr-btn text-blue-gray"
						disabled={this.isSubmitDisabled()}
						onClick={this.handleSubmit}
					>
						Roteirizar
					</Button>
				</Grid>
				<Grid item>
					<Typography variant="caption" align="left">
						O resultado será enviado para rotas@livup.com.br
					</Typography>
				</Grid>
			</Grid>
		);
	}

	renderProgress() {
		const { running, taskStatus } = this.props;
		if (running) {
			const progress =
				taskStatus.progress && taskStatus.progress >= 0 ? taskStatus.progress * 100 : 0;
			return (
				<React.Fragment>
					<Typography variant="caption" align="center" gutterBottom>
						Roteirizando, pode levar alguns segundos
					</Typography>
					<LinearProgress color="secondary" variant="determinate" value={progress} />
				</React.Fragment>
			);
		}
		return null;
	}

	renderIsFinished() {
		const { taskStatus } = this.props;
		if (taskStatus && taskStatus.isFinished) {
			return (
				<Typography variant="body2" gutterBottom>
					Roteirização finalizada. <br />
					O resultado foi enviado para o grupo rotas@livup.com.br
				</Typography>
			);
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="row mb-md-4 start-picking-form">
					<CardBox styleName="col-12">
						<div>
							<DCSelect />
							<DateSelect
								onSelect={this.handleSelectDate}
								selectedDate={this.state.selectedDate}
							/>
							<PeriodSelect />
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.filterOrders}
										onChange={this.handleFilterOrdersCheck}
										value="filterOrders"
									/>
								}
								label="Filtrar pedidos de Rota Própria e Bikes"
							/>
							<Grid container alignItems="center">
								{this.renderPickCSV()}
							</Grid>
							<br />
							{this.renderSubmit()}
						</div>
					</CardBox>
				</div>
				{this.renderProgress()}
				{this.renderIsFinished()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	selectedCenter: state.distributionCenters.selected,
	selectedPeriod: state.periods.selected,
	loading: state.routingAPI.loading,
	running: state.routingAPI.running,
	taskId: state.routingAPI.taskId,
	taskStatus: state.routingAPI.taskStatus,
});

RoutingAPILoggiFormContainer.propTypes = {
	loading: PropTypes.bool,
	running: PropTypes.bool,
	selectedCenter: PropTypes.string,
	selectedPeriod: PropTypes.string,
	taskId: PropTypes.string,
	taskStatus: PropTypes.shape({
		isFailed: PropTypes.bool,
		isFinished: PropTypes.bool,
		progress: PropTypes.number,
	}),
	// Actions
	runRouter: PropTypes.func.isRequired,
	fetchRouterStatus: PropTypes.func.isRequired,
};

RoutingAPILoggiFormContainer.defaultProps = {
	loading: false,
	running: false,
	selectedCenter: '',
	selectedPeriod: '',
	taskId: null,
	taskStatus: {},
};

export default connect(
	mapStateToProps,
	{ runRouter, fetchRouterStatus }
)(RoutingAPILoggiFormContainer);
