import React from 'react';
import CountingContainer from './containers/CountingContainer';

class Counting extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<CountingContainer />
			</div>
		);
	}
}

export default Counting;
