import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import teal from "@material-ui/core/colors/teal";
import {
  returnSingleOrder,
  getOrderToReturn,
} from "../../../actions/OrdersReturn";
import { sendNotification } from "../../../actions/Notifications";
import OrderReturnForm from "../components/OrderReturnForm";
import RouteDetails from "../../Dispatch/components/RouteDetails";
import { STEPS } from "../constants";
import {
  PackageCodesByDispatchType,
  PickingTypesByDispatchType,
} from "../../Dispatch/logic";

class OrdersReturnContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: STEPS.LOAD,
      orderData: {
        success: false,
        loading: false,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.orderData.loading) {
      return {
        orderData: {
          success: false,
          loading: true,
        },
        step: STEPS.LOAD,
      };
    }
    if (nextProps.orderData.success && prevState.orderData.loading) {
      return {
        orderData: {
          success: true,
          loading: false,
        },
        step: STEPS.FINISH,
      };
    }
    if (nextProps.finishStatus.success && prevState.step !== "step") {
      return {
        step: STEPS.LOAD,
      };
    }
  }
  getDispatchType = (code) => {
    const [_, pickingTypeInitial, ...rest] = code.split("-");
    const dispatchType = Object.keys(
      PackageCodesByDispatchType
    ).find((dispatchType) =>
      PackageCodesByDispatchType[dispatchType].includes(pickingTypeInitial)
    );
    this.setState({
      dispatchType,
    });
    return dispatchType;
  };
  handleGetOrder = (code) => {
    this.props.getOrderToReturn(code, this.getDispatchType(code));
  };

  handleFinishReturn = () => {
    const { orderData } = this.props;
    const { order, packing } = orderData.order.orders[0];
    this.props.returnSingleOrder(order, packing);
  };

  handleCancel = () => {
    this.setState({
      step: STEPS.LOAD,
      orderData: {
        success: false,
        loading: false,
      },
    });
  };

  render() {
    const { distributionCenter } = this.props;
    const { step } = this.state;
    if (step === STEPS.LOAD) {
      return (
        <Grid container>
          <OrderReturnForm
            onSubmitPackages={this.handleGetOrder}
            sendNotification={this.props.sendNotification}
            loading={this.props.orderData.loading}
          />
        </Grid>
      );
    }
    if (step === STEPS.FINISH) {
      if (this.props.finishStatus.loading) {
        return <CircularProgress size={50} />;
      }
      return (
        <RouteDetails
          date={this.state.date}
          onCancel={this.handleCancel}
          onFinish={this.handleFinishReturn}
          routeData={this.props.orderData.order}
          type="1"
          distributionCenter={distributionCenter}
          sendNotification={this.props.sendNotification}
          shouldRenderBillingInfo={false}
          title="Retorno de Pedido"
          titleBackgroundColor={teal[300]}
          pickingTypes={PickingTypesByDispatchType[this.state.dispatchType]}
        />
      );
    }
  }
}

OrdersReturnContainer.propTypes = {
  getOrderToReturn: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  returnSingleOrder: PropTypes.func.isRequired,
  orderData: PropTypes.object,
  finishStatus: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
  }),
};

OrdersReturnContainer.defaultProps = {
  orderData: {},
  finishStatus: {
    loading: false,
    success: false,
  },
};

const mapStateToProps = (state) => ({
  orderData: state.ordersReturn.data,
  finishStatus: state.ordersReturn.finish,
  distributionCenter: state.distributionCenters.selectedAddress,
});

export default connect(mapStateToProps, {
  sendNotification,
  getOrderToReturn,
  returnSingleOrder,
})(OrdersReturnContainer);
