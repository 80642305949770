import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import ProductsReturnContainer from './containers/ProductsReturnContainer';

class ProductsReturn extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				{window.innerWidth > 800 && (
					<ContainerHeader
						location={this.props.location}
						title={<IntlMessages id="pages.productsReturn" />}
					/>
				)}
				<ProductsReturnContainer />
			</div>
		);
	}
}

export default ProductsReturn;
