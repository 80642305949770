import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import classNames from 'classnames';
import SimpleModal from './SimpleModal';

const styles = theme => ({
	root: {
		marginTop: 4,
		marginBottom: 4,
		padding: '8px 12px 8px 12px',
		flex: 1,
	},
	container: {},
	palletIndex: {
		color: '#e98081',
		marginBottom: '6px',
		fontSize: '12px',
	},
	divider: {
		width: '100%',
		marginTop: '1%',
		marginBottom: '2%',
		marginLeft: 0,
		marginRight: 0,
		backgroundColor: '#e4e4e4',
	},
	verticalDivider: {
		marginTop: '12px',
		marginBottom: '12px',
		backgroundColor: '#e4e4e4',
		width: '1px',
	},
	itemName: {
		marginBottom: '6px',
		color: '#2a3644',
		fontSize: '14px',
		fontWeight: 'bold',
	},
	ellipsedItemName: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflowX: 'hidden',
	},
	emptyPalletItemName: {
		marginBottom: '0.5%',
		color: '#888888',
		fontSize: '14px',
	},
	boxQuantity: {
		color: '#e98081',
		fontSize: '14px',
		fontWeight: 'bold',
	},
	date: {
		color: '#888888',
		fontSize: '12px',
	},
	expirationDate: {
		color: '#888888',
		fontSize: '12px',
	},
	cell: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 4,
		marginBottom: 4,
	},
	deleteContainer: {
		padding: '12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		flexDirection: 'column',
		'&:hover': {
			background: 'transparent',
			opacity: '0.7',
		},
	},
	deleteText: {
		color: '#008fa8',
		fontWeight: 'bold',
		fontSize: '12px',
	},
	deleteIcon: {
		color: '#008fa8',
	},
});

const formatDate = (dateString, expiration) => {
	if (expiration) {
		return moment(new Date(dateString))
			.utc()
			.add(expiration, 'day')
			.format('DD/MM/YYYY');
	}
	return moment(new Date(dateString))
		.utc()
		.format('DD/MM/YYYY');
};

const Pallet = ({ index, pallet, classes, handleClick, ellipsis, editable, handleDelete }) => {
	const [deleteOpen, setDeleteOpen] = useState(false);
	return (
		<Paper className={classes.cell}>
			<ListItem
				className={classes.root}
				onClick={() => {
					if (handleClick) {
						handleClick({ index, ...pallet });
					}
				}}
			>
				<Grid container direction="column" className={classes.container}>
					{pallet.items.length === 0 && (
						<Fragment>
							<Typography className={classes.palletIndex}>
								Número do pallet
							</Typography>
							<Grid container className={classes.item}>
								<Grid container direction="row" justify="space-between">
									<Typography className={classes.emptyPalletItemName}>
										Produto a ser incluído
									</Typography>
									<Typography className={classes.boxQuantity}>0 cx</Typography>
								</Grid>
								<Grid container direction="row" justify="space-between">
									<Typography className={classes.date}>
										Fabricação: dd/mm/aa
									</Typography>
									<Typography className={classes.expirationDate}>
										Validade: dd/mm/aa
									</Typography>
								</Grid>
							</Grid>
						</Fragment>
					)}
					{pallet.items.length > 0 && (
						<Fragment>
							<Typography className={classes.palletIndex}>
								Pallet {pallet._id}
							</Typography>
							{pallet.items.map((item, itemIndex) => (
								<Grid container className={classes.item}>
									<Grid container direction="row" justify="space-between">
										<Grid item xs={10}>
											<Typography
												className={classNames(
													classes.itemName,
													ellipsis && classes.ellipsedItemName
												)}
											>
												{item.name}
											</Typography>
										</Grid>
										<Typography className={classes.boxQuantity}>
											{item.quantity} cx
										</Typography>
									</Grid>
									<Grid container direction="row" justify="space-between">
										<Typography className={classes.date}>
											Fabricação: {formatDate(item.batch)}
										</Typography>
										<Typography className={classes.expirationDate}>
											Validade: {formatDate(item.batch, item.expiration)}
										</Typography>
									</Grid>
									{itemIndex === 0 && pallet.items.length > 1 && (
										<Divider variant="middle" className={classes.divider} />
									)}
								</Grid>
							))}
						</Fragment>
					)}
				</Grid>
			</ListItem>
			{editable && <div className={classes.verticalDivider} />}
			{editable && (
				<Button
					variant="text"
					className={classes.deleteContainer}
					onClick={() => setDeleteOpen(true)}
				>
					<DeleteIcon className={classes.deleteIcon} />
					<Typography className={classes.deleteText}> Excluir Pallet </Typography>
				</Button>
			)}
			<SimpleModal
				open={deleteOpen}
				handleClose={() => setDeleteOpen(false)}
				handleConfirm={handleDelete(index)}
				title="Excluir Pallet"
				text="Tem certeza que deseja excluir o pallet?"
			/>
		</Paper>
	);
};

Pallet.propTypes = {
	index: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
	pallet: PropTypes.object.isRequired,
	handleClick: PropTypes.func,
	handleDelete: PropTypes.func,
	editable: PropTypes.bool,
};

Pallet.defaultProps = {
	handleClick: null,
	handleDelete: () => {},
	editable: false,
};

export default withStyles(styles)(Pallet);
