export default theme => ({
	inventoryInputPageTitle: {
		color: '#008fa8',
		fontSize: '18px',
		fontWeight: 'bold',
		marginBottom: '8px',
	},
	palletListTitle: {
		fontSize: '14px',
		color: '#455a64',
		fontWeight: 'bold',
	},
	instruction: {
		marginTop: '16px',
		marginBottom: '4px',
		fontSize: '12px',
		color: '#455a64',
	},
	inputComponentBackButton: {
		marginTop: 3 * theme.spacing.unit,
	},
	barCodeInputContainer: {
		display: 'none',
	},
	loadingContainer: {
		display: 'flex',
		width: '100%',
		height: '450px',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
