import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Typography from '@material-ui/core/Typography';

const Products = ({ items }) => (
	<Grid container direction="column">
		{items.map(item => (
			<Grid item>
				<Typography variant="h6">
					<b>
						{item.quantity} x {item.name}
					</b>
				</Typography>
			</Grid>
		))}
	</Grid>
);

Products.propTypes = {
	items: PropTypes.array.isRequired,
};

Products.defaultProps = {};

export default withStyles(styles)(Products);
