import React from 'react';
import CountingFinishContainer from './containers/CountingFinishContainer';

class Counting extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<CountingFinishContainer />
			</div>
		);
	}
}

export default Counting;
