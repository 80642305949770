import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';

import reducers from '../reducers';
import history from './history';

const routeMiddleware = routerMiddleware(history);

export default () => {
	const axiosInstance = axios.create({
		baseURL: process.env.API_ADDRESS || 'http://localhost:3000',
		withCredentials: true,
	});

	axiosInstance.interceptors.request.use(config => {
		const authToken = localStorage.getItem('authToken');
		if (!config.headers.Authorization) {
			config.headers.Authorization = authToken ? `Bearer ${authToken}` : '';
		}
		return config;
	});

	const thunkMiddleware = thunk.withExtraArgument(axiosInstance);

	const store = createStore(
		reducers,
		{},
		composeWithDevTools(applyMiddleware(routeMiddleware, thunkMiddleware))
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers/index', () => {
			const nextRootReducer = require('../reducers/index');
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
};

export { history };
