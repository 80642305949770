import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPallets, setPallet, resetStore } from 'actions/ClosePallets';
import { getProductsInfos } from 'actions/ProductsInfo';
import Pallets from '../../InventoryInput/components/Pallets';
import { PaperHeader } from '../components/Header';
import { CircularProgress } from '@material-ui/core';

const PalletSelectionContainer = props => {
	useEffect(() => {
		props.resetStore();
		props.getPallets();
		props.getProductsInfos();
	}, []);

	const hanldePalletClick = useCallback(
		pallet => {
			props.setPallet(pallet);
			props.onSelectPallet(pallet);
		},
		[props.setPallet, props.onSelectPallet]
	);

	return props.loadingPallets || props.loadingProducts ? (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '450px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress size={50} />
		</div>
	) : (
		<React.Fragment>
			<PaperHeader text="Lista de Pallets" type="primary" />
			<Pallets pallets={props.pallets} handleClick={hanldePalletClick} />
		</React.Fragment>
	);
};

PalletSelectionContainer.propTypes = {
	pallets: PropTypes.array,
	loadingPallets: PropTypes.bool,
	loadingProducts: PropTypes.bool,
	getPallets: PropTypes.func.isRequired,
	getProductsInfos: PropTypes.func.isRequired,
	onSelectPallet: PropTypes.func.isRequired,
	setPallet: PropTypes.func.isRequired,
	resetStore: PropTypes.func.isRequired,
};

PalletSelectionContainer.defaultProps = {
	pallets: [],
	loadingPallets: false,
	loadingProducts: false,
};

const mapActionsToProps = {
	getPallets,
	setPallet,
	resetStore,
	getProductsInfos,
};

const mapStateToProps = state => ({
	pallets: state.closePallets.pallets,
	loadingPallets: state.closePallets.loadingPallets,
	loadingProducts: state.closePallets.loadingProducts,
});

export default connect(mapStateToProps, mapActionsToProps)(PalletSelectionContainer);
