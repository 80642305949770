import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../styles';

class SelectModal extends Component {
	state = {
		selected: {},
	};

	handleSelect = selected => {
		const selectedPallet = this.props.pallets.find(pallet => pallet._id === selected.value);
		this.setState({ selected: selectedPallet });
	};

	handleChoose = () => {
		const { handleConfirm } = this.props;
		const { selected } = this.state;
		handleConfirm(selected);
	};

	render() {
		const { classes, pallets, loadingPallets, relative } = this.props;
		return (
			<Paper className={!relative && classes.modalRoot}>
				<Grid container direction="column">
					<Grid item xs>
						<Grid container justify="center">
							<Typography
								variant="h6"
								id="modal-title"
								className={classes.modalTitle}
							>
								<b>Escolha um pallet</b>
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs>
						{loadingPallets ? (
							<Grid
								className={classes.modalItemsContainer}
								container
								justify="center"
							>
								<CircularProgress size={50} />
							</Grid>
						) : (
							<Grid container direction="column" alignItems="center" spacing={24}>
								<Grid item className={classes.modalItemsContainer}>
									<Select
										placeholder="Escolha um pallet"
										options={pallets.map(pallet => ({
											value: pallet._id,
											label: pallet.name,
										}))}
										onChange={this.handleSelect}
									/>
								</Grid>
								<Grid item xs={5}>
									<Button onClick={this.handleChoose} className={classes.button}>
										<Typography
											variant="subtitle1"
											className={classes.buttonText}
										>
											Confirmar
										</Typography>
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}
SelectModal.propTypes = {
	classes: PropTypes.object.isRequired,
	label: PropTypes.object.isRequired,
	title: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	type: PropTypes.string.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	buttonMessage: PropTypes.object.isRequired,
	newRecipe: PropTypes.bool,
	step: PropTypes.number.isRequired,
	isLoading: PropTypes.bool,
	itemsLoading: PropTypes.bool,
};

SelectModal.defaultProps = {
	newRecipe: false,
	isLoading: false,
	itemsLoading: false,
};

export default withStyles(styles)(SelectModal);
