import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import UploadRoutesContainer from '../containers/UploadRoutesContainer';
import RoutesInfoContainer from '../containers/RoutesInfoContainer';

function TabContainer(props) {
	return <div style={{ padding: 20 }}>{props.children}</div>;
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

class RoutesTabs extends Component {
	state = {
		value: 0,
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { value } = this.state;

		return (
			<div>
				<AppBar position="static">
					<Tabs value={value} onChange={this.handleChange} centered>
						<Tab className="tab" label="Buscar Rotas" />
						<Tab className="tab" label="Salvar Rotas" />
					</Tabs>
				</AppBar>
				{value === 0 && (
					<TabContainer>
						<RoutesInfoContainer />
					</TabContainer>
				)}
				{value === 1 && (
					<TabContainer>
						<Grid container justify="center">
							<Grid item xs={6}>
								<UploadRoutesContainer switchTabs={this.handleChange} />
							</Grid>
						</Grid>
					</TabContainer>
				)}
			</div>
		);
	}
}

export default RoutesTabs;
