import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	root: {
		height: `50px`,
		width: 'fit-content',
		backgroundColor: theme.palette.common.white,
	},
	buttonText: {
		color: theme.palette.common.white,
		textTransform: 'none',
		fontSize: '16px',
	},
	button: {
		'&:hover': {
			backgroundColor: '#455a64',
		},
		borderRadius: 0,
		boxShadow:
			'0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
		backgroundColor: '#455a64',
		textAlign: 'center',
	},
});

const CalculateInventoryButton = ({ classes, onClick }) => (
	<Grid className={classes.root} container direction="row" justify="space-between">
		<Button onClick={onClick} className={classes.button}>
			<Typography className={classes.buttonText}>Calcular estoque atual</Typography>
		</Button>
	</Grid>
);

export default withStyles(styles)(CalculateInventoryButton);
