import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	paper: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		outline: 'none',
	},
	description: {
		marginBottom: '32px',
	},
});

class SimpleModal extends React.Component {
	render() {
		const { classes, open, handleClose, title, text, handleConfirm } = this.props;

		return (
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={open}
				onClose={handleClose}
			>
				<div
					style={{
						top: '50%',
						left: '50%',
						position: 'absolute',
						transform: 'translate(-50%, -50%)',
					}}
					className={classes.paper}
				>
					<Typography variant="h6" id="modal-title">
						Excluir Pallet
					</Typography>
					<Typography
						variant="subtitle1"
						id="simple-modal-description"
						className={classes.description}
					>
						Tem certeza que deseja excluir o pallet?
					</Typography>
					<Button
						variant="text"
						className={classes.deleteContainer}
						onClick={handleConfirm}
					>
						<Typography> Confirmar </Typography>
					</Button>
					<Button
						variant="text"
						className={classes.deleteContainer}
						onClick={handleClose}
					>
						<Typography> Cancelar </Typography>
					</Button>
				</div>
			</Modal>
		);
	}
}

SimpleModal.propTypes = {
	classes: PropTypes.object.isRequired,
};

const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;
