import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchDistributionCenters, selectDistributionCenter } from 'actions/DistributionCenters';
import { connect } from 'react-redux';
import { sendNotification, errorNotification } from 'actions/Notifications';
import { finish, get, calculateInventory } from 'actions/Counting';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import DcBox from '../components/DcBox';
import ProductBox from '../components/ProductBox';
import LoadingDcs from '../components/LoadingDcs';
import Totals from '../components/Totals';
import ButtonsFooter from '../components/ButtonsFooter';
import ConfirmModal from '../components/ConfirmModal';
import CalculateInventoryButton from '../components/CalculateInventoryButton';

const calculateTotalFromBarcodes = barcodes =>
	(barcodes && Object.keys(barcodes).reduce((acc, curr) => acc + Number(curr.slice(6, 9)), 0)) ||
	0;

const calculateTotalFromItems = items =>
	(items &&
		Object.keys(items).length > 0 &&
		Object.keys(items).reduce((acc, curr) => acc + items[curr].units, 0)) ||
	0;

const inventoryTypes = {
	chamber: 'Câmara fria | Freezers de estoque',
	picking: 'Freezers de Picking',
	snacks: 'Snacks',
};

const CountingFinishContainer = props => {
	const [step, setStep] = useState(0);
	const [dc, setDc] = useState();
	const [total, setTotal] = useState(0);
	const [inventoryType, setInventoryType] = useState();
	const [confirmModal, setConfirmModal] = useState(false);

	useEffect(() => {
		props.fetchDistributionCenters();
		return () => {};
	}, []);

	useEffect(() => {
		if (props.inventoryCount) {
			setTotal(
				calculateTotalFromBarcodes(props.inventoryCount.barcodes) +
					calculateTotalFromItems(props.inventoryCount.items)
			);
		}
		return () => {};
	}, [props.inventoryCount]);

	useEffect(() => {
		if (props.finishSuccess) {
			setTotal(0);
			if (dc.kind !== 'Warehouse') {
				setInventoryType();
				setStep(step - 1);
			}
		}
		return () => {};
	}, [props.finishSuccess]);

	useEffect(() => {
		if (
			props.distributionCenters &&
			props.distributionCenters.data &&
			props.distributionCenters.data.length > 0 &&
			localStorage.getItem('distributionCenter')
		) {
			const distributionCenter = props.distributionCenters.data.find(
				d => d._id === localStorage.getItem('distributionCenter')
			);
			if (distributionCenter.kind === 'Warehouse') {
				props.get({
					fromCenter: distributionCenter._id,
				});
				setStep(2);
			} else {
				setStep(1);
			}
			setDc(distributionCenter);
		}
		return () => {};
	}, [props.distributionCenters]);

	const handleClick = name => {
		const distributionCenter = props.distributionCenters.data.find(d => d.name === name);
		if (distributionCenter.kind === 'Warehouse') {
			setStep(2);
		} else {
			setStep(1);
		}
		props.selectDistributionCenter(
			distributionCenter._id,
			distributionCenter.address,
			distributionCenter.kind
		);
		setDc(distributionCenter);
	};

	const handleSelectType = type => {
		const t = Object.keys(inventoryTypes).find(key => inventoryTypes[key] === type);
		props.get({
			fromCenter: dc._id,
			inventoryType: t,
		});
		setInventoryType(t);
		setStep(2);
	};

	const handleBack = () => {
		if (dc.kind === 'Warehouse') {
			setStep(step - 2);
		} else {
			setStep(step - 1);
		}
		if (step - 1 === 1) {
			setInventoryType();
		}
		if (step - 1 === 0) {
			setDc();
		}
		setTotal(0);
	};

	const handleConfirmSave = () => {
		setConfirmModal(false);
		props.finish({
			_id: props.inventoryCount._id,
			total,
			distributionCenterName: dc.name,
			distributionCenterId: dc._id,
		});
	};

	const handleFinishClick = () => {
		setConfirmModal(true);
	};

	const handleClose = () => {
		setConfirmModal(false);
	};

	const handleCalculateInventoryClick = () => {
		props.calculateInventory({
			distributionCenterId: dc._id,
			distributionCenterName: dc.name,
		});
	};
	const renderDcs = () => (
		<Grid>
			{props.distributionCenters.loading ? (
				<LoadingDcs />
			) : (
				props.distributionCenters.data.map(dc => (
					<DcBox name={dc.name} onClick={handleClick} />
				))
			)}
		</Grid>
	);

	const renderInventoryType = () => (
		<Grid>
			{Object.values(inventoryTypes).map(type => (
				<DcBox name={type} onClick={handleSelectType} />
			))}
		</Grid>
	);

	const renderCounting = () => (
		<Grid container justify="center">
			{!props.getting ? (
				props.inventoryCount ? (
					<Fragment>
						<Totals total={total} />
					</Fragment>
				) : (
					<Fragment>
						<ProductBox product="Não há nenhuma checagem aberta" />
					</Fragment>
				)
			) : (
				<Grid container justify="center">
					<CircularProgress size={50} />
				</Grid>
			)}
		</Grid>
	);

	const renderButtonsFooter = () => (
		<ButtonsFooter
			finishing={props.finishing}
			onBack={handleBack}
			onFinish={handleFinishClick}
			disabled={!(step === 2 && total > 0)}
		/>
	);

	const renderConfirmModal = () => (
		<ConfirmModal open={confirmModal} onClick={handleConfirmSave} onClose={handleClose} />
	);

	const subTitleMessage = `${dc && dc.name}`;

	const inventoryTypeMessage = `${(inventoryType && `${inventoryTypes[inventoryType]}`) || ''}`;
	return (
		<Grid container direction="column">
			<Title />
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="center"
				style={{ marginBottom: '24px' }}
			>
				<SubTitle>
					{(dc && subTitleMessage) || 'Escolha o local onde deseja começar'}
				</SubTitle>
				{/* {step !== 0 && (
					<div style={{ display: 'flex', justifyContent: 'center', width: '175px' }}>
						{props.calculatingInventory ? (
							<CircularProgress />
						) : (
							<CalculateInventoryButton onClick={handleCalculateInventoryClick} />
						)}
					</div>
				)} */}
			</Grid>
			{inventoryTypeMessage && (
				<SubTitle style={{ marginBottom: '16px' }}>{inventoryTypeMessage}</SubTitle>
			)}
			{step === 0 && renderDcs()}
			{step === 1 && renderInventoryType()}
			{step === 2 && renderCounting()}
			{step !== 0 && renderButtonsFooter()}
			{step === 2 && renderConfirmModal()}
		</Grid>
	);
};

const mapStateToProps = state => ({
	distributionCenters: state.distributionCenters,
	getting: state.counting.getting,
	getError: state.counting.getError,
	getSuccess: state.counting.getSuccess,
	inventoryCount: state.counting.inventoryCount,
	finishing: state.counting.finishing,
	finishError: state.counting.finishError,
	finishSuccess: state.counting.finishSuccess,
	calculatingInventory: state.counting.calculatingInventory,
	calculateInventoryError: state.counting.calculateInventoryError,
	calculateInventorySuccess: state.counting.calculateInventorySuccess,
});

CountingFinishContainer.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.bool,
	products: PropTypes.array,
	sendNotification: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
	errorClear: PropTypes.func.isRequired,
};

CountingFinishContainer.defaultProps = {
	loading: false,
	error: false,
	products: [],
};

export default connect(mapStateToProps, {
	selectDistributionCenter,
	get,
	finish,
	fetchDistributionCenters,
	sendNotification,
	errorNotification,
	calculateInventory,
})(CountingFinishContainer);
