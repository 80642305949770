import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';

import DCSelect from 'containers/DCSelect';
import PeriodSelect from 'containers/PeriodSelect';
import RouteTypeSelect from 'containers/RouteTypeSelect';
import { moveArrayElementUp, moveArrayElementDown } from 'utils/arrayUtils';

import {
	getRoutes,
	changePackagesOrder,
	deletePackage,
	addPackage,
	toggleReturnPoint,
	callLoggi,
	deleteRoute,
	simulateRoute,
	getAvailablePeriods,
	resetRepeatedRoutes,
	changeRouteType,
} from 'actions/DeliveryRoutes';

import RoutesSelect from '../components/RoutesSelect';
import PackagesList from '../components/PackagesList';
import RoutesDateSelect from '../components/RoutesDateSelect';
import RouteMap from '../components/RouteMap';
import RouteDetail from '../components/RouteDetail';
import RoutesStatus from '../components/RoutesStatus';
import NewPackageDialog from '../components/NewPackageDialog';

import styles from '../styles.css';

class RoutesInfoContainer extends Component {
	constructor(props) {
		super(props);

		let selectedDate = moment().format('YYYY-MM-DD');
		if (props.routesContainer && props.routesContainer.data.length > 0) {
			selectedDate = props.routesContainer.data[0].date;
		}

		this.state = {
			selectedRouteId: null,
			selectedDate,
			productDialogOpen: false,
		};
	}

	componentDidMount() {
		this.updateRoutesList();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.distributionCenter !== this.props.distributionCenter ||
			prevState.selectedDate !== this.state.selectedDate
		) {
			this.updateRoutesList();
		}
	}

	getPackagesOrderArray() {
		const { routesContainer } = this.props;
		const { selectedRouteId } = this.state;
		if (!routesContainer || !routesContainer.data || routesContainer.data.length === 0)
			return [];

		if (!selectedRouteId) {
			return [];
		}

		const selectedRoute = routesContainer.data.find(route => route._id === selectedRouteId);
		if (!selectedRoute) {
			return [];
		}

		return selectedRoute.packages.map(pack => pack.order);
	}

	handleSelectDate = date => {
		this.setState({ selectedDate: date });
	};

	handleSelectRoute = routeId => {
		this.setState({ selectedRouteId: routeId });
	};

	handleChangePackagePosition = (order, direction) => {
		const packagesOrder = this.getPackagesOrderArray();
		const index = packagesOrder.indexOf(order);
		let newOrder;
		if (index === -1) return;
		if (direction === 1) {
			newOrder = moveArrayElementUp(packagesOrder, index);
		} else if (direction === -1) {
			newOrder = moveArrayElementDown(packagesOrder, index);
		}
		if (newOrder) {
			const { selectedRouteId } = this.state;
			this.props.changePackagesOrder({
				deliveryRouteId: selectedRouteId,
				packagesOrder: newOrder,
			});
		}
	};

	handleDeletePackage = order => {
		const { selectedRouteId } = this.state;
		this.props.deletePackage({
			deliveryRouteId: selectedRouteId,
			order,
		});
	};

	handleOpenNewPackageDialog = () => {
		this.setState({
			productDialogOpen: true,
		});
	};

	handleCloseNewPackageDialog = () => {
		this.setState({
			productDialogOpen: false,
		});
	};

	handleAddNewPackage = ({ order, callback, force }) => {
		const { selectedRouteId } = this.state;
		this.props.addPackage({
			deliveryRouteId: selectedRouteId,
			order,
			callback,
			force: force || false,
		});
	};

	updateRoutesList = () => {
		const { selectedDate } = this.state;
		this.setState({ selectedRouteId: null });
		this.props.getRoutes(selectedDate);
	};

	formatRepeatedRoutesString() {
		let formatedString = '';
		this.props.repeatedRoutesContainer.data.forEach(element => {
			formatedString = `${formatedString} ${element.number}-${element.period.name}, `;
		});
		return formatedString;
	}

	renderLoading() {
		const { routesContainer } = this.props;
		if (routesContainer.loading)
			return (
				<Grid item xs={12}>
					<CircularProgress size={50} />
				</Grid>
			);
		return null;
	}

	renderNoRoutesToday() {
		const { routesContainer, selectedPeriodId } = this.props;
		if (
			!routesContainer.error &&
			!routesContainer.loading &&
			routesContainer.data.length === 0 &&
			selectedPeriodId
		)
			return (
				<Grid item xs={12}>
					Nenhuma rota salva nesta data
				</Grid>
			);
		return null;
	}

	renderCheckRepeatedRoute() {
		if (
			this.props.repeatedRoutesContainer &&
			this.props.repeatedRoutesContainer.data &&
			this.props.repeatedRoutesContainer.data.length > 0
		) {
			return (
				<Dialog open>
					<DialogTitle id="alert-dialog-title">Por favor, confirme sua ação.</DialogTitle>
					<DialogContentText className={styles.checkDialogText}>
						{`O pedido #${
							this.props.repeatedRoutesContainer.order
						} está presente também nas rotas: ${this.formatRepeatedRoutesString()}. Tem certeza que quer adicionar o pedido novamente?`}
					</DialogContentText>
					<DialogActions>
						<Button
							onClick={() => {
								this.handleAddNewPackage({
									order: this.props.repeatedRoutesContainer.order,
									force: true,
								});
								this.props.resetRepeatedRoutes();
							}}
						>
							Sim
						</Button>
						<Button onClick={this.props.resetRepeatedRoutes}>Cancelar</Button>
					</DialogActions>
				</Dialog>
			);
		}
		return null;
	}

	renderDateSelect() {
		const { selectedDate } = this.state;
		return <RoutesDateSelect onSelect={this.handleSelectDate} selectedDate={selectedDate} />;
	}

	renderPeriodSelect() {
		return <PeriodSelect onChange={this.updateRoutesList} />;
	}

	renderRouteTypeSelect() {
		return <RouteTypeSelect onChange={this.updateRoutesList} />;
	}

	renderRoutesSelect() {
		const { routesContainer } = this.props;
		const { selectedRouteId } = this.state;

		if (routesContainer.data && routesContainer.data.length > 0) {
			return (
				<RoutesSelect
					routes={routesContainer.data}
					onSelect={this.handleSelectRoute}
					selectedRoute={selectedRouteId || ''}
				/>
			);
		}
		return null;
	}

	renderNewPackageDialog() {
		return (
			<NewPackageDialog
				onAdd={this.handleAddNewPackage}
				onClose={this.handleCloseNewPackageDialog}
				open={this.state.productDialogOpen}
			/>
		);
	}

	renderRoutePackages() {
		const { routesContainer, loggi } = this.props;
		const { selectedRouteId } = this.state;
		const selectedRoute = routesContainer.data.find(route => route._id === selectedRouteId);
		if (!routesContainer.error && routesContainer.data.length !== 0 && selectedRoute) {
			return (
				<Grid item>
					<PackagesList
						route={selectedRoute}
						onChangePackagePosition={this.handleChangePackagePosition}
						onDeletePackage={this.handleDeletePackage}
					/>
					<Button
						fullWidth
						variant="outlined"
						color="default"
						className="jr-btn-xs text-blue-gray"
						onClick={this.handleOpenNewPackageDialog}
					>
						<Add className={styles.addPackage} />
						Adicionar pedido
					</Button>
				</Grid>
			);
		}
	}

	renderRouteMap() {
		const { routesContainer } = this.props;
		const { selectedRouteId } = this.state;
		const selectedRoute = routesContainer.data.find(route => route._id === selectedRouteId);
		if (!routesContainer.error && routesContainer.data.length !== 0 && selectedRoute) {
			return <RouteMap cdLocation={this.props.cdLocation} route={selectedRoute} />;
		}
	}

	renderRouteDetails() {
		const { routesContainer, loggi } = this.props;
		const { selectedRouteId } = this.state;
		const selectedRoute = routesContainer.data.find(route => route._id === selectedRouteId);
		if (!routesContainer.error && routesContainer.data.length !== 0 && selectedRoute) {
			return (
				<RouteDetail
					route={selectedRoute}
					loggiLoading={loggi.loading}
					simulateLoading={this.props.simulate.loading}
					extraReturnLoading={this.props.extraReturn.loading}
					onCallLoggi={this.props.callLoggi}
					onDelete={this.props.deleteRoute}
					onSimulate={this.props.simulateRoute}
					onCheckReturnPoint={this.props.toggleReturnPoint}
					onChangeRouteType={this.props.changeRouteType}
				/>
			);
		}
	}

	renderRoutesStatus() {
		const { periods, routesContainer, selectedPeriodId } = this.props;
		const { selectedRouteId } = this.state;

		const hasRoutes =
			routesContainer && routesContainer.data && routesContainer.data.length > 0;
		const hasPeriod = periods && selectedPeriodId;

		if (hasRoutes && hasPeriod) {
			return (
				<RoutesStatus
					routes={routesContainer.data}
					date={this.state.selectedDate}
					period={periods.find(period => period.id === selectedPeriodId)}
				/>
			);
		}
		return null;
	}

	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					<Grid container direction="row" spacing={16}>
						<Grid item xs={3}>
							<DCSelect />
						</Grid>
						<Grid item xs={3}>
							{this.renderDateSelect()}
						</Grid>
						<Grid item xs={3}>
							{this.renderPeriodSelect()}
						</Grid>
						<Grid item xs={3}>
							{this.renderRouteTypeSelect()}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={16}>
						<Grid item xs={12}>
							{this.renderRoutesStatus()}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={16}>
						<Grid item xs={2}>
							{this.renderRoutesSelect()}
						</Grid>
						<Grid item xs={10}>
							<Grid container direction="column" spacing={16}>
								<Grid item>{this.renderRouteDetails()}</Grid>
								<Grid item>
									<Grid container direction="row">
										<Grid item xs={6}>
											{this.renderRoutePackages()}
										</Grid>
										<Grid item xs={6}>
											{this.renderRouteMap()}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{this.renderCheckRepeatedRoute()}
				{this.renderLoading()}
				{this.renderNoRoutesToday()}
				{this.renderNewPackageDialog()}
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	routesContainer: state.deliveryRoutes.routesContainer,
	repeatedRoutesContainer: state.deliveryRoutes.repeatedRoutesContainer,
	periods: state.periods.available,
	selectedPeriodId: state.periods.selected,
	loggi: {
		loading: state.deliveryRoutes.loggi.loading,
	},
	simulate: {
		loading: state.deliveryRoutes.simulate.loading,
	},
	extraReturn: {
		loading: state.deliveryRoutes.extraReturn.loading,
	},
	cdLocation: state.distributionCenters.selectedAddress,
	distributionCenter: state.distributionCenters.selected,
});

RoutesInfoContainer.propTypes = {
	cdLocation: PropTypes.object,
	routesContainer: PropTypes.shape({
		data: PropTypes.array,
		error: PropTypes.string,
		loading: PropTypes.bool,
	}),
	periods: PropTypes.array,
	loggi: PropTypes.shape({
		loading: PropTypes.bool,
	}),
	simulate: PropTypes.shape({
		loading: PropTypes.bool,
	}),
	extraReturn: PropTypes.shape({
		loading: PropTypes.bool,
	}),
	repeatedRoutesContainer: PropTypes.shape({
		data: PropTypes.array,
		order: PropTypes.number,
		error: PropTypes.string,
		loading: PropTypes.bool,
	}),
	distributionCenter: PropTypes.string,
	// Actions
	getRoutes: PropTypes.func.isRequired,
	changePackagesOrder: PropTypes.func.isRequired,
	deletePackage: PropTypes.func.isRequired,
	addPackage: PropTypes.func.isRequired,
	toggleReturnPoint: PropTypes.func.isRequired,
	deleteRoute: PropTypes.func.isRequired,
	callLoggi: PropTypes.func.isRequired,
	simulateRoute: PropTypes.func.isRequired,
	resetRepeatedRoutes: PropTypes.func.isRequired,
	changeRouteType: PropTypes.func.isRequired,
};

RoutesInfoContainer.defaultProps = {
	routesContainer: {
		data: [],
		error: null,
		loading: false,
		order: null,
	},
	repeatedRoutesContainer: {
		data: [],
		error: null,
		loading: false,
	},
	loggi: {
		loading: false,
	},
	simulate: {
		loading: false,
	},
	periods: {},
	distributionCenter: '',
};

export default connect(
	mapStateToProps,
	{
		getRoutes,
		changePackagesOrder,
		deletePackage,
		addPackage,
		toggleReturnPoint,
		callLoggi,
		deleteRoute,
		simulateRoute,
		getAvailablePeriods,
		resetRepeatedRoutes,
		changeRouteType,
	}
)(RoutesInfoContainer);
