import {
	TRANSFER_CHECK_FETCH_INVENTORY_ORDER_REQUEST,
	TRANSFER_CHECK_FETCH_INVENTORY_ORDER_RESPONSE,
	TRANSFER_CHECK_FETCH_INVENTORY_ORDER_ERROR,
	TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_REQUEST,
	TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_RESPONSE,
	TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_ERROR,
	TRANSFER_CHECK_FINISH_REQUEST,
	TRANSFER_CHECK_FINISH_RESPONSE,
	TRANSFER_CHECK_FINISH_ERROR,
	TRANSFER_CHECK_STORE_RESET,
	TRANSFER_CHECK_FETCH_DC_ORDERS_RESPONSE,
	TRANSFER_CHECK_FETCH_DC_ORDERS_REQUEST,
	TRANSFER_CHECK_FETCH_DC_ORDERS_ERROR,
	TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_REQUEST,
	TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_RESPONSE,
	TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_ERROR,
	TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_REQUEST,
	TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_RESPONSE,
	TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_ERROR,
} from 'constants/ActionTypes';

const removeArrivedOrders = ({ orders }, { payload }) =>
	'number' in payload ? orders.filter(({ number }) => number !== payload.number) : orders;

const addPackageToReceivedOrders = ({ receivedOrders, packageToBeAdded }) => {
	const index = receivedOrders.transferInItems.findIndex(item => item.sku === packageToBeAdded.sku);
	if (index !== -1) {
		receivedOrders.transferInItems[index].quantity += packageToBeAdded.quantity;
		receivedOrders.transferInItems[index].batch = packageToBeAdded.batch;
	} else {
		receivedOrders.transferInItems.push(packageToBeAdded)
	}
	return {
		...receivedOrders,
		totalQuantityReceived: receivedOrders.totalQuantityReceived + packageToBeAdded.quantity,
		receivedBarcodes: packageToBeAdded.barcode ? [...receivedOrders.receivedBarcodes, packageToBeAdded.barcode] : receivedOrders.receivedBarcodes,
	};
}

const initialState = {
	products: {},
	loading: false,
	error: null,
	success: false,
	receivedOrders: null,
	order: null,
	packageToBeAdded: null,
	waitingConfirmation: false,
	waitingFinish: false,
	done: false,
	orders: [],
	ordersLoading: false,
};

const TransferCheck = (state = initialState, action) => {
	switch (action.type) {
		case TRANSFER_CHECK_FETCH_INVENTORY_ORDER_REQUEST:
			return {
				...state,
				loading: true,
				done: false,
				error: null,
			};
		case TRANSFER_CHECK_FETCH_INVENTORY_ORDER_RESPONSE:
			return {
				...state,
				order: action.payload,
				done: false,
				loading: false,
				error: null,
			};
		case TRANSFER_CHECK_FETCH_INVENTORY_ORDER_ERROR:
			return {
				...state,
				loading: false,
				done: false,
				error: action.payload.data,
			};
		case TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_REQUEST:
			return {
				...state,
				waitingConfirmation: true,
				packageToBeAdded: action.payload,
				done: false,
				error: null,
			};
		case TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_RESPONSE:
			return {
				...state,
				receivedOrders: addPackageToReceivedOrders(state),
				waitingConfirmation: false,
				done: false,
				error: null,
			};
		case TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_ERROR:
			return {
				...state,
				waitingConfirmation: false,
				done: false,
				error: action.payload.data,
			};
		case TRANSFER_CHECK_FINISH_REQUEST:
			return {
				...state,
				waitingFinish: true,
				done: false,
				error: null,
			};
		case TRANSFER_CHECK_FINISH_RESPONSE:
			return {
				...state,
				receivedOrders: null,
				waitingFinish: false,
				done: true,
				error: null,
			};
		case TRANSFER_CHECK_FINISH_ERROR:
			return {
				...state,
				waitingFinish: false,
				done: false,
				error: action.payload.data,
			};
		case TRANSFER_CHECK_STORE_RESET:
			return {
				...state,
				loading: false,
				error: null,
				success: false,
				order: null,
				waitingConfirmation: false,
				done: false,
			};
		case TRANSFER_CHECK_FETCH_DC_ORDERS_REQUEST:
			return {
				...state,
				orders: [],
				ordersLoading: true,
				error: null,
			};
		case TRANSFER_CHECK_FETCH_DC_ORDERS_RESPONSE:
			return {
				...state,
				orders: action.payload,
				ordersLoading: false,
			};
		case TRANSFER_CHECK_FETCH_DC_ORDERS_ERROR:
			return {
				...state,
				orders: [],
				ordersLoading: false,
				error: action.payload,
			};
		case TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_REQUEST:
			return {
				...state,
				receivedOrders: null,
				loading: true,
				error: null,
			};
		case TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_RESPONSE:
			return {
				...state,
				receivedOrders: action.payload,
				loading: false,
			};
		case TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_ERROR:
			return {
				...state,
				receivedOrders: null,
				loading: false,
				error: action.payload,
			};
		case TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_REQUEST:
			return {
				...state,
				ordersLoading: true,
				error: null,
			};
		case TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_RESPONSE:
			return {
				...state,
				orders: removeArrivedOrders(state, action),
				ordersLoading: false,
			};
		case TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_ERROR:
			return {
				...state,
				ordersLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default TransferCheck;
