import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DcName from './DcName';

const styles = theme => ({
	root: {
		marginBottom: '8px',
	},
});

const DcBoxInfo = ({ name, classes, onClick }) => {
	const handleClick = () => {
		onClick(name);
	};
	return (
		<Paper className={classes.root} onClick={handleClick}>
			<DcName name={name} />
		</Paper>
	);
};

DcBoxInfo.propTypes = {
	box: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DcBoxInfo);
