import { sendNotification } from './Notifications.js';
import { handleRequestError } from './ErrorHandling.js';
import {
  GREEN_GROCER_POST_CONTAINER_RESPONSE,
  GREEN_GROCER_POST_CONTAINER_REQUEST,
  GREEN_GROCER_POST_CONTAINER_ERROR,
  GREEN_GROCER_RESET_CONTAINER_STATE,
  GREEN_GROCER_POST_CONTAINER_CHECK,
} from '../constants/ActionTypes';

export const postContainer = (packageCodes, containerCode, force) => async (
  dispatch,
  _getState,
  api
) => {
  try {
    dispatch({
      type: GREEN_GROCER_POST_CONTAINER_REQUEST,
    });
    const response = await api.post('wms/greenGrocer/container', {
      packageCodes,
      containerCode,
      force,
    });
    if (response.data.error) {
      dispatch(
        sendNotification({
          type: 'error',
          message: response.data.error,
        })
      );
    } else if (!response.data.repeated) {
      dispatch(
        sendNotification({
          type: 'success',
          message: 'Adicionado!',
        })
      );
      dispatch({
        type: GREEN_GROCER_POST_CONTAINER_RESPONSE,
        payload: {
          data: response.data,
        },
      });
    } else
      dispatch({
        type: GREEN_GROCER_POST_CONTAINER_CHECK,
        payload: {
          data: response.data.message,
        },
      });
  } catch (error) {
    dispatch(handleRequestError(error));
    dispatch({
      type: GREEN_GROCER_POST_CONTAINER_ERROR,
      payload: {
        error,
      },
    });
  }
};

export const resetRedux = () => async (dispatch, _getState, _api) => {
  dispatch({
    type: GREEN_GROCER_RESET_CONTAINER_STATE,
  });
};
