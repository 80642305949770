import React from 'react';
import { PropTypes } from 'prop-types';
import ProductTypeSelector from './components/ProductTypeSelector';

class RegisterPack extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ProductTypeSelector location={this.props.location} />
			</div>
		);
	}
}

RegisterPack.propTypes = {
	location: PropTypes.object.isRequired,
};

export default RegisterPack;
