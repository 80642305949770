import React, { Fragment } from 'react';
import { compose } from 'redux';
import { CircularProgress, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PropTypes } from 'prop-types';
import { showFooter } from 'actions/Setting';
import { connect } from 'react-redux';

import { getPallets, addBoxToPallet, resetInventoryInputStore } from 'actions/InventoryInput';
import { getProductsInfos } from 'actions/ProductsInfo';
import { sendNotification, errorNotification } from 'actions/Notifications';
import styles from '../styles';
import Pallets from '../components/Pallets';
import Pallet from '../components/Pallet';
import BoxInfoCards from '../components/BoxInfoCards';
import PseudoBarCodeInput from '../components/PseudoBarCodeInput';

import { checkUsingRegex } from 'components/BarCodeInput';
import { PACKAGE_BARCODE_REGEX } from 'constants/Regexes';
import {
	WRONG_PALLET,
	WRONG_BATCH,
	FULL_PALLET,
	WRONG_BOX,
	PRODUCT_NOT_FOUND,
} from '../constants/Errors';
import { PageTitle } from '../../ClosePallet/components/PageTitle';
import { PaperHeader } from '../../ClosePallet/components/Header';
import { FooterButton } from '../../ClosePallet/components/FooterButton';

const SELECTED_PALLET = 'Pallet selecionado';
const NEW_PALLET = 'Criar novo pallet';

class InventoryInputContainer extends React.Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.boxAdded) {
			const index = nextProps.pallets.findIndex(p =>
				p.items.some(item => item.sku === prevState.lastAddedSku)
			);
			const pallet = nextProps.pallets.find(p =>
				p.items.some(item => item.sku === prevState.lastAddedSku)
			);
			nextProps.resetInventoryInputStore();
			return {
				...prevState,
				pallet: {
					...pallet,
					index,
				},
				lastAddedSku: '',
				pendingConfirmationBox: null,
				busyScanner: false,
				error: false,
			};
		}
		if (nextProps.error) {
			nextProps.resetInventoryInputStore();
			return {
				...prevState,
				error: true,
				lastAddedSku: '',
				pendingConfirmationBox: null,
				busyScanner: false,
			};
		}
		return prevState;
	}

	constructor(props) {
		super(props);
		this.state = {
			step: 0,
			pallet: { items: [] },
			lastAddedSku: '',
			busyScanner: false,
			error: false,
			pendingConfirmationBox: null,
		};
	}

	componentDidMount() {
		this.props.getProductsInfos();
		this.props.getPallets();
		this.props.showFooter(false);
	}

	componentWillUnmount() {
		this.props.showFooter(true);
	}

	handleClickNewPallet = () => {
		this.setState(prevState => ({ ...prevState, step: 1, pallet: { items: [] } }));
	};

	handleSelectPallet = pallet => {
		this.setState(prevState => ({ ...prevState, step: 1, pallet, busyScanner: false }));
	};

	palletAlreadyHasTwoOtherProducts = (pallet, sku) =>
		pallet &&
		pallet.items &&
		pallet.items.length > 1 &&
		!pallet.items.some(item => item.sku === sku);

	getProductBatchFromBoxCode = boxCode =>
		`${boxCode.slice(9, 13)}-${boxCode.slice(13, 15)}-${boxCode.slice(15, 17)}`;

	anotherOpenPalletWithProduct = (pallet, sku) =>
		this.props.pallets.some(
			p => p._id !== pallet._id && p.items.some(palletItem => palletItem.sku === sku)
		);

	palletHasProduct = (pallet, sku) => pallet.items.some(item => item.sku === sku);

	validBatch = (pallet, sku, batch) =>
		!this.palletHasProduct(pallet, sku) ||
		pallet.items.find(item => item.sku === sku).batch === batch;

	validateScannedCode = (pallet, sku, product, batch) => {
		if (!product) {
			this.props.errorNotification({
				message: PRODUCT_NOT_FOUND,
			});
			this.setState({ error: true });
			return false;
		}

		if (this.anotherOpenPalletWithProduct(pallet, sku)) {
			this.props.errorNotification({
				message: WRONG_PALLET,
			});
			this.setState({ error: true });
			return false;
		}

		if (this.palletAlreadyHasTwoOtherProducts(pallet, sku)) {
			this.props.errorNotification({
				message: FULL_PALLET,
			});
			this.setState({ error: true });
			return false;
		}

		if (!this.validBatch(pallet, sku, batch)) {
			this.props.errorNotification({
				message: WRONG_BATCH,
			});
			this.setState({ error: true });
			return false;
		}
		return true;
	};

	addBoxToPallet = boxCode => {
		const { pallet, pendingConfirmationBox } = this.state;
		const sku = boxCode.slice(2, 6);
		const quantity = boxCode.slice(6, 9);
		const batch = this.getProductBatchFromBoxCode(boxCode);
		const product = this.props.products[sku];

		if (pendingConfirmationBox) {
			if (pendingConfirmationBox.code === boxCode) {
				this.setState(prevState => ({
					...prevState,
					error: false,
					lastAddedSku: sku,
				}));
				this.props.addBoxToPallet(pallet._id, boxCode);
			} else {
				this.props.errorNotification({
					message: WRONG_BOX,
				});
			}
		} else if (this.validateScannedCode(pallet, sku, product, batch)) {
			const { name, productsPerBox, expiration } = product;
			this.setState(prevState => ({
				...prevState,
				error: false,
				pendingConfirmationBox: {
					sku,
					name,
					quantity,
					productsPerBox,
					batch,
					expiration,
					code: boxCode,
				},
			}));
		}
	};

	back = () => {
		this.props.getPallets();
		this.setState({
			step: 0,
			pallet: { items: [] },
			lastAddedSku: '',
			error: false,
			pendingConfirmationBox: null,
			busyScanner: false,
		});
	};

	handleBarCodeInputError = error => {
		if (error === 'busy') {
			this.props.errorNotification({
				message: 'Erro na leitura. Espere alguns instantes entre as leituras.',
			});
		} else if (error === 'invalid') {
			this.props.errorNotification({
				message: 'Código inválido',
			});
		}
	};

	handleCheckValid = value => {
		if (!this.state.busyScanner && checkUsingRegex(value, PACKAGE_BARCODE_REGEX)) {
			this.setState({
				busyScanner: true,
			});
			setTimeout(() => {
				this.setState({
					busyScanner: false,
				});
			}, 1000);
			return true;
		}
		return false;
	};

	handleRegisterSubmission = value => {
		if (checkUsingRegex(value, PACKAGE_BARCODE_REGEX)) {
			this.addBoxToPallet(value);
		}
	};

	renderInitialPage() {
		const { pallets } = this.props;
		return (
			<Grid container direction="column">
				<PaperHeader text="Lista de pallets abertos" type="primary" />
				<Pallets pallets={pallets} handleClick={this.handleSelectPallet} />
				<FooterButton text="Criar pallet" onClick={this.handleClickNewPallet} />
			</Grid>
		);
	}

	renderInputPage() {
		const { pallet, pendingConfirmationBox, error } = this.state;
		const { addingBoxToPallet, classes } = this.props;
		return (
			<Grid>
				<Typography className={classes.palletListTitle}>
					{pallet.items.length > 0 ? SELECTED_PALLET : NEW_PALLET}
				</Typography>
				<Pallet pallet={pallet} index={pallet.index} ellipsis />
				<BoxInfoCards
					box={pendingConfirmationBox}
					addingBoxToPallet={addingBoxToPallet}
					error={error}
				/>
				<FooterButton text="Voltar" onClick={this.back} />
				<div className={classes.barCodeInputContainer}>
					<PseudoBarCodeInput
						onMatch={this.handleRegisterSubmission}
						checkValid={this.handleCheckValid}
						onError={this.handleBarCodeInputError}
						busy={this.state.busyScanner}
						keepFocus
						debounce
					/>
				</div>
			</Grid>
		);
	}

	render() {
		const { loadingProducts, loadingPallets, classes } = this.props;
		return (
			<div>
				<PageTitle title="Entrada de Estoque" />
				{loadingProducts || loadingPallets ? (
					<div className={classes.loadingContainer}>
						<CircularProgress size={50} />
					</div>
				) : (
					<div>
						{this.state.step === 0 && this.renderInitialPage()}
						{this.state.step === 1 && this.renderInputPage()}
					</div>
				)}
			</div>
		);
	}
}

InventoryInputContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	getPallets: PropTypes.func.isRequired,
	pallets: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	addingBoxToPallet: PropTypes.bool.isRequired,
	loadingProducts: PropTypes.bool.isRequired,
	loadingPallets: PropTypes.bool.isRequired,
	addBoxToPallet: PropTypes.func.isRequired,
	sendNotification: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
	showFooter: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	getPallets,
	addBoxToPallet,
	sendNotification,
	errorNotification,
	getProductsInfos,
	showFooter,
	resetInventoryInputStore,
};

const mapStateToProps = state => ({
	pallets: state.inventoryInput.pallets,
	loadingPallets: state.inventoryInput.loadingPallets,
	loadingProducts: state.inventoryInput.loadingProducts,
	addingBoxToPallet: state.inventoryInput.addingBoxToPallet,
	boxAdded: state.inventoryInput.boxAdded,
	products: state.inventoryInput.products,
	pallet: state.inventoryInput.pallet,
	error: state.inventoryInput.error,
});

export default compose(withStyles(styles), connect(mapStateToProps, mapActionsToProps))(
	InventoryInputContainer
);
