import React from 'react';
import PropTypes from 'prop-types';

import AlertDialog from 'components/AlertDialog';
import Input from '@material-ui/core/Input';

class NewPackageDialog extends React.Component {
	constructor() {
		super();
		this.state = {
			order: '',
		};
	}

	handleOrderChange = e => {
		this.setState({ order: e.target.value });
	};

	handleFormSubmit = e => {
		e.preventDefault();
		this.props.onAdd({
			order: Number(this.state.order),
			callback: this.props.onClose,
		});
		this.setState({ order: '' });
	};

	canSubmit() {
		const { order } = this.state;
		const orderNum = Number(order);

		return Number.isNaN(orderNum) || Number(orderNum) <= 0;
	}

	render() {
		const { open, onClose } = this.props;

		return (
			<AlertDialog
				title="Adicionar pedido"
				open={open}
				onClose={onClose}
				actions={[
					{
						title: 'Cancelar',
						onClick: this.props.onClose,
						type: 'default',
					},
					{
						title: 'Adicionar',
						onClick: this.handleFormSubmit,
						type: 'primary',
						submit: true,
						disabled: this.canSubmit(),
					},
				]}
			>
				<form onSubmit={this.handleFormSubmit}>
					<span>Digite o número do pedido.</span>
					<br />
					<Input
						type="number"
						value={this.state.order}
						onChange={this.handleOrderChange}
						autoFocus
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</form>
			</AlertDialog>
		);
	}
}

NewPackageDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
};

NewPackageDialog.defaultProps = {
	open: false,
};

export default NewPackageDialog;
