/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { fromEvent } from 'rxjs';
import { scan, map, debounceTime } from 'rxjs/operators';
import propTypes from 'prop-types';


function useScanner() {
	const lastScannedValue = useRef('');
	const [scannedValue, setScannedValue] = useState('');

	const handlePaste = ({ clipboardData }) => {
		const barcode = clipboardData.getData('Text')
		setScannedValue(barcode)
		return barcode
	}

	useEffect(() => {
		window.addEventListener('paste', handlePaste);
		const obs = fromEvent(window, 'keypress')
			.pipe(
				map(({ keyCode }) => String.fromCharCode(keyCode)),
				scan((acc, cur) => `${acc}${cur}`, ''),
				debounceTime(70),
				map(value => {
					const newValue = value.replace(lastScannedValue.current, '');
					lastScannedValue.current = value;
					return newValue;
				})
			)
			.subscribe(setScannedValue);
		return () => {
			lastScannedValue.current = '';
			obs.unsubscribe();
			window.removeEventListener('paste', handlePaste);
		};
	});
	function clearScannedValue() {
		setScannedValue('');
	}
	return [scannedValue, clearScannedValue];
}

const ScannerInput = props => {
	const { onChange, render } = props;
	const [value, clearValue] = useScanner();
	useEffect(() => {
		if (onChange) {
			onChange(value);
		}
	}, [value]);

	return render({ value, clearValue });
};

ScannerInput.propTypes = {
	onChange: propTypes.func,
	render: propTypes.func.isRequired,
};

ScannerInput.defaultProps = {
	onChange: () => {},
	render: () => <React.Fragment></React.Fragment>,
};

export default ScannerInput;
