import {
	RUN_ROUTER_REQUEST,
	RUN_ROUTER_RESPONSE,
	RUN_ROUTER_ERROR,
	FETCH_ROUTER_STATUS_REQUEST,
	FETCH_ROUTER_STATUS_RESPONSE,
	FETCH_ROUTER_STATUS_ERROR,
} from '../constants/ActionTypes';

const initialState = {
	loading: false,
	running: false,
	taskId: null,
	taskStatus: {},
};

const routingAPI = (state = initialState, action) => {
	switch (action.type) {
		case RUN_ROUTER_REQUEST:
			return {
				...state,
				loading: true,
				running: false,
				taskId: null,
				taskStatus: {},
			};
		case RUN_ROUTER_RESPONSE:
			return {
				...state,
				loading: false,
				running: true,
				taskId: action.payload.task_id,
			};
		case RUN_ROUTER_ERROR:
			return {
				...state,
				loading: false,
				running: false,
			};
		case FETCH_ROUTER_STATUS_RESPONSE:
			return {
				...state,
				taskStatus: {
					isFailed: action.payload.is_failed,
					isFinished: action.payload.is_finished,
					progress: action.payload.progress,
				},
				running: !(action.payload.is_failed || action.payload.is_finished),
			};
		case FETCH_ROUTER_STATUS_ERROR:
			return {
				...state,
				loading: false,
				success: false,
			};
		default:
			return state;
	}
};

export default routingAPI;
