import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { debounce } from 'utils/timingFunctions';
import { PropTypes } from 'prop-types';

const DEBOUNCE_TIME_MILISEC = 100;
const REFOCUS_TIME_MILISEC = 500;

class BarCodeInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barCode: '',
		};
		this.focusTimer = null;
		this.barcodeInput = null;
		this.foundValid = null;
	}

	componentDidMount() {
		const { barcodeInput } = this;
		if (this.props.keepFocus) {
			this.focusTimer = setInterval(() => {
				if (barcodeInput) {
					barcodeInput.focus();
				}
			}, REFOCUS_TIME_MILISEC);
		}
	}

	componentWillUnmount() {
		this.setState({ barCode: '' });
		if (this.focusTimer) {
			clearInterval(this.focusTimer);
		}
	}

	debounceCheck = debounce(value => {
		if (this.props.busy) {
			this.props.onError('busy');
		} else {
			const valid = this.props.checkValid(value);
			if (valid) {
				this.handleValidCode(value);
			} else if (this.props.onError) this.props.onError('invalid');
		}
		this.setState({
			barCode: '',
		});
	}, DEBOUNCE_TIME_MILISEC);

	handleValidCode = value => {
		if (this.props.onMatch) this.props.onMatch(value);
		this.setState({
			barCode: '',
		});
	};

	handleChange = event => {
		let { value } = event.target;
		if (this.props.toUpperCase) {
			value = value.toUpperCase();
		}
		this.setState({ barCode: value });
		if (this.props.onChange) {
			this.props.onChange(event);
		}
		if (this.props.checkValid) {
			if (this.props.debounce) {
				this.debounceCheck(value);
			} else if (this.props.checkValid(value)) {
				this.handleValidCode(value);
			}
		}
	};

	handleKeyPress = event => {
		if (this.props.disableKeyboard) {
			const { charCode } = event;
			this.setState(
				state => ({
					barCode: state.barCode + String.fromCharCode(charCode),
				}),
				() => this.handleChange({ target: { value: this.state.barCode } })
			);
		}
	};

	render() {
		const message = this.props.placeHolder || 'Código de Barras';
		return (
			<div className="row">
				<div className="col-sm-12">
					<FormControl className="mb-3" fullWidth a>
						<InputLabel htmlFor="order-id-input">{message}</InputLabel>
						<Input
							autoFocus={this.props.keepFocus}
							id="order-id-input"
							value={this.state.barCode}
							onChange={this.handleChange}
							inputRef={input => {
								this.barcodeInput = input;
							}}
							onKeyPress={this.handleKeyPress}
							readOnly={this.props.disableKeyboard}
							autoComplete="off"
						/>
					</FormControl>
				</div>
			</div>
		);
	}
}

BarCodeInput.propTypes = {
	keepFocus: PropTypes.bool,
	debounce: PropTypes.bool,
	toUpperCase: PropTypes.bool,
	disableKeyboard: PropTypes.bool,
	placeHolder: PropTypes.string,
	checkValid: PropTypes.func,
	onError: PropTypes.func,
	onMatch: PropTypes.func,
	onChange: PropTypes.func,
};

BarCodeInput.defaultProps = {
	keepFocus: false,
	debounce: false,
	toUpperCase: false,
	disableKeyboard: false,
	placeHolder: '',
	checkValid: value => !!value,
	onError: () => {},
	onMatch: () => {},
	onChange: () => {},
};

export default BarCodeInput;
export * from './CheckFunctions';
