export const DISPATCH_STEPS = Object.freeze({
  BEGINNING: 0,
  DETAILS: 1,
});
export const DISPATCH_SEARCH_TYPES = Object.freeze({
  ROUTE: "Rota",
  ORDER: "Pedido",
});

export const DISPATCH_TYPES = Object.freeze({
  frozen: "Congelados e Secos",
  greenGrocer: "Refrigerados"
});

export const ERROR_TYPE = Object.freeze({
  LOAD_ROUTE: 0,
  FINISH_DISPATCH: 1,
});

export const PRODUCT_TYPE = Object.freeze({
  snacks: "snacks",
  frozen: "congelados",
});

export const MANUAL_CHECKING_OPTIONS = Object.freeze({
	UNREADABLE_TAG: 'Impossível ler a etiqueta',
	WRONG_TAG: 'Etiqueta errada',
	MISSING_ITEM: 'Item faltando',
});
