import {
	GET_PALLETS_REQUEST,
	GET_PALLETS_RESPONSE,
	GET_PALLETS_ERROR,
	SET_PALLET,
	CLOSE_PALLET_REQUEST,
	CLOSE_PALLET_RESPONSE,
	CLOSE_PALLET_ERROR,
	RESET_CLOSE_PALLETS_STORE,
	PRODUCTS_INFO_REQUEST,
	PRODUCTS_INFO_RESPONSE,
	PRODUCTS_INFO_ERROR,
} from 'constants/ActionTypes';

const initialState = {
	pallet: null,
	pallets: [],
	products: {},
	loadingPallets: false,
	loadingProducts: false,
	error: null,
	closePalletLoading: false,
	palletClosed: false,
	alreadyClosed: false,
};

const ClosePallets = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_INFO_REQUEST:
			return {
				...state,
				loadingProducts: true,
				error: null,
			};
		case PRODUCTS_INFO_RESPONSE:
			return {
				...state,
				loadingProducts: false,
				products: action.payload,
				error: null,
			};
		case PRODUCTS_INFO_ERROR:
			return {
				...state,
				loadingProducts: false,
				error: action.payload.error,
			};
		case GET_PALLETS_REQUEST:
			return {
				...state,
				pallets: [],
				loadingPallets: true,
				error: null,
			};
		case GET_PALLETS_RESPONSE:
			return {
				...state,
				pallets: action.payload.data,
				loadingPallets: false,
				error: null,
			};
		case GET_PALLETS_ERROR:
			return {
				...state,
				loadingPallets: false,
				error: action.payload.error,
			};
		case SET_PALLET:
			return {
				...state,
				pallet: action.payload.pallet,
			};
		case CLOSE_PALLET_REQUEST:
			return {
				...state,
				closePalletLoading: true,
				error: null,
			};
		case CLOSE_PALLET_RESPONSE:
			return {
				...state,
				palletClosed: action.payload.palletClosed,
				alreadyClosed: action.payload.alreadyClosed,
				closePalletLoading: false,
			};
		case CLOSE_PALLET_ERROR:
			return {
				...state,
				error: action.payload.error,
				closePalletLoading: false,
			};
		case RESET_CLOSE_PALLETS_STORE:
			return initialState;
		default:
			return state;
	}
};

export default ClosePallets;
