import { makeStyles } from '@material-ui/styles';

const styles = () => ({
	typography: {
		color: '#008fa8',
		fontSize: '18px',
		fontWeight: 'bold',
		marginBottom: '8px',
	},
});

export const useStyles = makeStyles(styles);
