import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
	renderActions() {
		const actions = [];
		this.props.actions.forEach(action => {
			actions.push(
				<Button
					key={action.title}
					onClick={action.onClick}
					color={action.type}
					type={action.submit ? 'submit' : 'button'}
					autoFocus={action.autoFocus}
					disabled={action.disabled || false}
				>
					{action.title}
				</Button>
			);
		});
		return <DialogActions>{actions}</DialogActions>;
	}

	render() {
		const { open, onClose, title, children } = this.props;

		return (
			<div>
				<Dialog
					open={open}
					onClose={onClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{children}
						</DialogContentText>
					</DialogContent>
					{this.renderActions()}
				</Dialog>
			</div>
		);
	}
}

AlertDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	actions: PropTypes.array,
};

AlertDialog.defaultProps = {
	open: false,
	title: '',
	children: [],
	actions: [],
};

export default AlertDialog;
