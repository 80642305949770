import { Button, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const styles = (theme) => ({
  div: {
    marginRight: '4%',
  },
  buttonText: {
    color: theme.palette.common.white,
    textTransform: 'none',
    fontSize: '16px',
  },
  button: {
    '&:hover': {
      backgroundColor: '#455a64',
    },
    borderRadius: 0,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    marginLeft: '2%',
    marginRight: '2%',
    width: '100%',
    height: `100%`,
    backgroundColor: '#455a64',
    textAlign: 'center',
  },
});

const FooterBackButton = ({ onBack, classes }) => (
  <div className={classes.div}>
    <Button onClick={onBack} className={classes.button}>
      <Typography className={classes.buttonText}>Voltar à seleção de CD</Typography>
    </Button>
  </div>
);

export default withStyles(styles)(FooterBackButton);
