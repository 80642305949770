import {
	GET_ORDERS_LIST_REQUEST,
	GET_ORDERS_LIST_RESPONSE,
	GET_ORDERS_LIST_ERROR,
} from '../constants/ActionTypes';
import { handleRequestError } from './ErrorHandling';

export const getOrdersList = (date, runOnBackground) => async (dispatch, getState, api) => {
	const distributionCenter = getState().distributionCenters.selected;
	const period = getState().periods.selected;

	if (!distributionCenter || !period) {
		return null;
	}

	try {
		dispatch({
			type: GET_ORDERS_LIST_REQUEST,
			payload: {
				runOnBackground,
			},
		});
		const response = await api.get('/orders-list', {
			params: { date, period, distributionCenter },
		});
		dispatch({
			type: GET_ORDERS_LIST_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: GET_ORDERS_LIST_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};
