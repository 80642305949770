import { makeStyles } from '@material-ui/styles';

const styles = () => ({
	text: {
		color: '#4A4A4A',
		fontSize: '16px',
		marginBottom: '16px',
		fontWeight: 'bold',
	},

	palletIndex: {
		color: '#e98081',
		fontSize: '12px',
		opacity: '80%',
	},
	itemName: {
		color: '#4A4A4A',
		fontSize: '12px',
		marginRight: '24px',
	},
	boxQuantity: {
		color: '#E57374',
		fontSize: '12px',
	},
});

export const useStyles = makeStyles(styles);
