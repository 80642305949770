import React, { Component } from 'react';
import { debounce } from 'utils/timingFunctions';
import { PropTypes } from 'prop-types';

const DEBOUNCE_TIME_MILISEC = 100;

class PseudoBarCodeInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barCode: '',
		};
		this.barcodeInput = null;
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keyDownCallback);

		document.addEventListener('keypress', this.keyPressCallback);

		document.addEventListener('paste', this.pasteCallback);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keyDownCallback);

		document.removeEventListener('keypress', this.keyPressCallback);

		document.removeEventListener('paste', this.pasteCallback);
	}

	keyDownCallback = e => {
		if (this.props.busyScanner) return;
		const keyCode = e.which || e.keyCode;
		if (keyCode === 8) {
			let scanVal = this.state.barCode;
			scanVal = scanVal.slice(0, -1);
			this.handleChange(scanVal);
		}
	};

	keyPressCallback = e => {
		if (this.props.busyScanner) return;
		const keyCode = e.which || e.keyCode;
		const charFromKey = String.fromCharCode(keyCode);
		let scanVal = this.state.barCode;
		scanVal += charFromKey;
		this.handleChange(scanVal);
	};

	pasteCallback = e => {
		if (this.props.busyScanner) return;
		const paste = (e.clipboardData || window.clipboardData).getData('text');
		this.handleChange(paste);
	};

	debounceCheck = debounce(value => {
		if (this.props.busy) {
			this.props.onError('busy');
		} else {
			const valid = this.props.checkValid(value);
			if (valid) {
				this.handleValidCode(value);
			} else if (this.props.onError) this.props.onError('invalid');
		}
		this.setState({
			barCode: '',
		});
	}, DEBOUNCE_TIME_MILISEC);

	handleValidCode = value => {
		if (this.props.onMatch) this.props.onMatch(value);
		this.setState({
			barCode: '',
		});
	};

	handleChange = value => {
		this.setState({ barCode: value });
		if (this.props.onChange) {
			this.props.onChange(value);
		}
		if (this.props.checkValid) {
			if (this.props.debounce) {
				this.debounceCheck(value);
			} else if (this.props.checkValid(value)) {
				this.handleValidCode(value);
			}
		}
	};

	render() {
		return <div />;
	}
}

PseudoBarCodeInput.propTypes = {
	debounce: PropTypes.bool,
	checkValid: PropTypes.func,
	onError: PropTypes.func,
	onMatch: PropTypes.func,
	onChange: PropTypes.func,
};

PseudoBarCodeInput.defaultProps = {
	debounce: false,
	checkValid: value => !!value,
	onError: () => {},
	onMatch: () => {},
	onChange: () => {},
};

export default PseudoBarCodeInput;
export * from '../../../components/BarCodeInput/CheckFunctions';
