import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'material-ui-pickers';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import InputLabel from '@material-ui/core/InputLabel';
import styles from '../styles.css';

class RoutesDateSelect extends React.Component {
	handleChange = date => {
		this.props.onSelect(date ? date.format('YYYY-MM-DD') : null);
	};

	render() {
		const { selectedDate } = this.props;

		return (
			<form className="row" autoComplete="off">
				<div className="col-lg-12 col-sm-12 col-12">
					<InputLabel className={`w-100 mb-2 ${styles.routesForm}`}>
						<DatePicker
							className={styles.routesForm}
							label="Data"
							autoOk
							clearable
							clearLabel="Limpar"
							cancelLabel="Cancelar"
							emptyLabel="Selecione uma data"
							okLabel="Buscar"
							format="DD/MM/YYYY"
							leftArrowIcon={<KeyboardArrowLeftIcon />}
							rightArrowIcon={<KeyboardArrowRightIcon />}
							showTodayButton
							onChange={this.handleChange}
							value={selectedDate}
						/>
					</InputLabel>
				</div>
			</form>
		);
	}
}

RoutesDateSelect.propTypes = {
	onSelect: PropTypes.func.isRequired,
	selectedDate: PropTypes.string,
};

RoutesDateSelect.defaultProps = {
	selectedDate: null,
};

export default RoutesDateSelect;
