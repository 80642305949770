import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { uploadRoutes } from 'actions/DeliveryRoutes';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DCSelect from 'containers/DCSelect';
import CardBox from 'components/CardBox';
import { parseCSV } from 'utils/parsers';
import PeriodSelect from 'containers/PeriodSelect';
import RoutesDateSelect from '../components/RoutesDateSelect';

import styles from '../styles.css';

class UploadRoutesContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			csvFilename: '',
			csvData: null,
			selectedDate: moment().format('YYYY-MM-DD'),
		};

		this.handleUpload = this.handleUpload.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.upload.success && this.props.upload.success !== prevProps.upload.success) {
			this.props.switchTabs(null, 0);
		}
	}

	handleUpload(event) {
		const file = event.target.files[0];
		this.setState({ csvFilename: file.name });

		parseCSV(file, {}, csvData => this.setState({ csvData }));

		// clear input value so the same file can be reuploaded
		this.fileInput.value = '';
	}

	isSubmitDisabled() {
		const {
			upload: { loading: uploadLoading },
			selectedCenter,
		} = this.props;
		const { csvData } = this.state;

		if (uploadLoading || !selectedCenter || !csvData) {
			return true;
		}
		return false;
	}

	handleSelectDate = date => {
		this.setState({ selectedDate: date });
	};

	handleSubmit() {
		const { csvData, selectedDate } = this.state;
		this.props.uploadRoutes(csvData, selectedDate);
	}

	renderCSVStats() {
		const { csvFilename, csvData } = this.state;
		if (!csvFilename || !csvData) {
			return null;
		}
		return (
			<Grid item xs={7}>
				{`${csvFilename} com ${csvData.data.length} pedidos`}
			</Grid>
		);
	}

	renderPickCSV() {
		return (
			<React.Fragment>
				<input
					accept=".csv"
					id="raised-button-file"
					multiple
					type="file"
					style={{ display: 'none' }}
					ref={input => {
						this.fileInput = input;
					}}
					onChange={this.handleUpload}
				/>
				<label htmlFor="raised-button-file">
					<Button
						variant="raised"
						component="span"
						color="primary"
						className="jr-btn text-blue-gray"
					>
						Escolher .CSV
					</Button>
				</label>
			</React.Fragment>
		);
	}

	renderSubmit() {
		if (this.props.upload.loading) {
			return (
				<Grid item xs={12}>
					<CircularProgress size={50} />
				</Grid>
			);
		}
		return (
			<Grid item xs={5}>
				<label htmlFor="raised-button-save">
					<Button
						variant="raised"
						component="span"
						color="secondary"
						className="jr-btn text-blue-gray"
						disabled={this.isSubmitDisabled()}
						onClick={this.handleSubmit}
					>
						Salvar no Banco
					</Button>
				</label>
			</Grid>
		);
	}

	renderUploadForm() {
		return (
			<div>
				<DCSelect />
				<RoutesDateSelect
					onSelect={this.handleSelectDate}
					selectedDate={this.state.selectedDate}
				/>
				<PeriodSelect />
				{this.renderPickCSV()}
				<Grid container alignItems="center" className={styles.buttonPadding}>
					{this.renderSubmit()}
					{this.renderCSVStats()}
				</Grid>
			</div>
		);
	}

	render() {
		return (
			<div className="row mb-md-4 start-picking-form">
				<CardBox styleName="col-12">{this.renderUploadForm()}</CardBox>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	selectedCenter: state.distributionCenters.selected,
	upload: {
		loading: state.deliveryRoutes.upload.loading,
		success: state.deliveryRoutes.upload.success,
	},
});

UploadRoutesContainer.propTypes = {
	upload: PropTypes.shape({
		loading: PropTypes.bool,
		success: PropTypes.bool,
	}),
	selectedCenter: PropTypes.string,
	// Actions
	uploadRoutes: PropTypes.func.isRequired,
	switchTabs: PropTypes.func.isRequired,
};

UploadRoutesContainer.defaultProps = {
	upload: {
		loading: false,
	},
	selectedCenter: '',
};

export default connect(
	mapStateToProps,
	{ uploadRoutes }
)(UploadRoutesContainer);
