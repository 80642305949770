import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { getClosedPallets, deletePallet } from 'actions/ManagePallets';
import { getProductsInfos } from 'actions/ProductsInfo';
import Pallets from '../../InventoryInput/components/Pallets';
import { PaperHeader } from '../../ClosePallet/components/Header';

const ManagePalletsContainer = props => {
	useEffect(() => {
		props.getClosedPallets();
		props.getProductsInfos();
	}, []);

	return props.loadingPallets || props.loadingProducts || props.deletePalletLoading ? (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '450px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress size={50} />
		</div>
	) : (
		<React.Fragment>
			<PaperHeader text="Lista de Pallets" type="primary" />
			<Pallets
				pallets={props.pallets}
				handleClick={() => {}}
				editable
				handleDelete={props.deletePallet}
			/>
		</React.Fragment>
	);
};

ManagePalletsContainer.propTypes = {
	pallets: PropTypes.array,
	loadingPallets: PropTypes.bool,
	loadingProducts: PropTypes.bool,
	getClosedPallets: PropTypes.func.isRequired,
	getProductsInfos: PropTypes.func.isRequired,
	deletePallet: PropTypes.func.isRequired,
};

ManagePalletsContainer.defaultProps = {
	pallets: [],
	loadingPallets: false,
	loadingProducts: false,
};

const mapActionsToProps = {
	getClosedPallets,
	deletePallet,
	getProductsInfos,
};

const mapStateToProps = state => ({
	pallets: state.managePallets.pallets,
	loadingPallets: state.managePallets.loadingPallets,
	loadingProducts: state.closePallets.loadingProducts,
	deletePalletLoading: state.managePallets.deletePalletLoading,
});

export default connect(
	mapStateToProps,
	mapActionsToProps
)(ManagePalletsContainer);
