import { SET_PRODUCT_TYPE } from '../constants/ActionTypes';

const initialState = localStorage.getItem('productType') || 'frozen';

const productTypeReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PRODUCT_TYPE:
			return action.productType;
		default:
			return state;
	}
};

export default productTypeReducer;
