import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchDistributionCenters, selectDistributionCenter } from 'actions/DistributionCenters';
import ErrorBox from 'components/ErrorBox';

class DCSelect extends React.Component {
	componentDidMount() {
		if (this.props.distributionCenters.length === 0) {
			this.props.fetchDistributionCenters();
		}
	}

	handleChange = e => {
		const { distributionCenters } = this.props;
		distributionCenters.forEach(dc => {
			if (dc._id === e.target.value)
				this.props.selectDistributionCenter(dc._id, dc.address, dc.kind);
		});
	};

	renderOptions() {
		const { distributionCenters } = this.props;
		return distributionCenters.map(center => (
			<MenuItem value={center._id} key={center._id}>
				{center.name}
			</MenuItem>
		));
	}

	render() {
		if (this.props.loading) {
			return <CircularProgress size={50} />;
		}
		if (this.props.error) {
			return (
				<ErrorBox message={this.props.error} retry={this.props.fetchDistributionCenters} />
			);
		}
		return (
			<FormControl className="w-100 mb-2" fullWidth>
				<InputLabel htmlFor="dc-select">Centro de Distribuição</InputLabel>
				<Select
					value={this.props.selectedDistributionCenter}
					onChange={this.handleChange}
					input={<Input id="dc-select" />}
				>
					{this.renderOptions()}
				</Select>
			</FormControl>
		);
	}
}

function mapStateToProps(state) {
	return {
		distributionCenters: state.distributionCenters.data,
		selectedDistributionCenter: state.distributionCenters.selected,
		loading: state.distributionCenters.loading,
		error: state.distributionCenters.error,
	};
}

DCSelect.propTypes = {
	distributionCenters: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
			address: PropTypes.object,
		})
	).isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.string,
	selectedDistributionCenter: PropTypes.string.isRequired,
	fetchDistributionCenters: PropTypes.func.isRequired,
	selectDistributionCenter: PropTypes.func.isRequired,
};

DCSelect.defaultProps = {
	error: null,
};

export default connect(mapStateToProps, { fetchDistributionCenters, selectDistributionCenter })(
	DCSelect
);
