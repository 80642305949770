import {
  GREEN_GROCER_POST_CONTAINER_REQUEST,
  GREEN_GROCER_POST_CONTAINER_RESPONSE,
  GREEN_GROCER_POST_CONTAINER_ERROR,
  GREEN_GROCER_RESET_CONTAINER_STATE,
  GREEN_GROCER_POST_CONTAINER_CHECK,
} from '../constants/ActionTypes';

const initialState = {
  container: {},
  finish: false,
  loading: false,
  error: '',
  check: {
    repeated: false,
    message: '',
  },
};

const greenGrocerWMS = (state = initialState, action: any) => {
  switch (action.type) {
    case GREEN_GROCER_POST_CONTAINER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GREEN_GROCER_POST_CONTAINER_RESPONSE:
      return {
        ...state,
        container: action.payload.data.container || null,
        finish: true,
        loading: false,
      };
    case GREEN_GROCER_POST_CONTAINER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error || 'Unknow error',
      };
    case GREEN_GROCER_POST_CONTAINER_CHECK:
      return {
        ...state,
        check: {
          repeated: true,
          message: action.payload.data,
        },
      };
    case GREEN_GROCER_RESET_CONTAINER_STATE:
      return {
        container: {},
        finish: false,
        loading: false,
        error: '',
        check: {
          repeated: false,
          message: '',
        },
      };
    default:
      return state;
  }
};

export default greenGrocerWMS;
