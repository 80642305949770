import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'assets/images/read-barcode.png';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import BarCodeInput from 'components/BarCodeInput';
import { checkPackCode } from 'components/BarCodeInput/CheckFunctions';
import styles from '../styles.css';

class OrderReturnForm extends React.Component {
	handleChange = code => this.props.onSubmitPackages(code);

	renderBarCodeInput = () => {
		if (this.props.loading) {
			return <CircularProgress size={50} />;
		}
		return (
			<BarCodeInput
				onMatch={this.handleChange}
				checkValid={code => checkPackCode(code)}
				placeHolder="Código de barras da sacola"
				keepFocus
				debounce
			/>
		);
	};

	render() {
		return (
			<Grid container direction="column" alignItems="center" justify="center" spacing={16}>
				<Card className={styles.returnForm}>
					<CardContent>
						<Grid
							container
							direction="column"
							alignItems="center"
							justify="flex-start"
							spacing={16}
						>
							<Grid item>
								<h1>Retorno de Pedidos</h1>
							</Grid>
							<Grid container direction="row" justify="center">
								<Grid item xs={6} align="center">
									<img width="128" src={Icon} alt="Barcode scanner" />
								</Grid>
								<Grid item xs={6}>
									<form onSubmit={this.handleClick}>
										<Grid item>{this.renderBarCodeInput()}</Grid>
									</form>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		);
	}
}

OrderReturnForm.propTypes = {
	onSubmitPackages: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};
OrderReturnForm.defaultProps = {
	loading: false,
};

export default OrderReturnForm;
