import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardSubtitle, CardText } from 'reactstrap';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Done from '@material-ui/icons/Done';
import Schedule from '@material-ui/icons/Schedule';
import AttachMoney from '@material-ui/icons/AttachMoney';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import { ROUTES_STATUS } from '../../../constants/DeliveryRoutes';

import styles from '../styles.css';

class RoutesStatus extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { routes, date, period } = this.props;
		const totalPackages = routes.reduce((clients, route) => clients + route.packages.length, 0);
		const called = routes.filter(
			route =>
				route.status === ROUTES_STATUS.ALLOCATING ||
				route.status === ROUTES_STATUS.IN_TRANSIT ||
				route.status === ROUTES_STATUS.FINISHED
		);
		const toCall = routes.filter(
			route =>
				route.status !== ROUTES_STATUS.ALLOCATING &&
				route.status !== ROUTES_STATUS.IN_TRANSIT &&
				route.status !== ROUTES_STATUS.FINISHED
		);
		const totalCost = routes.reduce((partialCost, route) => {
			if (route.estimated_price) {
				return partialCost + Number(route.estimated_price);
			}
			return partialCost;
		}, 0);
		const avgCost = totalCost / totalPackages;
		return (
			<Card className="shadow border-0">
				<CardBody>
					<h3 className="card-title">{`${routes.length} Rotas`}</h3>
					<CardSubtitle>{`${moment(date).format('dddd [,] ll')} - ${period &&
						period.name}`}</CardSubtitle>
					<CardText>
						<Grid container direction="row" spacing={24}>
							<Grid item>
								<Done className={styles.icon} />
								<span>
									{`rotas chamadas: `} <strong>{`${called.length}`}</strong>
								</span>
								<br />
								<Schedule className={styles.icon} />
								<span>
									{`rotas não chamadas: `} <strong>{`${toCall.length}`}</strong>
								</span>
							</Grid>
							<Grid item>
								<ShoppingBasket className={styles.icon} />
								<span>
									{`Total de pedidos: `} <strong>{`${totalPackages}`}</strong>
								</span>
								<br />
								<AttachMoney className={styles.icon} />
								<span>
									{`custo total estimado: `}{' '}
									<strong>{`${totalCost.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}`}</strong>
								</span>
								<br />
								<MonetizationOn className={styles.icon} />
								<span>
									{`custo médio estimado: `}
									<strong>{`${avgCost.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}`}</strong>
								</span>
							</Grid>
						</Grid>
					</CardText>
				</CardBody>
				<div className="card-mt-footer" />
			</Card>
		);
	}
}

RoutesStatus.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.number,
			delivery_eta: PropTypes.number,
			estimated_price: PropTypes.number,
			status: PropTypes.string,
		})
	),
	date: PropTypes.string,
	period: PropTypes.shape({
		name: PropTypes.string,
	}).isRequired,
};

RoutesStatus.defaultProps = {
	routes: [],
	date: null,
};

export default RoutesStatus;
