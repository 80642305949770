import axios from 'axios';
import {
	RUN_ROUTER_REQUEST,
	RUN_ROUTER_RESPONSE,
	RUN_ROUTER_ERROR,
	FETCH_ROUTER_STATUS_REQUEST,
	FETCH_ROUTER_STATUS_RESPONSE,
	FETCH_ROUTER_STATUS_ERROR,
} from '../constants/ActionTypes';
import { handleRequestError } from './ErrorHandling';
import { ROUTING_ENDPOINTS } from '../constants/RoutingAPI';

const getRoutingAPI = () =>
	axios.create({
		baseURL: process.env.ROUTING_API_ADDRESS,
		responseType: 'json',
	});

export const runRouter = ({
	distributionCenterId,
	date,
	period,
	ordersFilter,
	modal,
}) => async dispatch => {
	dispatch({
		type: RUN_ROUTER_REQUEST,
		payload: {
			distributionCenterId,
			date,
			period,
		},
	});

	try {
		const routingApi = getRoutingAPI();

		const authToken = localStorage.getItem('authToken');
		const headers = {
			Authorization: authToken ? `Bearer ${authToken}` : '',
			'Content-Type': 'application/json',
		};

		const response = await routingApi.get(ROUTING_ENDPOINTS[modal], {
			headers,
			params: {
				date,
				period,
				distribution_center: distributionCenterId,
				orders_filter: JSON.stringify(ordersFilter),
			},
		});
		dispatch({
			type: RUN_ROUTER_RESPONSE,
			payload: response.data,
		});
	} catch (error) {
		dispatch({
			type: RUN_ROUTER_ERROR,
			error,
		});
		dispatch(handleRequestError(error));
	}
};

export const fetchRouterStatus = taskId => async dispatch => {
	dispatch({
		type: FETCH_ROUTER_STATUS_REQUEST,
		payload: {
			taskId,
		},
	});

	try {
		const routingApi = getRoutingAPI();

		const authToken = localStorage.getItem('authToken');
		const headers = {
			Authorization: authToken ? `Bearer ${authToken}` : '',
			'Content-Type': 'application/json',
		};

		const response = await routingApi.get(`/routing/${taskId}`, {
			headers,
		});

		dispatch({
			type: FETCH_ROUTER_STATUS_RESPONSE,
			payload: response.data,
		});
	} catch (error) {
		dispatch({
			type: FETCH_ROUTER_STATUS_ERROR,
			error,
		});
		dispatch(handleRequestError(error));
	}
};
