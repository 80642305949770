import {
	PERIODS_FETCH_REQUEST,
	PERIODS_FETCH_RESPONSE,
	PERIODS_FETCH_ERROR,
	PERIOD_SELECT,
} from 'constants/ActionTypes';

const initialState = {
	available: [],
	loading: false,
	error: null,
	selected: localStorage.getItem('period') || '',
};

const distributionCenters = (state = initialState, action) => {
	switch (action.type) {
		case PERIODS_FETCH_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case PERIODS_FETCH_RESPONSE:
			return {
				...state,
				loading: false,
				available: action.payload.data,
			};
		case PERIODS_FETCH_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};
		case PERIOD_SELECT:
			return {
				...state,
				selected: action.payload.data,
			};
		default:
			return state;
	}
};

export default distributionCenters;
