import { makeStyles } from '@material-ui/styles';

const styles = () => ({
	paper: {
		position: 'fixed',
		top: '50%',
		width: props => (props.fullWidth ? '90%' : 'auto'),
		left: props => (props.matchContent ? '50%' : '16px'),
		right: props => (props.matchContent ? 'auto' : '16px'),
		transform: props => (props.matchContent ? 'translate(-50%, -50%)' : 'translate(0%, -50%)'),
		borderRadius: '2dp',
	},
	modalContent: {
		padding: '24px',
	},
	divider: {
		height: '1px',
		width: '100%',
		backgroundColor: '#F7F7F7',
	},
	buttonsContainer: {
		padding: '8px',
	},
	button: {
		color: props => (props.buttonTextColor ? props.buttonTextColor : '#4A4A4A'),
		fontWeight: 'bold',
		padding: '8px',
		marginLeft: '16px',
	},
});

export const useStyles = makeStyles(styles);
