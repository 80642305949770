import {
	DISTRIBUTION_CENTERS_FETCH_REQUEST,
	DISTRIBUTION_CENTERS_FETCH_RESPONSE,
	DISTRIBUTION_CENTERS_FETCH_ERROR,
	DISTRIBUTION_CENTERS_SELECT,
} from '../constants/ActionTypes';

import { handleRequestError } from './ErrorHandling';

export const fetchDistributionCenters = () => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: DISTRIBUTION_CENTERS_FETCH_REQUEST,
		});
		const response = await api.get('/distribution-center/');
		dispatch({
			type: DISTRIBUTION_CENTERS_FETCH_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: DISTRIBUTION_CENTERS_FETCH_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err, false));
	}
};

export const selectDistributionCenter = (
	centerId,
	centerLocation,
	centerKind
) => async dispatch => {
	dispatch({
		type: DISTRIBUTION_CENTERS_SELECT,
		payload: {
			data: {
				id: centerId,
				address: centerLocation,
				kind: centerKind,
			},
		},
	});
	localStorage.setItem('distributionCenter', centerId);
	localStorage.setItem('distributionCenterKind', centerKind);
	localStorage.setItem('distributionCenterLocation', JSON.stringify(centerLocation));
};
