import React, { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'styles/app.scss';
import 'styles/global.scss';
import MainApp from 'routes/index';
import NotificationCenter from 'containers/NotificationCenter';
import RegularUpdateCenter from 'containers/RegularUpdateCenter';

import defaultTheme from './themes/defaultTheme';
import AppLocale from '../languageProvider';

import Login from './Login';

class App extends Component {
	render() {
		const { match, location, locale, authUser, initURL } = this.props;

		if (location.pathname === '/') {
			return <Redirect to="/app" />;
		}

		console.log('connecting with ');
		const currentAppLocale = AppLocale[locale.locale];
		return (
			<MuiThemeProvider theme={createMuiTheme(defaultTheme)}>
				<ThemeProvider theme={createMuiTheme(defaultTheme)}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<IntlProvider
							locale={currentAppLocale.locale}
							messages={currentAppLocale.messages}
						>
							<div className="app-main">
								<NotificationCenter />
								<RegularUpdateCenter />
								<Route path="/login" component={Login} authUser={authUser} />
								<Route path="/app" component={MainApp} authUser={authUser} />
							</div>
						</IntlProvider>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</MuiThemeProvider>
		);
	}
}

App.propTypes = {
	authUser: PropTypes.object,
	initURL: PropTypes.string,
};

App.defaultProps = {
	authUser: null,
	initURL: '/',
};

const mapStateToProps = ({ settings, auth }) => {
	const { sideNavColor, locale } = settings;
	const { authUser, initURL } = auth;
	return { sideNavColor, locale, authUser, initURL };
};

export default connect(mapStateToProps)(App);
