import Button from "@material-ui/core/Button";
import "jquery-slimscroll/jquery.slimscroll.min";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "utils/IntlMessages";
import { testId } from "../../utils/testId";

const getPathsFromRoutes = (routes) =>
  routes.map((route) => route.props && route.props.path);

const token = localStorage.getItem("gandauthToken");

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const $nav = $(this.nav);
    const slideDuration = 250;
    const pathname = `#${history.location.pathname}`; // get current path

    $nav.slimscroll({
      height: "100%",
    });

    $("ul.nav-menu > li.menu").click(function () {
      const menuLi = this;
      $("ul.nav-menu > li.menu").not(menuLi).removeClass("open");
      $("ul.nav-menu > li.menu ul").not($("ul", menuLi)).slideUp(slideDuration);
      $("> ul", menuLi).slideToggle(slideDuration);
      $(menuLi).toggleClass("open");
    });

    $("ul.sub-menu li").click(function (e) {
      const superSubMenu = $(this).parent();
      if (superSubMenu.parent().hasClass("active")) {
        $("li", superSubMenu).not($(this)).removeClass("active");
      } else {
        $("ul.sub-menu li").not($(this)).removeClass("active");
      }

      $(this).toggleClass("active");
      e.stopPropagation();
    });

    const activeLi = $(`a[href="${pathname}"]`); // select current a element
    const activeNav = activeLi.closest("ul"); // select closest ul
    if (activeNav.hasClass("sub-menu")) {
      activeNav.slideDown(slideDuration);
      activeNav.parent().addClass("open");
      activeLi.parent().addClass("active");
    } else {
      activeLi.parent().addClass("open");
    }
  }

  subMenu = (message, disabled = false, items, contentTestId) =>
    !disabled && (
      <li className="menu">
        <Button href="javascript:void(0)" {...testId(contentTestId)}>
          <i className="zmdi zmdi-file-text" />
          <span className="nav-text">{message}</span>
        </Button>
        <ul className="sub-menu">{items.map((item) => item)}</ul>
      </li>
    );

  render() {
    const paths = getPathsFromRoutes(this.props.routes);
    return (
      <ul
        className="nav-menu"
        ref={(c) => {
          this.nav = c;
        }}
      >
        <li className="nav-header">
          <IntlMessages id="sidebar.main" />
        </li>
        {paths.some((path) => path === "/app/") && (
          <li className="menu no-arrow">
            <NavLink to="/app">
              <i className="zmdi zmdi-home" />
              <span className="nav-text">
                <IntlMessages id="pages.home" />
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/dispatch") && (
          <li className="menu no-arrow">
            <NavLink to="/app/dispatch">
              <i className="zmdi zmdi-assignment-check" />
              <span className="nav-text">
                <IntlMessages id="pages.dispatch" />
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/orders-return") && (
          <li className="menu no-arrow">
            <NavLink to="/app/orders-return">
              <i className="zmdi zmdi-assignment-return" />
              <span className="nav-text">
                <IntlMessages id="pages.ordersReturn" />
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/delivery-routes") && (
          <li className="menu no-arrow">
            <NavLink to="/app/delivery-routes">
              <i className="zmdi zmdi-bike" />
              <span className="nav-text">
                <IntlMessages id="pages.deliveryRoutes" />
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/orders-list") && (
          <li className="menu no-arrow">
            <NavLink to="/app/orders-list">
              <i className="zmdi zmdi-format-subject" />
              <span className="nav-text">
                <IntlMessages id="pages.ordersList" />
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/wms") && (
          <li className="menu no-arrow">
            <NavLink to="/app/wms">
              <i className="zmdi zmdi-inbox" />
              <span className="nav-text">
                <IntlMessages id="pages.wms" />
              </span>
            </NavLink>
          </li>
        )}
        {/*this.subMenu(<IntlMessages id="pages.routing" />, false, [
          <li className="menu no-arrow">
            <NavLink to="/app/routing/loggi">
              <i className="zmdi zmdi-bike" />
              <span className="nav-text" style={{ marginLeft: "-4px" }}>
                <IntlMessages id="pages.routing.loggi" />
              </span>
            </NavLink>
          </li>,
          <li className="menu no-arrow">
            <NavLink to="/app/routing/fiorino">
              <i className="zmdi zmdi-car" />
              <span className="nav-text" style={{ marginLeft: "-4px" }}>
                <IntlMessages id="pages.routing.fiorino" />
              </span>
            </NavLink>
          </li>,
        ])*/}
        {paths.some((path) => path === "/app/products-loss") && (
          <li className="menu no-arrow">
            <NavLink to="/app/products-loss">
              <i className="zmdi zmdi-thumb-down" />
              <span className="nav-text">
                <IntlMessages id="pages.productsLoss" />{" "}
              </span>
            </NavLink>
          </li>
        )}
        {this.subMenu(<IntlMessages id="pages.counting" />, false, [
          <li className="menu no-arrow">
            <NavLink to="/app/counting/start">
              <i className="zmdi zmdi-check-all" />
              <span className="nav-text" style={{ marginLeft: "-4px" }}>
                <IntlMessages id="pages.counting.start" />{" "}
              </span>
            </NavLink>
          </li>,
          <li className="menu no-arrow">
            <NavLink to="/app/counting/finish">
              <i className="zmdi zmdi-check-all" />
              <span className="nav-text" style={{ marginLeft: "-4px" }}>
                <IntlMessages id="pages.counting.finish" />{" "}
              </span>
            </NavLink>
          </li>,
        ])}
        {this.subMenu(
          <IntlMessages id="pages.transferCheck" />,
          false,
          [
            <li className="menu no-arrow">
              <NavLink
                to="/app/transfer-check/order-arrival"
                {...testId("transfer-check-arrival")}
              >
                <i className="zmdi zmdi-check-all" />
                <span className="nav-text" style={{ marginLeft: "-4px" }}>
                  <IntlMessages id="pages.transferCheck.arrival" />{" "}
                </span>
              </NavLink>
            </li>,
            <li className="menu no-arrow">
              <NavLink
                to="/app/transfer-check"
                {...testId("transfer-check-items")}
              >
                <i className="zmdi zmdi-check-all" />
                <span className="nav-text" style={{ marginLeft: "-4px" }}>
                  <IntlMessages id="pages.transferCheck.items" />{" "}
                </span>
              </NavLink>
            </li>,
          ],
          "transfer-check-submenu"
        )}
        <li className="menu no-arrow">
          <a href={`${process.env.WMS_URL}/app?token=${token}`}>
            <i className="zmdi zmdi-filter-frames" />
            <span className="nav-text">
              <IntlMessages id="pages.inventory-wms" />{" "}
            </span>
          </a>
        </li>
        {paths.some((path) => path === "/app/nota-fiscal") && (
          <li className="menu no-arrow">
            <NavLink to="/app/nota-fiscal">
              <i className="zmdi zmdi-print" />
              <span className="nav-text">
                <IntlMessages id="pages.fiscalPrinting" />{" "}
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/products-return") && (
          <li className="menu no-arrow">
            <NavLink to="/app/products-return">
              <i className="zmdi zmdi-assignment-return" />
              <span className="nav-text">
                <IntlMessages id="pages.productsReturn" />{" "}
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/inventory-input") && (
          <li className="menu no-arrow">
            <NavLink to="/app/inventory-input">
              <i className="zmdi zmdi-assignment-return" />
              <span className="nav-text">
                <IntlMessages id="pages.inventoryInput" />{" "}
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/close-pallet") && (
          <li className="menu no-arrow">
            <NavLink to="/app/close-pallet">
              <i className="zmdi zmdi-filter-frames" />
              <span className="nav-text">
                <IntlMessages id="pages.closePallet" />{" "}
              </span>
            </NavLink>
          </li>
        )}
        {paths.some((path) => path === "/app/manage-pallet") && (
          <li className="menu no-arrow">
            <NavLink to="/app/manage-pallet">
              <i className="zmdi zmdi-filter-frames" />
              <span className="nav-text">
                <IntlMessages id="pages.managePallet" />{" "}
              </span>
            </NavLink>
          </li>
        )}
      </ul>
    );
  }
}

SidenavContent.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SidenavContent);
