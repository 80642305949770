import {
	REGEX_PICKING_ORDER,
	REGEX_PICKING_BOX_LABEL,
	REGEX_PACKING_CODE,
	REGEX_PRODUCT,
	REGEX_PACKING_BOX_OR_ORDER,
} from 'constants/Regexes';

export const checkUsingRegex = (str, regex) => {
	const regexObj = new RegExp(regex);
	return regexObj.test(str);
};

export const execRegex = (str, regex) => {
	const regexObj = new RegExp(regex);
	return regexObj.exec(str);
};
export const checkByComparison = (str, array) => array.indexOf(str) > -1;

export const checkOrder = str => checkUsingRegex(str, REGEX_PICKING_ORDER);

export const checkBox = str => checkUsingRegex(str, REGEX_PICKING_BOX_LABEL);

export const checkPackCode = str => execRegex(str, REGEX_PACKING_CODE);

export const checkBoxOrOrder = str => checkBox(str) || checkOrder(str);

export const execBoxOrOrder = str => execRegex(str, REGEX_PACKING_BOX_OR_ORDER);

export const checkProduct = str => checkUsingRegex(str, REGEX_PRODUCT);
