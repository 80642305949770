import React from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import {
  REGEX_FROZEN_WMS_POSITION,
  REGEX_SNACKS_WMS_POSITION,
  REGEX_PACKING_CODE,
  REGEX_FROZEN_PACKING_CODE_OR_WMS_POSITION,
  REGEX_SNACKS_PACKING_CODE_OR_WMS_POSITION,
} from 'constants/Regexes';
import { REGISTER_STEPS } from 'constants/RegisterPack.js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import BarCodeInput, { checkUsingRegex } from 'components/BarCodeInput';
import AlertDialog from 'components/AlertDialog';
import { PropTypes } from 'prop-types';
import {
  STRING_SCAN_POSITION,
  STRING_SCAN_ORDER_OR_POSITION,
} from 'constants/strings.js';
import styles from '../styles.css';

class RegisterPack extends React.Component {
  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.finish || nextProps.error) {
      nextProps.resetRedux();
      return {
        componentState: 0,
        activeRegex:
          nextProps.productType === 'frozen'
            ? REGEX_FROZEN_WMS_POSITION
            : REGEX_SNACKS_WMS_POSITION,
        holdOrdersCode: [],
        holdPositionCode: '',
        container: null,
        error: null,
      };
    }
    return prevState;
  };
  static getChip(chipContent) {
    if (chipContent) return <Chip label={chipContent} />;

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      componentState: 0,
      activeRegex:
        props.productType === 'frozen'
          ? REGEX_FROZEN_WMS_POSITION
          : REGEX_SNACKS_WMS_POSITION,
      holdOrdersCode: [],
      holdPositionCode: '',
    };
  }
  onGoBack = () => {
    this.props.resetRedux();
    this.setState({
      componentState: 0,
      activeRegex:
        this.props.productType === 'frozen'
          ? REGEX_FROZEN_WMS_POSITION
          : REGEX_SNACKS_WMS_POSITION,
      holdOrdersCode: [],
      holdPositionCode: '',
    });
  };

  getMessage() {
    switch (this.state.componentState) {
      case REGISTER_STEPS.READ_POSITION:
        return STRING_SCAN_POSITION;
      case REGISTER_STEPS.READ_PACKAGES_OR_POSITION:
        return STRING_SCAN_ORDER_OR_POSITION;
      default:
        return STRING_SCAN_POSITION;
    }
  }

  getPackingCodeRegex() {
    const { productType } = this.props;
    switch (productType) {
      case 'frozen':
        return REGEX_FROZEN_PACKING_CODE_OR_WMS_POSITION;
      case 'snacks':
      case 'greenGrocer':
        return REGEX_SNACKS_PACKING_CODE_OR_WMS_POSITION;
      default:
        throw new Error('Unknown product type');
    }
  }

  handleRegisterSubmission = value => {
    switch (this.state.componentState) {
      case REGISTER_STEPS.READ_POSITION:
        this.props.sendNotification({
          type: 'success',
          message: 'Posição lida',
          autoDismiss: 2,
        });
        this.setState({
          holdPositionCode: value,
          componentState: 1,
          activeRegex: this.getPackingCodeRegex(),
        });
        break;
      case REGISTER_STEPS.READ_PACKAGES_OR_POSITION:
        if (checkUsingRegex(value, REGEX_PACKING_CODE)) {
          if (this.state.holdOrdersCode.some(code => code === value)) {
            this.props.sendNotification({
              type: 'error',
              message: 'Este pacote já foi lido',
            });
          } else {
            this.props.sendNotification({
              type: 'success',
              message: 'Pacote lido!',
              autoDismiss: 2,
            });
            this.setState(state => ({
              holdOrdersCode: [...state.holdOrdersCode, value],
              componentState: 1,
              activeRegex: this.getPackingCodeRegex(),
            }));
          }
        } else if (value === this.state.holdPositionCode) {
          this.props.postContainer(
            this.state.holdOrdersCode,
            this.state.holdPositionCode,
            false
          );
          this.setState({
            holdPositionCode: value,
            componentState: 0,
          });
        } else if (checkUsingRegex(value, this.getPackingCodeRegex())) {
          this.props.sendNotification({
            type: 'error',
            message: 'A posição não é a mesma.',
          });
        } else {
          throw new Error('Unknown code');
        }

        break;
      default:
        throw new Error('Unknow state in RegisterPack');
    }
  };

  handleCheckValid = value => checkUsingRegex(value, this.state.activeRegex);

  sendErrorNotification = value => {
    if (
      this.state.componentState === REGISTER_STEPS.READ_PACKAGES_OR_POSITION &&
      checkUsingRegex(value, REGEX_PACKING_CODE)
    ) {
      this.props.sendNotification({
        type: 'error',
        message: 'Este código não pertence a este tipo de produto!',
      });
    } else {
      this.props.sendNotification({
        type: 'error',
        message: 'O codigo lido está errado!',
      });
    }
  };

  handleForcePost = _e => {
    this.props.resetRedux();
    this.props.postContainer(
      this.state.holdOrdersCode,
      this.state.holdPositionCode,
      true
    );
    this.setState({
      componentState: 0,
      activeRegex:
        this.props.productType === 'frozen'
          ? REGEX_FROZEN_WMS_POSITION
          : REGEX_SNACKS_WMS_POSITION,
      holdOrdersCode: [],
      holdPositionCode: '',
    });
  };

  renderCheckRepeated() {
    return (
      <AlertDialog
        open={this.props.check.repeated}
        actions={[
          {
            title: 'Cancelar',
            onClick: this.onGoBack,
            type: 'default',
          },
          {
            title: 'Confirmar',
            onClick: this.handleForcePost,
            type: 'primary',
          },
        ]}
      >
        {this.props.check.message}
      </AlertDialog>
    );
  }

  renderCard() {
    if (!this.props.loading) {
      return (
        <Grid container direction="column">
          <Grid item>{this.getMessage()}</Grid>
          <Grid item className={styles.input}>
            <BarCodeInput
              onMatch={this.handleRegisterSubmission}
              checkValid={this.handleCheckValid}
              onError={this.sendErrorNotification}
              autoFocus
              keepFocus
              debounce
            />
          </Grid>
          <Grid item>
            Posição: {RegisterPack.getChip(this.state.holdPositionCode)}
          </Grid>
          <Grid item>
            Pacote:{' '}
            {this.state.holdOrdersCode.map(holdOrderCode =>
              RegisterPack.getChip(holdOrderCode)
            )}
          </Grid>
          <Grid item className={styles.button}>
            <Button variant="raised" color="secondary" onClick={this.onGoBack}>
              Limpar
            </Button>
          </Grid>
        </Grid>
      );
    }
    return <CircularProgress size={50} />;
  }

  render() {
    return (
      <div id={styles.card}>
        <Card>
          <CardContent>{this.renderCard()}</CardContent>
        </Card>
        {this.renderCheckRepeated()}
      </div>
    );
  }
}

RegisterPack.propTypes = {
  productType: PropTypes.string,
  loading: PropTypes.bool,
  check: PropTypes.object,
  // Actions
  postContainer: PropTypes.func.isRequired,
  resetRedux: PropTypes.func.isRequired,
  sendNotification: PropTypes.func,
};

RegisterPack.defaultProps = {
  check: {},
  productType: '',
  error: null,
  loading: false,
  finish: false,
  sendNotification: () => {},
};

export default RegisterPack;
