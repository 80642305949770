import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'utils/IntlMessages';
import ContainerHeader from '../../components/ContainerHeader/index';
import RoutesTabs from './components/RoutesTabs';

class DeliveryRoutes extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.deliveryRoutes" />}
				/>
				<RoutesTabs />
			</div>
		);
	}
}

export default DeliveryRoutes;
