import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = () => ({
	root: {
		color: '#455A64',
		fontSize: '20px',
		fontFamily: 'Roboto',
		fontWeight: 'bold',
		marginTop: '12px',
		marginBottom: '12px',
	},
});

const LoadingOrders = ({ classes }) => (
	<Typography className={classes.root}>Buscando ordens...</Typography>
);

export default withStyles(styles)(LoadingOrders);
