import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

export const PaperHeader = ({ text, type }) => {
	const classes = useStyles({ type });
	return <Typography className={classes.typography}>{text}</Typography>;
};

PaperHeader.propTypes = {
	text: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};
