import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export const Loading = ({ linear, text }) => {
	const classes = useStyles();
	return linear ? (
		<Grid container className={classes.savingContainer} justify="center" direction="column">
			<Typography className={classes.saving}>{text}</Typography>
			<LinearProgress className={classes.savingProgress} />
		</Grid>
	) : (
		<Grid container alignItems="center" justify="center">
			<CircularProgress size={50} className={classes.root} />
		</Grid>
	);
};

Loading.propTypes = {
	linear: PropTypes.bool,
	text: PropTypes.string,
};

Loading.defaultProps = {
	linear: false,
	text: '',
};
