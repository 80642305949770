// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eBgzgNrD19D9WwNj75OA {
  width: 200px;
  margin: auto;
}

.PkfR2N_5BPALhBv0SMUz {
  width: 80px;
  margin: auto;
}

#UTF_pb2wWDICUSKXBYMT {
  width:80%;
  margin:auto;
  margin-top: 10%!important;
}

.uuw91k7hhu2tFG0Cn6J9 {
  margin: 40px;
}

.inlmDRcepEMiLXB1yb9w {
  padding: 40px; 
  width: 100%;
}

.V4bDTV2XeKKkjY1ytGkH {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.EFYQugK_nAWfqwgA3r9r {
  height: 64px;
  width: 64px;
}`, "",{"version":3,"sources":["webpack://./routes/RegisterPack/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".input {\n  width: 200px;\n  margin: auto;\n}\n\n.button {\n  width: 80px;\n  margin: auto;\n}\n\n#card {\n  width:80%;\n  margin:auto;\n  margin-top: 10%!important;\n}\n\n.productTypeCard {\n  margin: 40px;\n}\n\n.buttonBaseStyles {\n  padding: 40px; \n  width: 100%;\n}\n\n.iconStyles {\n  margin-top: 40px;\n  margin-bottom: 40px;\n  padding-top: 40px;\n  padding-bottom: 40px;\n}\n\n.iconSize {\n  height: 64px;\n  width: 64px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `eBgzgNrD19D9WwNj75OA`,
	"button": `PkfR2N_5BPALhBv0SMUz`,
	"card": `UTF_pb2wWDICUSKXBYMT`,
	"productTypeCard": `uuw91k7hhu2tFG0Cn6J9`,
	"buttonBaseStyles": `inlmDRcepEMiLXB1yb9w`,
	"iconStyles": `V4bDTV2XeKKkjY1ytGkH`,
	"iconSize": `EFYQugK_nAWfqwgA3r9r`
};
export default ___CSS_LOADER_EXPORT___;
