import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import TransferCheckContainer from './containers/TransferCheckContainer';

class TransferCheck extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				{window.innerWidth > 800 && (
					<ContainerHeader
						location={this.props.location}
						title={<IntlMessages id="pages.transferCheck" />}
					/>
				)}
				<TransferCheckContainer />
			</div>
		);
	}
}

export default TransferCheck;
