import { Button, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

const styles = theme => ({
	root: {
		marginTop: '50px',
		width: '100%',
		borderRadius: 0,
		boxShadow:
			'0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
		textAlign: 'center',
	},
	buttonText: {
		paddingTop: '15px',
		paddingBottom: '15px',
		textTransform: 'none',
		fontSize: '16px',
	},
	active: {
		backgroundColor: '#094D59',
		'&:hover': {
			backgroundColor: '#094D59',
		},
	},
	inactive: {
		backgroundColor: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.common.white,
		},
	},
	activeText: {
		color: theme.palette.common.white,
	},
	inactiveText: {
		fontWeight: 'bold',
		color: '#094D59',
	},
});

const ManualButton = ({ onClick, manual, classes }) => (
	<Button
		onClick={onClick}
		className={classNames(classes.root, manual ? classes.active : classes.inactive)}
	>
		{manual ? (
			<Typography className={classNames(classes.buttonText, classes.activeText)}>
				Clique aqui para DESATIVAR a leitura de saquinhos
			</Typography>
		) : (
			<Typography className={classNames(classes.buttonText, classes.inactiveText)}>
				Clique aqui para ATIVAR a leitura de saquinhos
			</Typography>
		)}
	</Button>
);

export default withStyles(styles)(ManualButton);
