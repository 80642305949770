import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DISPATCH_SEARCH_TYPES } from 'constants/Dispatch';
import LastRoutesCard from './LastRoutesCard';

const RouteInput = ({ route, onRouteInput, selected }) => {
	const handleChange = event => {
		onRouteInput(event.target.value);
	};
	const getPlaceholder = () => {
		switch (selected) {
			case DISPATCH_SEARCH_TYPES.ROUTE:
				return 'Número da rota';
			case DISPATCH_SEARCH_TYPES.ORDER:
				return 'Número do pedido';
			default:
				return '';
		}
	};
	return (
		<div className="row">
			<div className="col-sm-12">
				<form>
					<FormControl className="mb-3" fullWidth>
						<InputLabel htmlFor="route-id-input">{getPlaceholder()}</InputLabel>
						<Input
							id="route-id-input"
							value={route}
							onChange={handleChange}
							autoFocus
						/>
					</FormControl>
				</form>
			</div>
		</div>
	);
};

export default RouteInput;
