import React from 'react';
import { connect } from 'react-redux';

import { postContainer, resetRedux } from '../../../actions/GreenGrocerWMS';
import { sendNotification } from '../../../actions/Notifications.js';
import ContainerHeader from '../../../components/ContainerHeader';
import IntlMessages from '../../../utils/IntlMessages';
import RegisterPack from '../components/RegisterPack';

function RegisterSnackPack(props: any) {
  return (
    <div className="app-wrapper">
      <ContainerHeader
        location={props.location}
        title={<IntlMessages id="pages.wms.greenGrocer" />}
        showBreadcrumb
      />
      <RegisterPack {...props} productType="greenGrocer" />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  finish: state.greenGrocerWMS.finish,
  storage: state.greenGrocerWMS.storage,
  loading: state.greenGrocerWMS.loading,
  error: state.greenGrocerWMS.error,
  check: state.greenGrocerWMS.check,
});

export default connect(mapStateToProps, {
  postContainer,
  sendNotification,
  resetRedux,
})(RegisterSnackPack);
