import {
	PERIODS_FETCH_REQUEST,
	PERIODS_FETCH_RESPONSE,
	PERIODS_FETCH_ERROR,
	PERIOD_SELECT,
} from '../constants/ActionTypes';

import { handleRequestError } from './ErrorHandling';

export const fetchAvailablePeriods = () => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: PERIODS_FETCH_REQUEST,
		});
		const response = await api.get('/delivery/periods');
		dispatch({
			type: PERIODS_FETCH_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: PERIODS_FETCH_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err, false));
	}
};

export const selectPeriod = periodId => async dispatch => {
	dispatch({
		type: PERIOD_SELECT,
		payload: {
			data: periodId,
		},
	});
	localStorage.setItem('period', periodId);
};
