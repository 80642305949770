export const WRONG_PALLET =
	'Epa! Este produto já está inserido em outro pallet aberto. Volte para a listagem.';

export const WRONG_BATCH =
	'Epa! Este produto é de um lote diferente dos demais. Finalize este lote antes de iniciar outro.';

export const FULL_PALLET =
	'Este pallet já tem 2 produtos diferentes. Volte e escolha outro pallet.';
export const WRONG_BOX = 'Caixa errada.';

export const PRODUCT_NOT_FOUND = 'Produto não encontrado.';
