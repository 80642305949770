import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  paper: {
    marginBottom: '8px',
    display: 'inline-table',
  },
  numberText: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    marginLeft: '20px',
    justifySelf: 'start',
  },
  arrivalText: {
    color: theme.palette.cancel.main,
    fontSize: '12px',
    margin: '10px',
    justifySelf: 'end',
  },
  button: {
    width: '100%',
    margin: '10px',
    justifySelf: 'end',
    fontSize: '14px',
    textTransform: 'none',
  },
});

const formatDate = (date) =>
  date
    .split('-')
    .reverse()
    .join('/');

const OrderCard = ({ number, arrival, classes, onClick }) => {
  const arrivalDate = formatDate(arrival);
  const handleClick = () => {
    onClick(number, arrival);
  };
  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.root} direction="row" spacing={12}>
        <Grid container xs={4}>
          <Typography className={classes.numberText}>{number}</Typography>
        </Grid>
        <Grid container xs={4} justify="flex-end">
          <Typography className={classes.arrivalText}>{`Chegada prevista: ${arrivalDate}`}</Typography>
        </Grid>
        <Grid container xs={4} justify="center">
          <Button className={classes.button} variant="contained" color="primary" onClick={handleClick}>
            Confirmar Chegada
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

OrderCard.propTypes = {
  classes: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  arrival: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderCard);
