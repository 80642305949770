import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Avatar from '@material-ui/core/Avatar';
import FlipMove from 'react-flip-move';
import PackageListItem from './PackageListItem';

import styles from '../styles.css';

class PackagesList extends Component {
	render() {
		const { route, onChangePackagePosition, onDeletePackage } = this.props;
		const { packages } = route;

		if (!packages) return null;

		return (
			<div>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<div className={`jr-card p-0 m-1 ${styles.packagesList}`}>
							<List>
								<FlipMove duration={300} easing="ease-out">
									{packages.map(pack => (
										<ListItem key={pack.order}>
											<Avatar>{pack.waypoint.indexDisplay || 'X'}</Avatar>
											<ListItemText
												disableTypography
												primary={
													<Typography>Pedido {pack.order}</Typography>
												}
												secondary={<PackageListItem {...pack} />}
											/>
											<ListItemSecondaryAction>
												<IconButton
													aria-label="Up"
													onClick={() =>
														onChangePackagePosition(pack.order, -1)
													}
												>
													<KeyboardArrowUpIcon />
												</IconButton>
												<IconButton
													aria-label="Down"
													onClick={() =>
														onChangePackagePosition(pack.order, 1)
													}
												>
													<KeyboardArrowDownIcon />
												</IconButton>
												<IconButton
													aria-label="Delete"
													onClick={() => onDeletePackage(pack.order)}
												>
													<DeleteIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</FlipMove>
							</List>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

PackagesList.propTypes = {
	route: PropTypes.object,
	onChangePackagePosition: PropTypes.func.isRequired,
	onDeletePackage: PropTypes.func.isRequired,
};

PackagesList.defaultProps = {
	route: {},
};

export default PackagesList;
