import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { showFooter } from 'actions/Setting';
import ManagePalletsContainer from './containers/index';
import { PageTitle } from '../ClosePallet/components/PageTitle';

const ManagePallet = props => {
	useEffect(() => {
		props.showFooter(false);
		return () => props.showFooter(true);
	});

	const handleSelectPallet = useCallback(
		pallet => props.history.push(`/app/manage-pallet/${pallet._id}`),
		[props.history]
	);
	const handlePalletListNav = useCallback(() => props.history.push('/app/close-pallet'), [
		props.history,
	]);

	return (
		<div className="app-wrapper">
			<PageTitle title="Gerenciar Pallets" />
			<Route
				exact
				path="/app/manage-pallet"
				render={() => <ManagePalletsContainer onSelectPallet={handleSelectPallet} />}
			/>
		</div>
	);
};

ManagePallet.propTypes = {
	history: PropTypes.object.isRequired,
	showFooter: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	showFooter,
};

export default connect(
	_state => {},
	mapActionsToProps
)(ManagePallet);
