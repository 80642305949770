import axios from 'axios';
import { INVENTORY_API_ADDRESS } from 'constants/InventoryAPI';
import {
	GET_PALLETS_REQUEST,
	GET_PALLETS_RESPONSE,
	GET_PALLETS_ERROR,
	ADD_BOX_TO_PALLET_REQUEST,
	ADD_BOX_TO_PALLET_RESPONSE,
	ADD_BOX_TO_PALLET_ERROR,
	RESET_INVENTORY_INPUT_STORE,
} from '../constants/ActionTypes';
import { handleRequestError } from './ErrorHandling';
import { sendNotification } from 'actions/Notifications';

export const getPallets = () => async dispatch => {
	try {
		dispatch({
			type: GET_PALLETS_REQUEST,
		});
		const authToken = localStorage.getItem('authToken');
		const response = await axios.get(`${INVENTORY_API_ADDRESS}/pallets/get-all`, {
			headers: {
				Authorization: authToken ? `Bearer ${authToken}` : '',
				'Content-Type': 'application/json',
			},
			params: {
				open: true,
				sent: false,
			},
		});

		dispatch({
			type: GET_PALLETS_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: GET_PALLETS_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const addBoxToPallet = (palletId, boxCode) => async dispatch => {
	try {
		dispatch({
			type: ADD_BOX_TO_PALLET_REQUEST,
		});
		const authToken = localStorage.getItem('authToken');
		const response = await axios.post(
			`${INVENTORY_API_ADDRESS}/pallets/input`,
			{
				palletId,
				boxCode,
			},
			{
				headers: {
					Authorization: authToken ? `Bearer ${authToken}` : '',
					'Content-Type': 'application/json',
				},
			}
		);
		dispatch(
			sendNotification({
				type: 'success',
				message: 'Sucesso! A caixa foi adicionada ao pallet desejado.',
			})
		);
		dispatch({
			type: ADD_BOX_TO_PALLET_RESPONSE,
			payload: {
				data: response.data,
			},
		});
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: ADD_BOX_TO_PALLET_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err, true, '/pallets/input'));
	}
};

export const resetInventoryInputStore = () => async dispatch => {
	dispatch({
		type: RESET_INVENTORY_INPUT_STORE,
	});
};
