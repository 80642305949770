// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BHxPLBSkE0N_IbCgaFzu {
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./routes/OrdersReturn/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ","sourcesContent":[".returnForm {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"returnForm": `BHxPLBSkE0N_IbCgaFzu`
};
export default ___CSS_LOADER_EXPORT___;
