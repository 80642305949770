// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.HHAe3DbSq_6By0Pke6uH th {
    white-space: pre-wrap !important;
}

.h1ZmHczMSjUtHQWshHhz {
    width: 100px;
    padding: 10px;
    white-space: pre-wrap !important;
}

.rwSfRGZquhNvEoLgsqLA {
    white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./routes/OrdersList/styles.css"],"names":[],"mappings":";AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["\n.tableHeader th {\n    white-space: pre-wrap !important;\n}\n\n.cell {\n    width: 100px;\n    padding: 10px;\n    white-space: pre-wrap !important;\n}\n\n.tooltip {\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeader": `HHAe3DbSq_6By0Pke6uH`,
	"cell": `h1ZmHczMSjUtHQWshHhz`,
	"tooltip": `rwSfRGZquhNvEoLgsqLA`
};
export default ___CSS_LOADER_EXPORT___;
