import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'utils/IntlMessages';
import logo from '../assets/images/logo-livup.png';
import { signIn } from 'actions/Auth';
import { testId } from '../utils/testId';

class Login extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
		};
	}

	handleFormSubmit = e => {
		e.preventDefault();
		const { email, password } = this.state;
		this.props.signIn({ email, password });
	};

	render() {
		const { email, password } = this.state;
		const { loading, error, authUser } = this.props;
		if (authUser) {
			return <Redirect to="/" />;
		}
		return (
			<div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
				<div className="app-login-main-content">
					<div className="app-logo-content d-flex align-items-center justify-content-center">
						<Link className="logo-lg" to="/" title="Jambo">
							<img src={logo} alt="LivUp logo" title="LivUp" />
						</Link>
					</div>

					<div className="app-login-content">
						<div className="app-login-header mb-4">
							<h1>
								<IntlMessages id="appModule.signInTitle" />
							</h1>
						</div>

						<div className="app-login-form">
							<form onSubmit={this.handleFormSubmit} {...testId('login-form')}>
								<fieldset>
									<TextField
										label={<IntlMessages id="appModule.email" />}
										fullWidth
										onChange={event =>
											this.setState({ email: event.target.value })
										}
										defaultValue={email}
										margin="normal"
										className="mt-1 my-sm-3"
										{...testId('email-input')}
									/>
									<TextField
										type="password"
										label={<IntlMessages id="appModule.password" />}
										fullWidth
										onChange={event =>
											this.setState({ password: event.target.value })
										}
										defaultValue={password}
										margin="normal"
										className="mt-1 my-sm-3"
										{...testId('password-input')}
									/>

									<div className="mb-3 d-flex align-items-center justify-content-between">
										<Button
											type="submit"
											variant="raised"
											color="primary"
											{...testId('login-submit')}
										>
											<IntlMessages id="appModule.signInButton" />
										</Button>
									</div>
								</fieldset>
							</form>
						</div>
					</div>
				</div>
				{loading && (
					<div className="loader-view">
						<CircularProgress />
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.auth.loading,
	error: state.auth.error,
	authUser: state.auth.authUser,
});

export default connect(
	mapStateToProps,
	{ signIn }
)(Login);
