import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		color: '#2A3644',
		fontSize: '18px',
		fontFamily: 'Roboto',
		fontWeight: 'bold',
		padding: '32px 36px 32px 36px',
		textAlign: 'center',
	},
});

const DcName = ({ name, classes }) => <Typography className={classes.root}>{name}</Typography>;

export default withStyles(styles)(DcName);
