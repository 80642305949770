import React from 'react';
import { postContainer, resetRedux } from 'actions/FrozenWMS';
import { connect } from 'react-redux';
import { sendNotification } from 'actions/Notifications.js';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'utils/IntlMessages';
import RegisterPack from '../components/RegisterPack';

class RegisterFrozenPack extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader
					location={this.props.location}
					title={<IntlMessages id="pages.wms.frozen" />}
					showBreadcrumb
					backButton
				/>
				<RegisterPack {...this.props} productType="frozen" />
			</div>
		);
	}
}
const mapStateToProps = state => ({
	finish: state.frozenWMS.finish,
	storage: state.frozenWMS.storage,
	loading: state.frozenWMS.loading,
	error: state.frozenWMS.error,
	check: state.frozenWMS.check,
});

export default connect(mapStateToProps, {
	postContainer,
	sendNotification,
	resetRedux,
})(RegisterFrozenPack);
