import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import BarCodeInput, { checkUsingRegex } from 'components/BarCodeInput';
import { EAN14REGEX } from 'constants/Regexes';
import styles from '../styles';
import ProductComponent from '../../TransferCheck/components/ProductComponent';
import Products from '../components/Products';

class InputComponent extends React.Component {
	state = {
		busyScanner: false,
	};

	handleBarCodeInputError = error => {
		if (error === 'busy') {
			this.props.errorNotification({
				message: 'Erro na leitura. Espere alguns instantes entre as leituras.',
			});
		} else if (error === 'invalid') {
			this.props.errorNotification({
				message: 'Código inválido',
			});
		}
	};

	handleCheckValid = value => {
		if (!this.state.busyScanner && checkUsingRegex(value, EAN14REGEX)) {
			this.setState({
				busyScanner: true,
			});
			setTimeout(() => {
				this.setState({
					busyScanner: false,
				});
			}, 1000);
			return true;
		}
		return false;
	};

	handleRegisterSubmission = value => {
		if (checkUsingRegex(value, EAN14REGEX)) {
			this.props.addBoxToPallet(value);
		}
	};

	render() {
		const { pallet, pendingConfirmationBox, classes, back } = this.props;
		return (
			<Grid container direction="column">
				<Grid item>
					<Paper className={classes.palletProductsContainer}>
						{pallet.items ? (
							<Products items={pallet.items} />
						) : (
							<Typography variant="h6">
								<b>Pallet vazio</b>
							</Typography>
						)}
					</Paper>
				</Grid>
				<Grid item>
					<Typography variant="h6">
						{Object.keys(pendingConfirmationBox).length === 0 ? (
							<b>Leia a caixa</b>
						) : (
							<b>Leia a caixa novamente para confirmar</b>
						)}
					</Typography>
					<BarCodeInput
						onMatch={this.handleRegisterSubmission}
						checkValid={this.handleCheckValid}
						onError={this.handleBarCodeInputError}
						busy={this.state.busyScanner}
						keepFocus
						debounce
					/>
				</Grid>

				<Grid item className={classes.inputComponentBackButton}>
					<Button onClick={back} className={classes.button}>
						<Typography variant="subtitle1" className={classes.buttonText}>
							Voltar
						</Typography>
					</Button>
				</Grid>
			</Grid>
		);
	}
}

InputComponent.propTypes = {
	pallet: PropTypes.object.isRequired,
	pendingConfirmationBox: PropTypes.object.isRequired,
	addPendingConfirmationPackage: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
};

InputComponent.defaultProps = {};

export default withStyles(styles)(InputComponent);
