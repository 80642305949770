import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
	root: {
		height: '100px',
		display: 'flex',
		alignItems: 'center',
		padding: '20px 16px 20px 16px',
		marginBottom: '25px',
	},
	instruction: {
		fontFamily: 'Roboto',
		color: '#888888',
		fontSize: '14px',
	},
	product: {
		fontFamily: 'Roboto',
		color: '#2A3644',
		fontSize: '18px',
		fontWeight: 'bold',
	},
});

const ProductBox = ({ product, classes, onClick }) => (
	<Paper className={classes.root} onClick={onClick}>
		{!product ? (
			<Grid container direction="row">
				{/* <ScannerIcon/> */}
				<Typography className={classes.instruction}>
					O nome do último produto lido irá aparecer aqui.
				</Typography>
			</Grid>
		) : (
			<Grid container direction="row">
				<Typography className={classes.product}>{product}</Typography>
			</Grid>
		)}
	</Paper>
);

export default withStyles(styles)(ProductBox);
