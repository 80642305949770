import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

export const PageTitle = ({ title }) => {
	const classes = useStyles();
	return <Typography className={classes.typography}>{title}</Typography>;
};

PageTitle.propTypes = {
	title: PropTypes.string.isRequired,
};
