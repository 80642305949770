import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { debounce } from 'utils/timingFunctions';

class OrderInput extends Component {
	state = {
		orderID: '',
	};

	debounceCheck = debounce(event => {
		const valid = this.testRegex(event.target.value);
		if (valid) {
			this.props.onFormChange(event);
		} else {
			this.props.onError();
		}
		this.setState({
			orderID: '',
		});
	}, 300);

	testRegex(str) {
		const regex = new RegExp(this.props.regex);
		return regex.test(str);
	}

	handleChange = event => {
		this.setState({ orderID: event.target.value });
		this.debounceCheck(event);
	};

	render() {
		const classes = this.props.classes;
		let message = 'ID do pedido';
		if (this.props.placeHolder) message = this.props.placeHolder;
		return (
			<div className="row">
				<div className="col-sm-12">
					<FormControl className="mb-3" fullWidth>
						<InputLabel htmlFor="order-id-input">{message}</InputLabel>
						<Input
							autoFocus
							id="order-id-input"
							value={this.state.orderID}
							onChange={this.handleChange}
						/>
					</FormControl>
				</div>
			</div>
		);
	}
}

export default OrderInput;
