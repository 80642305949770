import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';

export default {
	palette: {
		primary: {
			light: blueGrey[200],
			main: blueGrey[700],
			dark: blueGrey[900],
		},
		secondary: {
			light: red[300],
			main: red.A200,
			dark: red[700],
		},
		cancel: {
			light: grey[200],
			main: grey[600],
			dark: grey[900],
		},
		li: {
			primary: blueGrey[50],
			secondary: '#ffffff',
		},
		checkLi: {
			primary: green[900],
			secondary: green[800],
		},
	},
	status: {
		danger: 'orange',
	},
	typography: {
		button: {
			fontWeight: 400,
			textAlign: 'capitalize',
		},
	},
};
