import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'utils/IntlMessages';
import ContainerHeader from '../../components/ContainerHeader/index';
import OrdersReturnContainer from './containers/OrdersReturnContainer';

class OrdersReturn extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<ContainerHeader location={this.props.location} title="Retorno de Pedidos" />
				<OrdersReturnContainer />
			</div>
		);
	}
}

OrdersReturn.propTypes = {
	location: PropTypes.object.isRequired,
};

export default OrdersReturn;
