import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Invoice from './Invoice';

const InvoiceList = props => {
  const { data, onInvoiceRequested } = props;
  return (
    <Fragment>
      {data.map((invoice, index) => (
        <Invoice
          key={invoice.order || index}
          invoice={invoice}
          onInvoiceRequested={onInvoiceRequested}
        />
      ))}
    </Fragment>
  );
};

InvoiceList.propTypes = {
  data: PropTypes.array.isRequired,
  onInvoiceRequested: PropTypes.func,
};

InvoiceList.defaultProps = {
  onInvoiceRequested: () => {},
};

export default InvoiceList;
