import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button } from '@material-ui/core';
import { useStyles } from './styles';

export const BaseModal = ({ actions, children, ...props }) => {
	const classes = useStyles(props);

	return (
		<Paper className={classes.paper}>
			<div className={classes.modalContent}>{children}</div>
			<div className={classes.divider} />
			<Grid
				className={classes.buttonsContainer}
				container
				direction="row"
				justify={props.spaceBetween ? 'space-between' : 'flex-end'}
			>
				{actions.map(action => (
					<Grid item>
						<Button className={classes.button} onClick={action.onClick}>
							{action.text}
						</Button>
					</Grid>
				))}
			</Grid>
		</Paper>
	);
};

BaseModal.propTypes = {
	actions: PropTypes.array.isRequired,
	children: PropTypes.node.isRequired,
};
