import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Payment from '@material-ui/icons/Payment';
import Divider from '@material-ui/core/Divider';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Book from '@material-ui/icons/Book';
import styles from '../styles.css';

class PackageListItem extends React.Component {
	renderAddress() {
		const { destination } = this.props;
		return (
			<React.Fragment>
				<Typography variant="caption">
					{`${destination.street}, ${destination.number}`}
				</Typography>
				<Typography variant="caption">
					{`${destination.neighborhood || ''} - ${destination.city} - ${
						destination.state
					}`}
				</Typography>
			</React.Fragment>
		);
	}

	renderEta() {
		const { eta } = this.props;
		if (eta) {
			return (
				<h4 className={styles.packageDetails}>
					<strong>ETA:</strong>
					{` ${Math.ceil(eta / 60)} min`}
				</h4>
			);
		}
		return null;
	}

	renderCost() {
		const { cost } = this.props;
		if (cost) {
			return (
				<h4 className={styles.packageDetails}>
					<strong>custo: </strong>
					{cost.toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL',
					})}
				</h4>
			);
		}
		return null;
	}

	renderError() {
		const { error } = this.props;
		if (error) {
			return `⚠️ ${error}`;
		}
		return null;
	}

	renderObservations() {
		const { insideObservations } = this.props;
		if (insideObservations) {
			return (
				<Typography variant="caption" gutterBottom className={styles.highlightPackageInfo}>
					<NotificationsActive className={styles.icon} />
					{`${insideObservations.text} - ${insideObservations.author}`}
				</Typography>
			);
		}
		return null;
	}

	renderPaymentStatus() {
		const { paymentStatus, isPaymentOffline } = this.props;
		if (paymentStatus) {
			const isOnDelivery = paymentStatus.id === 'on_delivery';
			const notPaid = paymentStatus.id !== 'paid' && paymentStatus.id !== 'on_delivery';

			let paymentMsg = paymentStatus.pt;
			if (isOnDelivery && !isPaymentOffline) {
				paymentMsg = 'O pedido não precisa ser cobrado';
			}

			return (
				<Typography
					variant="caption"
					gutterBottom
					className={notPaid || isPaymentOffline ? styles.highlightPackageInfo : null}
				>
					<Payment className={styles.icon} />
					{paymentMsg}
					{notPaid && ' pedido não será chamado'}
				</Typography>
			);
		}
		return null;
	}

	renderProductTypes() {
		const { productTypes } = this.props;
		if (productTypes && productTypes.length > 0) {
			return (
				<Typography variant="body2">
					<Book className={styles.icon} />
					{productTypes.join(', ')}
				</Typography>
			);
		}
		return null;
	}

	render() {
		return (
			<div>
				{this.renderAddress()}
				{this.renderEta()}
				{this.renderCost()}
				{this.renderProductTypes()}
				{this.renderPaymentStatus()}
				{this.renderError()}
				{this.renderObservations()}
				<Divider className={styles.divider} light />
			</div>
		);
	}
}

PackageListItem.propTypes = {
	destination: PropTypes.object,
	eta: PropTypes.number,
	error: PropTypes.string,
	cost: PropTypes.number,
	isPaymentOffline: PropTypes.bool,
	insideObservations: PropTypes.shape({
		author: PropTypes.string,
		text: PropTypes.string,
	}),
	paymentStatus: PropTypes.object,
	productTypes: PropTypes.array,
};

PackageListItem.defaultProps = {
	destination: {
		street: PropTypes.string,
		number: PropTypes.number,
		complement: PropTypes.string,
		neighborhood: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		zip_code: PropTypes.string,
		coordinates: {
			latitude: PropTypes.number,
			longitude: PropTypes.number,
		},
	},
	eta: 0,
	error: null,
	cost: 0,
	isPaymentOffline: false,
	insideObservations: null,
	paymentStatus: {},
	productTypes: [],
};

export default PackageListItem;
