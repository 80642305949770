import moment from "moment";

export function extractBatchFromProductCode(productCode) {
	const day = `${productCode.slice(6, 8)}`;
	const month = `${productCode.slice(8, 10)}`;
	const year = `20${productCode.slice(10, 12)}`;
	return `${year}${month}${day}`;
}

function extractSkuFromBarcode(barcode) {
	return barcode.slice(2, 6);
}

function convertSku(sku) {
	return String.fromCharCode(sku + 64);
}

function extractSkuFromProduct(productCode) {
	return `${convertSku(Number(productCode.slice(1, 3)))}${productCode.slice(3, 6)}`;
}

export const compareBatchWithPackageCode = productCode => batch => {
	const batchDateString = extractBatchFromProductCode(batch.code);
	const productDateString = productCode.slice(9, 17);
	const sku = extractSkuFromProduct(batch.code);
	const productSku = extractSkuFromBarcode(productCode);
	return batchDateString === productDateString && sku === productSku;
};

export const isManufacturingOutOfWithdrawalPeriod = (product, manufacturingDate) => {
	const today = moment();
	const daysToExpiration = moment(manufacturingDate).add(product.expiration, 'days').diff(today, 'days');
	return  daysToExpiration < product.withdrawalPeriod;
}

export const isExpirationOutOfWithdrawalPeriod = (expirationDate, withdrawalPeriod) => {
	const today = moment();
	const daysToExpiration = moment(expirationDate).diff(today, 'days');
	return  daysToExpiration < withdrawalPeriod;
}
