import React from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class OrderRouteSelector extends React.Component {
  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{this.props.name}</FormLabel>
        <RadioGroup
          aria-label="type"
          name="orderroute"
          value={this.props.selected}
          onChange={this.handleChange}
        >
          {this.props.options.map((option, index) => (
            <FormControlLabel
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

OrderRouteSelector.propTypes = {
  type: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};

export default OrderRouteSelector;
