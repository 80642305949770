export const styles = theme => ({
	singleCell: {
		borderRight: `1px solid ${theme.palette.primary.light}`,
		padding: '4px 24px 4px 24px',
		textAlign: 'center',
		width: '50%',
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
	orderCell: {
		height: '100%',
		fontSize: '1.4rem',
		fontWeight: 'bolder',
	},
	fullHeight: {
		height: '100%',
	},
	checkedLine: {
		textDecoration: 'line-through',
	},
	checkedOpacity: {
		opacity: '.4',
	},
	gridCell: {
		borderLeft: `1px solid ${theme.palette.primary.light}`,
		borderTop: `1px solid ${theme.palette.primary.light}`,
		borderRight: `1px solid ${theme.palette.primary.light}`,
		height: '100%',
	},
	borderSeparator: {
		marginBottom: '20px',
	},
	rightBorder: {
		borderRight: `1px solid ${theme.palette.primary.light}`,
	},
	textAlign: {
		textAlign: 'center',
	},
	onHover: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	routeDetailsHeader: {
		textAlign: 'center',
		fontSize: '1.2rem',
		fontWeight: 'bolder',
		padding: '10px 0 10px 0',
	},
	columnSubHeaders: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '1rem',
		width: `50%`,
		padding: '10px 0 10px 0',
	},
	headersPadding: {
		padding: '10px 0 10px 0',
	},
	routeNumber: {
		fontSize: '1.4rem',
		padding: '10px 24px 10px 24px',
		width: '100%',
		marginBottom: '20px',
	},
	bottomBorder: {
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
	routeForm: {
		height: '300px',
		width: '40%',
	},
	fullWidth: {
		width: '100%',
	},
	subHeadersHeight: {
		height: '80px',
	},
	getRouteButton: {
		width: '100%',
		textAlign: 'center',
	},
	fallBackMessageMargin: {
		margin: '0 10px 0 10px',
	},
	justifyContentCenter: {
		justifyContent: 'center',
	},
	loadingAndErrorContainerHeight: {
		height: '400px',
	},
	packageNumberConfirmationButton: {
		width: '100%',
		height: '100%',
		textTransform: 'none !important',
	},
});
