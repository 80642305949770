import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'utils/IntlMessages';
import InventoryInputContainer from './containers/InventoryInputContainer';

class InventoryInput extends React.Component {
	render() {
		return (
			<div className="app-wrapper">
				<InventoryInputContainer />
			</div>
		);
	}
}

export default InventoryInput;
