import {
	SIGNIN_REQUEST,
	SIGNIN_RESPONSE,
	SIGNIN_ERROR,
	SIGNOUT_REQUEST,
	SIGNOUT_RESPONSE,
	SIGNOUT_ERROR,
} from 'constants/ActionTypes';

const initialState = {
	loading: false,
	error: null,
	authUser: JSON.parse(localStorage.getItem('user')),
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SIGNIN_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case SIGNIN_RESPONSE: {
			return {
				...state,
				loading: false,
				authUser: action.payload,
			};
		}
		case SIGNIN_ERROR: {
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		}
		case SIGNOUT_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case SIGNOUT_RESPONSE: {
			return {
				...state,
				loading: false,
				authUser: null,
			};
		}
		case SIGNOUT_ERROR: {
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		}
		default:
			return state;
	}
};
