import { ERRORS } from '../constants/Errors';

export const errorTranslator = (err, route) => {
	const originalMessage = err.response ? err.response.data.message : err.message;
	if (!route || !ERRORS[route]) {
		return originalMessage;
	}
	const ERROR_TYPES = Object.entries(ERRORS[route]);

	for (const ERROR_TYPE in ERROR_TYPES) {
		if (originalMessage.includes(ERROR_TYPES[ERROR_TYPE][1].error)) {
			return ERROR_TYPES[ERROR_TYPE][1].message;
		}
	}
	return originalMessage;
};
