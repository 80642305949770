import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PropTypes } from 'prop-types';
import { getSaleInvoice, requestSaleInvoice } from 'actions/FiscalPrinting';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { styles } from '../styles';
import InvoiceList from '../components/InvoiceList';

class FiscalPrintingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: '',
    };
  }

  handleOrderChange = event => {
    const { target } = event;
    if (/^[1-9]\d*$/.test(target.value)) {
      this.setState({ order: target.value });
    }
  };

  handleGetSaleInvoice = () => {
    if (!this.state.order || this.state.order.length < 1) return;
    this.props.getSaleInvoice(parseInt(this.state.order));
  };

  handleRequestSaleInvoice = () => {
    if (!this.state.order || this.state.order.length < 1) return;
    this.props.requestSaleInvoice(parseInt(this.state.order));
  };

  renderFilters() {
    const { classes, loading } = this.props;

    return (
      <Grid
        container
        xs={12}
        justify="center"
        alignItems="center"
        spacing={24}
        style={{ marginBottom: '10px' }}
      >
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <h2>Digite o número do pedido</h2>
                </Grid>
                <TextField
                  label="Número"
                  value={this.state.order}
                  onChange={this.handleOrderChange}
                />
                <Grid item justify="center">
                  {loading ? (
                    <CircularProgress size={50} />
                  ) : (
                    <Button
                      variant="raised"
                      color="primary"
                      onClick={this.handleGetSaleInvoice}
                      className={`jr-btn text-blue-gray ${classes.printButton}`}
                      disabled={
                        !this.state.order || this.state.order.length < 1
                      }
                    >
                      Buscar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  renderResults() {
    const { data } = this.props;

    return (
      <InvoiceList
        data={data}
        onInvoiceRequested={this.handleRequestSaleInvoice}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid container direction="column">
          {this.renderFilters()}
        </Grid>
        <Grid container direction="column">
          {this.renderResults()}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.fiscalPrinting.loading,
  data: state.fiscalPrinting.data || [],
});

FiscalPrintingContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  getSaleInvoice: PropTypes.func.isRequired,
  requestSaleInvoice: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

FiscalPrintingContainer.defaultProps = {};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getSaleInvoice,
    requestSaleInvoice,
  })
)(FiscalPrintingContainer);
