import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Modal } from '@material-ui/core';
import { sendNotification, errorNotification } from 'actions/Notifications';
import { closePallet } from 'actions/ClosePallets';
import Pallet from '../../InventoryInput/components/Pallet';
import { checkPackageBarcode, isBoxInPallet, getBoxInfoFromBarcode } from '../logic';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { PaperHeader } from '../components/Header';
import PseudoBarCodeInput from '../../InventoryInput/components/PseudoBarCodeInput';
import BoxInfoCard from '../../InventoryInput/components/BoxInfoCard';
import { Loading } from '../components/Loading';
import { FooterButton } from '../components/FooterButton';
import { FinishModal } from '../components/FinishModal';

const BoxReadContainer = props => {
	const [readError, setReadError] = useState(false);
	const [box, setBox] = useState();
	const [confirmed, setConfirmed] = useState(false);
	const [closed, setClosed] = useState(false);

	useEffect(() => {
		if (props.error) {
			setBox();
			props.errorNotification(
				props.error.message === 'Transação não encontrada'
					? {
							message:
								'Epa! Esta caixa não pertence a este pallet. Escaneie outra caixa.',
					  }
					: props.error
			);
		}
	}, [props.error]);

	useEffect(() => {
		if (props.palletClosed) {
			props.sendNotification({ type: 'success', message: 'Pallet fechado com sucesso!' });
			setClosed(true);
		}
	}, [props.palletClosed]);

	const confirmPallet = useCallback(() => setConfirmed(true), [setConfirmed]);

	const handleBoxMatch = useCallback(
		barcode => {
			setReadError(false);
			if (!isBoxInPallet(props.pallet, barcode)) {
				setReadError(true);
				props.errorNotification({
					message: 'Epa! Esta caixa não pertence a este pallet. Escaneie outra caixa.',
				});
				return;
			}
			setBox(getBoxInfoFromBarcode(barcode, props.products));
			props.closePallet(props.pallet, barcode);
		},
		[props.pallet]
	);

	const handleReadError = useCallback(() => {
		setReadError(true);
		return props.errorNotification({
			message: 'Desculpe, não conseguimos identificar esse código. Tente novamente.',
		});
	}, [setReadError, props.errorNotification]);

	if (!props.pallet) {
		props.onNullPallet();
		return null;
	}

	if (props.alreadyClosed) {
		props.errorNotification({
			message: 'Epa! Esse pallet já está fechado. Escolha outro pallet.',
		});
		props.onFinish();
	}

	return (
		<React.Fragment>
			<Modal open={!confirmed}>
				<ConfirmationModal
					pallet={props.pallet}
					onConfirm={confirmPallet}
					onCancel={props.onFinish}
				/>
			</Modal>
			<Modal open={closed}>
				<FinishModal onConclude={props.onFinish} />
			</Modal>
			<PaperHeader text="Pallet selecionado para fechar" type="primary" />
			<Grid container>
				<Grid item>
					<Pallet pallet={props.pallet} index={props.pallet.index} />
				</Grid>
				<Grid item xs>
					<PaperHeader
						text={
							readError || props.error
								? 'Erro ao escanear a caixa'
								: 'Passe o leitor em uma caixa do pallet para confirmar'
						}
						type={readError || props.error ? 'error' : 'secondary'}
					/>
					<BoxInfoCard box={box} border={!box} error={readError || props.error} />
				</Grid>
			</Grid>
			{props.closePalletLoading ? <Loading linear text="Fechando pallet..." /> : null}
			<FooterButton text="Voltar" onClick={props.onFinish} />
			{confirmed && !props.closePalletLoading ? (
				<PseudoBarCodeInput
					keepFocus
					debounce
					checkValid={checkPackageBarcode}
					onMatch={handleBoxMatch}
					onError={handleReadError}
				/>
			) : null}
		</React.Fragment>
	);
};

BoxReadContainer.propTypes = {
	pallet: PropTypes.object.isRequired,
	products: PropTypes.object.isRequired,
	closePalletLoading: PropTypes.bool,
	palletClosed: PropTypes.bool,
	alreadyClosed: PropTypes.bool,
	error: PropTypes.object,
	closePallet: PropTypes.func.isRequired,
	onNullPallet: PropTypes.func.isRequired,
	onFinish: PropTypes.func.isRequired,
	sendNotification: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
};

BoxReadContainer.defaultProps = {
	closePalletLoading: false,
	palletClosed: false,
	alreadyClosed: false,
	error: null,
};

const mapActionsToProps = {
	sendNotification,
	errorNotification,
	closePallet,
};

const mapStateToProps = state => ({
	pallet: state.closePallets.pallet,
	products: state.closePallets.products,
	closePalletLoading: state.closePallets.closePalletLoading,
	palletClosed: state.closePallets.palletClosed,
	alreadyClosed: state.closePallets.alreadyClosed,
	error: state.closePallets.error,
});

export default connect(
	mapStateToProps,
	mapActionsToProps
)(BoxReadContainer);
