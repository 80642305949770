import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { BaseModal } from '../../ClosePallet/components/BaseModal';

export const RejectedBatchModal = ({ onClick }) => (
	<BaseModal matchContent actions={[{ text: 'Concluir', onClick }]}>
		<Typography>O lote deste produto foi rejeitado. DESCARTE IMEDIATAMENTE.</Typography>
	</BaseModal>
);

RejectedBatchModal.propTypes = {
	onClick: PropTypes.func.isRequired,
};
