import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { fetchAvailablePeriods, selectPeriod } from 'actions/Periods';

class PeriodSelect extends React.Component {
	componentDidMount() {
		if (this.props.availablePeriods.length === 0) {
			this.props.fetchAvailablePeriods();
		}
	}

	handleChange = e => {
		this.props.selectPeriod(e.target.value);
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	render() {
		const { availablePeriods, selectedPeriod } = this.props;

		return (
			<form className="row" autoComplete="off">
				<div className="col-lg-12 col-sm-12 col-12">
					<FormControl className="w-100 mb-2" fullWidth>
						<InputLabel htmlFor="route-select">Selecionar Período</InputLabel>
						<Select
							value={selectedPeriod || ''}
							onChange={this.handleChange}
							input={<Input id="period-select" />}
						>
							{availablePeriods.map(period => (
								<MenuItem value={period.id} key={period.id}>
									{period.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</form>
		);
	}
}

function mapStateToProps(state) {
	return {
		availablePeriods: state.periods.available,
		selectedPeriod: state.periods.selected,
		loading: state.periods.loading,
		error: state.periods.error,
	};
}

PeriodSelect.propTypes = {
	availablePeriods: PropTypes.array,
	selectedPeriod: PropTypes.string,
	loading: PropTypes.bool,
	error: PropTypes.string,
	onChange: PropTypes.func,
};

PeriodSelect.defaultProps = {
	availablePeriods: [],
	selectedPeriod: '',
	loading: false,
	error: null,
};

export default connect(mapStateToProps, { fetchAvailablePeriods, selectPeriod })(PeriodSelect);
