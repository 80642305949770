interface SplittedInfo {
  kitchen: string;
  skuCharacterPosition: number;
  skuNumber: string;
  expiration: string;
}

function splitBarcode(barcode: string): SplittedInfo {
  return {
    kitchen: barcode.substr(0, 1),
    skuCharacterPosition: parseInt(barcode.substr(1, 2)),
    skuNumber: barcode.substr(3, 3),
    expiration: barcode.substr(6, 6),
  };
}

function generateSkuCharacterFromPosition(position: number) {
  return String.fromCharCode(96 + position).toUpperCase();
}

function convertExpirationStringToDate(expiration: string): Date {
  const day = parseInt(expiration.substr(0, 2));
  const month = parseInt(expiration.substr(2, 2)) - 1;
  const year = parseInt(`20${expiration.substr(4, 2)}`);

  return new Date(year, month, day);
}

export interface BagInfo {
  barcode: string;
  sku: string;
  kitchen: string;
  expiration: Date;
}

export interface ParseOptions {
  expirationModeCut?: Date;
  getExpiration?: (sku?: string, barcode?: string) => number;
}

export function parseBagBarcode(barcode: string, options?: ParseOptions): BagInfo {
  const { expirationModeCut, getExpiration } = options || {};

  const {
    skuCharacterPosition,
    skuNumber,
    kitchen,
    expiration: rawExpiration,
  } = splitBarcode(barcode);

  const skuCharacter = generateSkuCharacterFromPosition(skuCharacterPosition);
  const sku = `${skuCharacter}${skuNumber}`;
  const expiration = convertExpirationStringToDate(rawExpiration);

  // TODO: remover quando não existirem mais produtos com etiquetas antigas
  const isLegacyCode = expirationModeCut && expirationModeCut > expiration;
  if (isLegacyCode) {
    const addToExpiration = getExpiration && getExpiration(sku, barcode) || 0;

    expiration.setDate(expiration.getDate() + addToExpiration);
  }

  return {
    barcode,
    sku,
    kitchen,
    expiration,
  };
}
