// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GHOsNL9azfyF0TbIdcT2 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/DateSelect/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[".form {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `GHOsNL9azfyF0TbIdcT2`
};
export default ___CSS_LOADER_EXPORT___;
