import {
	GET_ORDERS_LIST_REQUEST,
	GET_ORDERS_LIST_RESPONSE,
	GET_ORDERS_LIST_ERROR,
} from '../constants/ActionTypes';

const initialState = {
	loading: false,
	success: false,
	error: null,
	data: [],
};

const OrdersList = (state = initialState, action) => {
	switch (action.type) {
		case GET_ORDERS_LIST_REQUEST:
			return {
				...state,
				loading: !action.payload.runOnBackground,
				success: false,
			};
		case GET_ORDERS_LIST_RESPONSE:
			return {
				...state,
				data: [...action.payload.data],
				loading: false,
				success: true,
			};
		case GET_ORDERS_LIST_ERROR:
			return {
				...state,
				error: action.payload.error,
				loading: false,
				success: false,
			};

		default:
			return state;
	}
};

export default OrdersList;
