/* global google */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import styles from '../styles.css';

const ASCII_A = 65;

const OUT_OF_COVERAGE_ICON = {
	url: 'http://maps.google.com/mapfiles/ms/icons/yellow.png',
	scaledSize: new google.maps.Size(43, 43),
	labelOrigin: new google.maps.Point(21, 14),
};

const INSIDE_COVERAGE_ICON = {
	url: 'http://maps.google.com/mapfiles/ms/icons/blue.png',
	scaledSize: new google.maps.Size(43, 43),
	labelOrigin: new google.maps.Point(21, 14),
};

const Map = withGoogleMap(props => (
	<GoogleMap defaultZoom={13} center={props.center} ref={props.onMapMounted}>
		{props.markers
			? props.markers.map(marker => (
					<Marker
						key={marker.order}
						position={marker.position}
						label={marker.indexDisplay}
						icon={
							marker.outOfCoverageArea ? OUT_OF_COVERAGE_ICON : INSIDE_COVERAGE_ICON
						}
					/>
			  ))
			: null}
		<Marker key="marker-CD" position={props.cdMarker.position} label="A" />
	</GoogleMap>
));

class RouteMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center: {
				lat: Number(props.cdLocation.coordinates.latitude),
				lng: Number(props.cdLocation.coordinates.longitude),
			},
			cdMarker: {
				position: new google.maps.LatLng(
					Number(props.cdLocation.coordinates.latitude),
					Number(props.cdLocation.coordinates.longitude)
				),
			},
		};
		this.map = undefined;
		this.mapDidMount = this.mapDidMount.bind(this);
	}

	componentDidMount() {
		this.updateBounds();
	}

	componentDidUpdate() {
		this.updateBounds();
	}

	mapDidMount(map) {
		this.map = map;
		this.updateBounds();
	}

	updateBounds() {
		if (this.map) {
			const { packages } = this.props.route;
			if (!packages) {
				return null;
			}
			const bounds = new google.maps.LatLngBounds();
			packages.forEach(pack => {
				const position = new google.maps.LatLng(
					pack.destination.coordinates.latitude,
					pack.destination.coordinates.longitude
				);
				bounds.extend(position);
			});
			bounds.extend(this.state.cdMarker.position);
			this.map.fitBounds(bounds);
		}
	}

	parseMarkers() {
		const { packages } = this.props.route;
		if (!packages) {
			return null;
		}
		const markers = packages.map(pack => ({
			position: new google.maps.LatLng(
				pack.destination.coordinates.latitude,
				pack.destination.coordinates.longitude
			),
			order: pack.order,
			indexDisplay: pack.waypoint.indexDisplay,
			outOfCoverageArea: pack.outOfCoverageArea,
		}));

		return markers;
	}

	render() {
		return (
			<Map
				containerElement={
					<div className={`${styles.routeMap} embed-responsive embed-responsive-21by9`} />
				}
				mapElement={<div className={`${styles.routeMap} embed-responsive-item`} />}
				onMapMounted={this.mapDidMount}
				center={this.state.center}
				markers={this.parseMarkers()}
				cdMarker={this.state.cdMarker}
			/>
		);
	}
}

RouteMap.propTypes = {
	route: PropTypes.object,
	cdLocation: PropTypes.shape({
		coordinates: PropTypes.shape({
			latitude: PropTypes.string,
			longitude: PropTypes.string,
		}),
	}),
};

RouteMap.defaultProps = {
	route: {},
	cdLocation: {
		// LivUp Amaro As default value
		coordinates: {
			latitude: '-46.699449',
			longitude: '-23.566597',
		},
	},
};

export default RouteMap;
