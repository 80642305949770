const env = process.env.NODE_ENV;

export const ROUTING_MODALS = Object.freeze({
	LOGGI: 'loggi',
	FIORINO: 'fiorino',
});

export const ROUTING_ENDPOINTS = Object.freeze({
	[ROUTING_MODALS.LOGGI]: '/routing',
	[ROUTING_MODALS.FIORINO]: '/fiorino_routing',
});
