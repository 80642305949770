import PropTypes from "prop-types";
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { DISPATCH_SEARCH_TYPES } from "constants/Dispatch";
import { styles } from "../constants/styles";

class LastRoutesCard extends Component {
  renderRoutes() {
    if (
      this.props.lastRoutesNumbers &&
      this.props.lastRoutesNumbers.length > 0
    ) {
      const { classes } = this.props;
      const cards = [];
      this.props.lastRoutesNumbers.forEach((routeNumber, index) => {
        if (index < 5) {
          cards.push(
            <Grid item justify="center">
              <Paper className={classes.lastRoutesCard}>
                <Typography align="center">{routeNumber}</Typography>
              </Paper>
            </Grid>
          );
        }
      });
      return cards;
    }
    return null;
  }
  renderComponent() {
    const { classes } = this.props;
    if (
      this.props.lastRoutesNumbers &&
      this.props.lastRoutesNumbers.length > 0
    ) {
      return (
        <Grid
          container
          spacing={8}
          className={classes.lastRoutesContainer}
          alignItems="center"
        >
          <Grid item xs={1}>
            <Tooltip
              id="tooltip-icon"
              title="Limpar Rotas Recentes"
              placement="bottom"
            >
              <IconButton
                aria-label="Delete"
                onClick={this.props.deleteRecentRoutes}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} justify="center">
            <Typography>Últimas Rotas:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={8}>
              {this.renderRoutes()}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  }

  render() {
    return this.props.searchType === DISPATCH_SEARCH_TYPES.ROUTE
      ? this.renderComponent()
      : null;
  }
}

LastRoutesCard.propTypes = {
  lastRoutesNumbers: PropTypes.array.isRequired,
  deleteRecentRoutes: PropTypes.func.isRequired,
  searchType: PropTypes.string,
};

LastRoutesCard.defaultProps = {
  searchType: "0",
};

export default withStyles(styles)(LastRoutesCard);
