import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { styles } from '../constants/styles';

const RouteOrder = ({
  order,
  onClick,
  classes,
  pickingTypes,
  onOpenConfirmationDialog,
}) => {
  const handleOnClick = ({ orderNumber, packingKind, pack }) => {
    onClick({ orderNumber, packingKind, pack });
  };

  const handleOpenConfirmationDialog = (totalPacks, packingKind) => {
    onOpenConfirmationDialog(order.order, totalPacks, packingKind);
  };

  return (
    <Grid container>
      <Grid container justify="space-around" direction="row">
        <Grid item xs={6} className={classes.orderCell}>
          <Grid
            container
            className={`${classes.fullHeight} ${classes.textAlign} ${
              order.done ? classes.checkedLine : ''
            }`}
            alignItems="center"
            justify="center"
          >
            {order.order}
            <Typography variant="body2" gutterBottom>
              {order.isPaymentOffline && '⚠️ Pagamento na Entrega'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {order.isReceivePaymentOnly && (
            <Grid container alignItems="center" justify="center">
              <strong>Somente cobrança</strong>
            </Grid>
          )}
          {pickingTypes.map(packingKind => (
            <React.Fragment key={packingKind}>
              {order.packing[packingKind].packages.length > 0 &&
                !order.isReceivePaymentOnly &&
                !order.packing[packingKind].printingError && (
                  <Grid
                    container
                    className={classes.fullHeight}
                    direction="row"
                  >
                    {order.packing[packingKind].packages.map(pack => (
                      <Grid
                        key={pack.code}
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        onClick={() =>
                          handleOnClick({
                            orderNumber: order.order,
                            packingKind,
                            pack,
                          })
                        }
                        className={
                          pack.checked
                            ? `${classes.checkedOpacity} ${classes.checkedLine} ${classes.gridCell}`
                            : `${classes.onHover} ${classes.gridCell}`
                        }
                      >
                        {pack.code}
                      </Grid>
                    ))}
                  </Grid>
                )}

              {!order.isReceivePaymentOnly &&
                order.packing[packingKind].printingError &&
                order.totalPacks[packingKind].number > 0 && (
                  <Button
                    variant="raised"
                    color="primary"
                    disabled={order.done}
                    className={`jr-btn text-blue-gray ${classes.packageNumberConfirmationButton}`}
                    onClick={
                      order.done
                        ? null
                        : () =>
                            handleOpenConfirmationDialog(
                              order.totalPacks[packingKind].number,
                              packingKind
                            )
                    }
                  >
                    As sacolas não foram lidas. Clique aqui para confirmar o
                    pedido.
                  </Button>
                )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(RouteOrder);

RouteOrder.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  pickingTypes: PropTypes.array.isRequired,
  onOpenConfirmationDialog: PropTypes.func.isRequired,
};

RouteOrder.defaultProps = {
  onClick: () => {},
};
