import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
	},
	buttonBase: {
		width: '100%',
		height: '100%',
		display: 'inline',
	},
	container: {
		height: '70%',
		width: '100%',
	},
	title: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fonzSize: '1.3125rem',
		fontWeight: 500,
		marginTop: `${3 * theme.spacing.unit}px`,
		opacity: 0.9,
	},
});

class OptionCard extends Component {
	render() {
		const { name, classes, children, handleClick } = this.props;
		return (
			<Paper className={classes.root}>
				<ButtonBase className={classes.buttonBase} onClick={handleClick}>
					<Typography variant="h5" className={classes.title}>
						{name}
					</Typography>
					<Grid
						direction="column"
						container
						justify="flex-start"
						alignItems="center"
						className={classes.container}
					>
						{children}
					</Grid>
				</ButtonBase>
			</Paper>
		);
	}
}
OptionCard.propTypes = {
	name: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired,
	handleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(OptionCard);
